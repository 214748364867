import moment from "moment";

export const PsicologoHeaderList = [
  { field: "nome", header: "Nome" },
  { field: "telefone", header: "Telefone" },
  { field: "email", header: "Email" },
  { field: "crp", header: "CRP" },
  { field: "ativo", header: "Ativo", body: getStatusBody },
  { field: "dataCriacao", header: "Data de criação", body: getDataCriacaoBody },
];

function getStatusBody(rowData) {
  if (rowData.ativo) {
    return (
      <label htmlFor="psicologoStatusAtivo" style={{ color: "green" }}>
        ✓
      </label>
    );
  } else {
    return (
      <label htmlFor="psicologoStatusInativo" style={{ color: "red" }}>
        x
      </label>
    );
  }
}

function getDataCriacaoBody(rowData) {
  return formatDate(rowData.dataCriacao);
}

function formatDate(date) {
  return moment(date).format("DD/MM/YYYY");
}
