import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";

class QueixaInicialItemDataService {
  env = environment.getEnvironment();
  QUEIXA_INICIAL_ENDPOINTS = `${this.env.BACKEND_API_URL}/queixa-inicial`;
  token = storageTokenConfig.getToken();

  getAllAtivos() {
    return axios.get(`${this.QUEIXA_INICIAL_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const queixaInicialItemDataService = new QueixaInicialItemDataService();
export default queixaInicialItemDataService;
