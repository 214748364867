import ReactQuill from "react-quill";
import "./style/ProntuarioRegistroAtendimento.css";
import {
  QuillFormats,
  QuillModules,
} from "../../../components/quill/QuillConfig";
import ProntuarioLabel from "./ProntuarioLabel";

export default function ProntuarioRegistroAtendimento({
  prontuario,
  setProntuario,
  isVisualizacao,
}) {
  return (
    <div style={{ marginTop: 10 }}>
      <div style={{ textAlign: "left" }}>
        <ProntuarioLabel titulo={"Registros do atendimento:"} />
      </div>
      <div>
        {prontuario.id !== undefined && (
          <ReactQuill
            theme="snow"
            modules={QuillModules}
            formats={QuillFormats}
            readOnly={isVisualizacao}
            style={{
              backgroundColor: "white",
              border: "3px solid #24345a",
              margin: 0,
              padding: 0,
              width: "100%",
              height: "100%",
            }}
            value={prontuario.registroAtendimento || ""}
            onChange={(e) =>
              setProntuario({
                ...prontuario,
                registroAtendimento: e,
              })
            }
          />
        )}
      </div>
    </div>
  );
}
