import Messages from "../components/Messages";
import { validate } from "./Validator";

export function validarRequestBody(usuario, isEdicao) {
  const camposValidar = [
    { name: "Nome", value: usuario.nome },
    { name: "Email", value: usuario.email },
    { name: "Login", value: usuario.login },
  ];
  if (!isEdicao) {
    camposValidar.push({ name: "Senha", value: usuario.senha });
  }
  return validate(camposValidar);
}

export async function validarTrocaDeStatusMessage(rowData) {
  return Messages.questionMessage(
    "Confirme a sua opção",
    `O usuario ${rowData.nome} será ${
      rowData.ativo ? "inativado" : "ativado"
    }`,
    `Sim. ${rowData.ativo ? "Inativar" : "Ativar"}`,
    "Não."
  );
}
