import { MeusPacientesIdNomeDTO } from "./MeusPacientesIdNomeDTO";
import { TipoAusenciaDTO } from "./TipoAusenciaDTO";
import { TipoEventoAgendaDTO } from "./TipoEventoAgendaDTO";
import { UsuarioDTO } from "./UsuarioDTO";

export class EventoAgendaDTO {
  id?: number;
  tipoEventoAgenda?: TipoEventoAgendaDTO;
  usuario?: UsuarioDTO;
  paciente?: MeusPacientesIdNomeDTO;
  nomeEvento?: string;
  observacao?: string;
  dataCriacao?: Date;
  inicio?: Date;
  fim?: Date;
  isCancelado?: boolean;
  dataCancelamento?: Date;
  motivoCancelamento?: string;
  pacienteCompareceu?: boolean;
  tipoAusencia?: TipoAusenciaDTO;
  justificativaAusencia?: string;

  constructor(options: Partial<EventoAgendaDTO> = {}) {
    this.id = options.id;
    this.tipoEventoAgenda = options.tipoEventoAgenda;
    this.usuario = options.usuario;
    this.paciente = options.paciente;
    this.nomeEvento = options.nomeEvento;
    this.observacao = options.observacao;
    this.dataCriacao = options.dataCriacao;
    this.inicio = options.inicio;
    this.fim = options.fim;
    this.isCancelado = options.isCancelado;
    this.dataCancelamento = options.dataCancelamento;
    this.motivoCancelamento = options.motivoCancelamento;
    this.pacienteCompareceu = options.pacienteCompareceu;
    this.tipoAusencia = options.tipoAusencia;
    this.justificativaAusencia = options.justificativaAusencia;
  }
}
