import LOGO_SYSTEM from "../../../images/pronpsi_logo_horizontal_fundo_transparente.png";
export default function LeftInformations() {
  return (
      <div className="col-5 flex align-items-center justify-content-center">
        <div className="p-fluid">
          <div className="field" style={{marginLeft: 300}}>
            <img alt="img-home" width={500} src={LOGO_SYSTEM} />
          </div>
          {/* <div className="field">
            <h1 style={{ fontFamily: "Syncopate" }}>Psicologando</h1>
          </div> */}
          <div className="field">
            <h3
              style={{
                color: "#24345a",
                fontFamily: "Open Sans Condensed",
              }}
            >
            </h3>
          </div>
        </div>
      </div>
  );
}
