import "./Medico.css";
import {
  AtivarIcon,
  EditIcon,
  InativarIcon,
  MedicoIcon,
  NovoIcon,
  ViewIcon,
} from "../../components/icons/Icons";
import { useCallback, useEffect, useState } from "react";
import Messages from "../../components/Messages";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import DefaultButton from "../../components/buttons/DefaultButton";
import AuthDataService from "../../data_services/auth/AuthDataService";
import CustomDataTable from "../../components/table/CustomDataTable";
import { RadioButton } from "primereact/radiobutton";
import TipoExibicaoEnum from "../../model/enums/TipoExibicaoEnum";
import { MedicoDTO } from "../../model/dto/MedicoDTO";
import EspecialidadeService from "../../service/EspecialidadeService";
import {
  validarRequestBodyMedico,
  validarTrocaDeStatusMedicoMessage,
} from "../../validator/MedicoValidator";
import {
  fecharModalMedico,
  handleModalEditarMedico,
  handleModalNovoMedico,
  handleModalVisualizarMedico,
} from "./dialog/DialogCRUDMedicoUtils";
import MedicoService from "../../service/MedicoService";
import MedicoActionTemplate from "./MedicoActionTemplate";
import { MedicoHeaderList } from "./header/MedicoHeaderList";
import DialogCRUDMedico from "./dialog/DialogCRUDMedico";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";

export default function Medico() {
  const [blockUi, setBlockUi] = useState(false);
  const [tipoExibicao, setTipoExibicao] = useState(TipoExibicaoEnum.TODOS);
  const [especialidades, setEspecialidades] = useState([]);
  const [medicosSemFiltro, setMedicosSemFiltro] = useState([]);
  const [medicos, setMedicos] = useState([]);
  const [medicoSelecionado, setMedicoSelecionado] = useState(new MedicoDTO());
  const [totalMedicos, setTotalMedicos] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [isVisualizacao, setIsVisualizacao] = useState(false);
  const [isEdicao, setIsEdicao] = useState(false);

  const setMedicoSelecionadoCallback = useCallback(
    function (m) {
      setMedicoSelecionado(m);
    },
    [setMedicoSelecionado]
  );

  useEffect(() => {
    carregarDados();
  }, []);

  async function alteraVisualizacao(value) {
    setTipoExibicao(value);
    switch (value) {
      case TipoExibicaoEnum.INATIVOS:
        await atualizaListaDeMedicos(false);
        break;
      case TipoExibicaoEnum.ATIVOS:
        await atualizaListaDeMedicos(true);
        break;
      default:
        await carregarDados();
        break;
    }
  }

  async function atualizaListaDeMedicos(value) {
    await carregarDados();
    const medicosFiltrados = medicosSemFiltro.filter((m) => m.ativo === value);
    setMedicos(medicosFiltrados);
    setTotalMedicos(medicosFiltrados.length);
  }

  async function carregarDados() {
    setBlockUi(true);
    const [medicosData, especialidadesData] = await Promise.all([
      MedicoService.getAll(),
      EspecialidadeService.getAll(),
    ]);
    setMedicos(medicosData);
    setMedicosSemFiltro(medicosData);
    setTotalMedicos(medicosData.length);
    setEspecialidades(especialidadesData);
    setBlockUi(false);
  }

  async function salvar(medico) {
    if (!validarRequestBodyMedico(medico, isEdicao)) {
      fecharModal();
      return;
    }
    try {
      setBlockUi(true);
      await MedicoService.salvarDadosMedico(medico.id, medico);
      if (isEdicao) {
        fecharModal();
        setBlockUi(false);
        Messages.successMessage("Sucesso", "Médico editado com sucesso!");
        setTipoExibicao(TipoExibicaoEnum.TODOS);
        carregarDados();
      } else {
        fecharModal();
        setBlockUi(false);
        Messages.successMessage("Sucesso", "Médico criado com sucesso!");
        setTipoExibicao(TipoExibicaoEnum.TODOS);
        carregarDados();
      }
    } catch (error) {
      fecharModal();
      setBlockUi(false);
      Messages.errorMessage("Erro", error.response.data.message);
    }
    setBlockUi(false);
  }

  async function trocarStatusMedico(rowData) {
    const result = await validarTrocaDeStatusMedicoMessage(rowData);
    if (result.isConfirmed) {
      try {
        setBlockUi(true);
        await MedicoService.trocarStatusMedico(rowData);
        fecharModal();
        setBlockUi(false);
        Messages.successMessage("Sucesso", "Status alterado com sucesso");
        carregarDados();
      } catch (error) {
        fecharModal();
        setBlockUi(false);
        Messages.errorMessage("Erro", error.response.data.message);
      }
    }
    setBlockUi(false);
  }

  function fecharModal() {
    fecharModalMedico(
      setIsEdicao,
      setDisplayModal,
      setMedicoSelecionado,
      setIsVisualizacao
    );
  }

  function botaoVisualizarMedico(rowData) {
    return (
      <DefaultButton
        fontSize={15}
        icon={<ViewIcon />}
        messageToolTip={"Visualizar médico"}
        click={(_) =>
          handleModalVisualizarMedico(
            rowData,
            setDisplayModal,
            setMedicoSelecionado,
            setIsVisualizacao
          )
        }
        width={40}
        height={30}
      />
    );
  }

  function botaoEditarMedico(rowData) {
    return (
      <DefaultButton
        fontSize={15}
        icon={<EditIcon />}
        messageToolTip={"Editar dados do médico"}
        click={(_) =>
          handleModalEditarMedico(
            rowData,
            setIsEdicao,
            setDisplayModal,
            setMedicoSelecionado,
            setIsVisualizacao
          )
        }
        width={40}
        height={30}
      />
    );
  }

  function botaoTrocarStatusMedico(rowData) {
    return (
      <DefaultButton
        fontSize={15}
        icon={rowData.ativo ? <InativarIcon /> : <AtivarIcon />}
        messageToolTip={`${
          rowData.ativo ? "Inativar médico" : "Ativar médico"
        }`}
        click={(_) => trocarStatusMedico(rowData)}
        width={40}
        height={30}
      />
    );
  }

  function medicoActionTemplate(rowData) {
    return (
      <MedicoActionTemplate
        botaoVisualizarMedico={() => botaoVisualizarMedico(rowData)}
        botaoEditarMedico={() => botaoEditarMedico(rowData)}
        botaoTrocarStatusMedico={() => botaoTrocarStatusMedico(rowData)}
      />
    );
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div className="Medico">
        <div className="Title">
          <span>
            <MedicoIcon /> Médicos
          </span>
        </div>
        <div></div>
      </div>
      <div className="Body">
        {AuthDataService.hasPermission(FuncionalidadeEnum.CADASTRO_MEDICO) && (
          <div className="NovoMedico">
            <DefaultButton
              fontSize={10}
              icon={<NovoIcon />}
              buttonLabel={"Adicionar"}
              messageToolTip={"Cadastrar um novo médico(a)"}
              click={(_) =>
                handleModalNovoMedico(
                  setIsEdicao,
                  setDisplayModal,
                  setMedicoSelecionado,
                  setIsVisualizacao
                )
              }
              width={150}
              height={30}
            />{" "}
            <div style={{ marginLeft: 10 }}></div>
            <div className="flex flex-wrap gap-3">
              <div className="flex align-items-center">
                <RadioButton
                  inputId="todos"
                  name="todos"
                  value={TipoExibicaoEnum.TODOS}
                  onChange={(e) => alteraVisualizacao(e.value)}
                  checked={tipoExibicao === TipoExibicaoEnum.TODOS}
                />
                <label htmlFor="todos" className="ml-2">
                  Todos
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="inativos"
                  name="inativos"
                  value={TipoExibicaoEnum.INATIVOS}
                  onChange={(e) => alteraVisualizacao(e.value)}
                  checked={tipoExibicao === TipoExibicaoEnum.INATIVOS}
                />
                <label htmlFor="inativos" className="ml-2">
                  Inativos
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="ativos"
                  name="ativos"
                  value={TipoExibicaoEnum.ATIVOS}
                  onChange={(e) => alteraVisualizacao(e.value)}
                  checked={tipoExibicao === TipoExibicaoEnum.ATIVOS}
                />
                <label htmlFor="ativos" className="ml-2">
                  Ativos
                </label>
              </div>
            </div>
          </div>
        )}
        <CustomDataTable
          values={medicos}
          totalRecords={totalMedicos}
          dataKey="id"
          headerList={MedicoHeaderList}
          actionTemplate={medicoActionTemplate}
          paginator={true}
          exibeActionTemplate={true}
        />
      </div>
      <DialogCRUDMedico
        display={displayModal}
        esconder={fecharModal}
        medico={medicoSelecionado}
        setMedico={setMedicoSelecionadoCallback}
        isVisualizacao={isVisualizacao}
        salvar={salvar}
        especialidades={especialidades}
      />
    </BlockUI>
  );
}
