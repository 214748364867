import { TipoProntuarioDTO } from "./TipoProntuarioDTO";

export class TemplatePadraoProntuarioDTO {
  id?: number;
  tipoProntuario?: TipoProntuarioDTO;
  htmlTemplate?: string;
  ativo?: boolean;

  constructor(options: Partial<TemplatePadraoProntuarioDTO> = {}) {
    this.id = options.id;
    this.tipoProntuario = options.tipoProntuario;
    this.htmlTemplate = options.htmlTemplate;
    this.ativo = options.ativo;
  }
}
