export class AgendaExportDTO {
  "Tipo evento"?: string;
  "Psicólogo"?: string;
  "CRP"?: string;
  "Paciente"?: string;
  "Nr. Carteirinha"?: string;
  "Nome do evento"?: string;
  "Observação"?: string;
  "Data criação"?: string;
  "Início"?: string;
  "Fim"?: string;
  "Cancelado"?: string;
  "Motivo cancelamento"?: string;
  "Paciente compareceu?"?: string;
  "Tipo da falta"?: string;
  "Justificativa"?: string;

  constructor(options: Partial<AgendaExportDTO> = {}) {
    this["Tipo evento"] = options["Tipo evento"];
    this["Psicólogo"] = options["Psicólogo"];
    this["CRP"] = options["CRP"];
    this["Paciente"] = options["Paciente"];
    this["Nr. Carteirinha"] = options["Nr. Carteirinha"];
    this["Nome do evento"] = options["Nome do evento"];
    this["Observação"] = options["Observação"];
    this["Data criação"] = options["Data criação"];
    this["Início"] = options["Início"];
    this["Fim"] = options["Fim"];
    this["Cancelado"] = options["Cancelado"];
    this["Motivo cancelamento"] = options["Motivo cancelamento"];
    this["Paciente compareceu?"] = options["Paciente compareceu?"];
    this["Tipo da falta"] = options["Tipo da falta"];
    this["Justificativa"] = options["Justificativa"];
  }
}
