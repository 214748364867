export class PsicologoExportDTO {
  "Nome"?: string;
  "Telefone"?: string;
  "Email"?: string;
  "CRP"?: string;
  "Ativo"?: string;
  "CEP"?: string;
  "Endereço"?: string;
  "Número"?: string;
  "Complemento"?: string;
  "Bairro"?: string;
  "Cidade"?: string;
  "Estado"?: string;
  "Sexo"?: string;

  constructor(options: Partial<PsicologoExportDTO> = {}) {
    this["Nome"] = options["Nome"];
    this["Telefone"] = options["Telefone"];
    this["Email"] = options["Email"];
    this["CRP"] = options["CRP"];
    this["Ativo"] = options["Ativo"];
    this["CEP"] = options["CEP"];
    this["Endereço"] = options["Endereço"];
    this["Número"] = options["Número"];
    this["Complemento"] = options["Complemento"];
    this["Bairro"] = options["Bairro"];
    this["Cidade"] = options["Cidade"];
    this["Estado"] = options["Estado"];
    this["Sexo"] = options["Sexo"];
  }
}
