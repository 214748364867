export function handleModalNovoPaciente(
  setIsEdicao,
  setDisplayModal,
  setPacienteSelecionado,
  setIsVisualizacao,
  setVisualizaHistorico
) {
  setIsEdicao(false);
  setDisplayModal(true);
  setPacienteSelecionado({});
  setIsVisualizacao(false);
  setVisualizaHistorico(false);
}

export function handleModalVisualizarPaciente(
  rowData,
  setDisplayModal,
  setPacienteSelecionado,
  setIsVisualizacao,
  setVisualizaHistorico
) {
  setDisplayModal(true);
  setPacienteSelecionado(rowData);
  setIsVisualizacao(true);
  setVisualizaHistorico(true);
}

export function handleModalEditarPaciente(
  rowData,
  setIsEdicao,
  setDisplayModal,
  setPacienteSelecionado,
  setIsVisualizacao,
  setVisualizaHistorico
) {
  setIsEdicao(true);
  setDisplayModal(true);
  setPacienteSelecionado(rowData);
  setIsVisualizacao(false);
  setVisualizaHistorico(true);
}

export function fecharModalPaciente(
  setIsEdicao,
  setDisplayModal,
  setPacienteSelecionado,
  setIsVisualizacao
) {
  setIsEdicao(false);
  setDisplayModal(false);
  setPacienteSelecionado({});
  setIsVisualizacao(false);
}
