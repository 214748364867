import moment from "moment";
import { formatData } from "../../../utils/FormatDataUtils";

export const ProntuariosPacienteHeader = [
  { field: "nrProntuario", header: "Nrº", size: 110 },
  { field: "tipoProntuario.nome", header: "Tipo de atendimento" },
  { field: "psicologo.nome", header: "Psicólogo" },
  { field: "isFinalizado", header: "Finalizado?", body: getIsRascunho },
  {
    field: "dataConsulta",
    header: "Data da consulta",
    body: getDataConsulta,
  },
  {
    field: "dataHoraCriacao",
    header: "Data criação prontuário",
    body: getDataHoraCriacao,
  },
  {
    field: "dataHoraUltimaAtualizacao",
    header: "Data ultima atualização",
    body: getDataHoraAtualizacao,
  },
];

function getIsRascunho(rowData) {
  if (rowData.isFinalizado) {
    return "Sim";
  } else {
    return "Não";
  }
}

function getDataConsulta(rowData) {
  if (rowData.dataConsulta !== null) {
    return formatData(rowData.dataConsulta);
  }
}

function getDataHoraCriacao(rowData) {
  return formatDate(rowData.dataHoraCriacao);
}

function getDataHoraAtualizacao(rowData) {
  if (rowData.dataHoraUltimaAtualizacao) {
    return formatDate(rowData.dataHoraUltimaAtualizacao);
  } else {
    return "";
  }
}

function formatDate(date) {
  return moment(date).format("DD/MM/YYYY HH:mm:ss");
}
