import "./PerfilAcesso.css";
import {
  DeleteIcon,
  EditIcon,
  NovoIcon,
  PerfilAcessoIcon,
  ViewIcon,
} from "../../components/icons/Icons";
import { useCallback, useEffect, useState } from "react";
import { PerfilAcessoDTO } from "../../model/dto/PerfilAcessoDTO";
import PerfilAcessoDataService from "../../data_services/perfil-acesso/PerfilAcessoDataService";
import FuncionalidadeDataService from "../../data_services/funcionalidade/FuncionalidadeDataService";
import { validate } from "../../validator/Validator";
import Messages from "../../components/Messages";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import DefaultButton from "../../components/buttons/DefaultButton";
import AuthDataService from "../../data_services/auth/AuthDataService";
import CustomDataTable from "../../components/table/CustomDataTable";
import DialogCRUDPerfil from "./dialog/DialogCRUDPerfil";
import { PerfilAcessoRequestDTO } from "../../model/dto/request/PerfilAcessoRequestDTO";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";

export default function PerfilAcesso() {
  const [blockUi, setBlockUi] = useState(false);
  const [perfisAcesso, setPerfisAcesso] = useState([]);
  const [funcionalidades, setFuncionalidades] = useState([]);
  const [perfilAcessoSelecionado, setPerfilAcessoSelecionado] = useState(
    new PerfilAcessoDTO()
  );
  const [totalPerfis, setTotalPerfis] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [isVisualizacao, setIsVisualizacao] = useState(false);

  const setPerfilAcessoSelecionadoCallback = useCallback(
    function (p) {
      setPerfilAcessoSelecionado(p);
    },
    [setPerfilAcessoSelecionado]
  );

  const cabecalho = [
    { field: "id", header: "ID" },
    { field: "codigo", header: "Código do perfil" },
    { field: "nome", header: "Nome" },
    { field: "descricao", header: "Descrição do perfil" },
  ];

  useEffect(() => {
    carregarTodosPerfisAcesso();
    carregarTodasAsFuncionalidades();
  }, []);

  function validarPerfil(perfil) {
    const camposValidar = [
      { name: "Código", value: perfil.codigo },
      { name: "Nome", value: perfil.nome },
      { name: "Descrição", value: perfil.descricao },
    ];
    return validate(camposValidar);
  }

  function carregarTodosPerfisAcesso() {
    setBlockUi(true);
    PerfilAcessoDataService.getAll().then((response) => {
      setPerfisAcesso(response.data);
      setTotalPerfis(response.data.length);
      setBlockUi(false);
    });
  }

  function carregarTodasAsFuncionalidades() {
    setBlockUi(true);
    FuncionalidadeDataService.getAll().then((response) => {
      setFuncionalidades(response.data);
    });
    setBlockUi(false);
  }

  function handleModalNovoPerfil() {
    setDisplayModal(true);
    setPerfilAcessoSelecionado({});
    setIsVisualizacao(false);
  }

  function handleModalVisualizarPerfil(rowData) {
    setDisplayModal(true);
    setPerfilAcessoSelecionado(rowData);
    setIsVisualizacao(true);
  }

  function handleModalEditarPerfil(rowData) {
    setDisplayModal(true);
    setPerfilAcessoSelecionado(rowData);
    setIsVisualizacao(false);
  }

  function fecharModal() {
    setDisplayModal(false);
    setPerfilAcessoSelecionado({});
    setIsVisualizacao(false);
  }

  function salvar(perfilAcesso) {
    if (!validarPerfil(perfilAcesso)) {
      fecharModal();
      return;
    }
    if (perfilAcesso.id) {
      setBlockUi(true);
      PerfilAcessoDataService.atualizarPerfil(
        perfilAcesso.id,
        montaRequest(perfilAcesso)
      )
        .then((_) => {
          setBlockUi(false);
          fecharModal();
          Messages.successMessage("Sucesso", "Perfil atualizado com sucesso!");
          carregarTodosPerfisAcesso();
          carregarTodasAsFuncionalidades();
        })
        .catch((error) => {
          setBlockUi(false);
          fecharModal();
          Messages.errorMessage("Erro", error.response.data.message);
        });
    } else {
      setBlockUi(true);
      PerfilAcessoDataService.criarPerfilAcesso(montaRequest(perfilAcesso))
        .then((_) => {
          setBlockUi(false);
          fecharModal();
          Messages.successMessage("Sucesso", "Perfil criado com sucesso!");
          carregarTodosPerfisAcesso();
          carregarTodasAsFuncionalidades();
        })
        .catch((error) => {
          setBlockUi(false);
          fecharModal();
          Messages.errorMessage("Erro", error.response.data.message);
        });
    }
  }

  function montaRequest(perfilAcesso) {
    return new PerfilAcessoRequestDTO({
      codigo: perfilAcesso.codigo,
      nome: perfilAcesso.nome,
      descricao: perfilAcesso.descricao,
      funcionalidadeIds:
        perfilAcesso.funcionalidadeList?.map(
          (funcionalidade) => funcionalidade.id
        ) || [],
    });
  }

  function removerPerfil(rowData) {
    Messages.questionMessage(
      "Confirme a sua opção",
      `O perfil ${rowData.nome} será removido permanentemente do sistema`,
      "Sim. Remover",
      "Não."
    ).then((result) => {
      if (result.isConfirmed) {
        setBlockUi(true);
        PerfilAcessoDataService.deletarPerfil(rowData.id)
          .then((_) => {
            setBlockUi(false);
            fecharModal();
            Messages.successMessage("Sucesso", "Perfil removido com sucesso");
            carregarTodosPerfisAcesso();
            carregarTodasAsFuncionalidades();
          })
          .catch((error) => {
            setBlockUi(false);
            fecharModal();
            Messages.errorMessage("Erro", error.response.data.message);
          });
      }
    });
  }

  function actionTemplate(rowData) {
    return (
      <div className="ActionItens">
        {AuthDataService.hasPermission(FuncionalidadeEnum.CADASTRO_PERFIL) && (
          <div>
            <DefaultButton
              fontSize={15}
              icon={<ViewIcon />}
              messageToolTip={"Visualizar perfil"}
              click={(_) => handleModalVisualizarPerfil(rowData)}
              width={40}
              height={30}
            />
          </div>
        )}
        {AuthDataService.hasPermission(
          FuncionalidadeEnum.ATUALIZACAO_PERFIL
        ) && (
          <div>
            <DefaultButton
              fontSize={15}
              icon={<EditIcon />}
              messageToolTip={"Editar dados do perfil"}
              click={(_) => handleModalEditarPerfil(rowData)}
              width={40}
              height={30}
            />
          </div>
        )}
        {AuthDataService.hasPermission(FuncionalidadeEnum.REMOCAO_PERFIL) && (
          <div>
            <DefaultButton
              fontSize={15}
              icon={<DeleteIcon />}
              messageToolTip={"Remove o perfil"}
              click={(_) => removerPerfil(rowData)}
              width={40}
              height={30}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div className="PerfilAcesso">
        <div className="Title">
          <span>
            <PerfilAcessoIcon /> Perfis de acesso
          </span>
        </div>
        <div></div>
      </div>
      <div className="Body">
        {AuthDataService.hasPermission(FuncionalidadeEnum.CADASTRO_PERFIL) && (
          <div className="NovoPerfilAcesso">
            <DefaultButton
              fontSize={12}
              icon={<NovoIcon />}
              buttonLabel={"Adicionar"}
              messageToolTip={"Cadastrar um novo perfil"}
              click={(_) => handleModalNovoPerfil()}
              width={150}
              height={30}
            />{" "}
          </div>
        )}
        <CustomDataTable
          values={perfisAcesso}
          totalRecords={totalPerfis}
          dataKey="id"
          headerList={cabecalho}
          actionTemplate={actionTemplate}
          paginator={true}
          exibeActionTemplate={true}
        />
      </div>
      <DialogCRUDPerfil
        display={displayModal}
        esconder={fecharModal}
        perfil={perfilAcessoSelecionado}
        setPerfil={setPerfilAcessoSelecionadoCallback}
        isVisualizacao={isVisualizacao}
        salvar={salvar}
        funcionalidades={funcionalidades}
      />
    </BlockUI>
  );
}
