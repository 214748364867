import IdleTimer from "react-idle-timer";
import Messages from "../components/Messages";
import AuthDataService from "../data_services/auth/AuthDataService";

export const SessionControl = () => {
  const TWO_HOURS = 7200000;
  const FIVE_SECONDS = 5000;

  function watchSessionExpiration() {
    if (AuthDataService.isUserLoggedIn()) {
      setInterval(() => {
        if (AuthDataService.verifyExpiredToken()) {
          Messages.errorMessage("Fim de sessão", "Sessão expirada!").then(
            function () {
              AuthDataService.doLogout();
            }
          );
          clearInterval();
        }
      }, FIVE_SECONDS);
    }
  }
  return (
    <>
      {/* Session control methods */}
      {AuthDataService.isUserLoggedIn() && watchSessionExpiration()}
      {AuthDataService.isUserLoggedIn() && (
        <IdleTimer
          timeout={TWO_HOURS}
          onIdle={(_) => {
            Messages.errorMessage(
              "Fim de sessão",
              "Sessão expirada devido à inatividade!"
            ).then(function () {
              AuthDataService.doLogout();
            });
          }}
        />
      )}
      {/* End of session control methods */}
    </>
  );
};
