import PropTypes from "prop-types";

import "./DialogCRUDPsicologo.css";

import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { InputMask } from "primereact/inputmask";
import {
  CancelIcon,
  FiltrarIcon,
  PsicologoIcon,
  SaveIcon,
} from "../../../components/icons/Icons";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import DefaultButton from "../../../components/buttons/DefaultButton";
import { Ufs } from "../../../utils/EstadosBrasilUtils";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { validarConsultaCep } from "../../../validator/EnderecoValidator";
import EnderecoService from "../../../service/EnderecoService";
import { useState } from "react";
import Messages from "../../../components/Messages";

export default function DialogCRUDPsicologo(props) {
  const sexoList = ["M", "F"];
  const { display, esconder, psicologo, setPsicologo, isVisualizacao, salvar } =
    props;

  const [enderecoConsultado, setEnderecoConsultado] = useState("");
  const [isBuscandoDadosEndereco, setIsBuscandoDadosEndereco] = useState(false);

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<PsicologoIcon />} {"Cadastro e gerenciamento de psicólogos"}
      </div>
    );
  };

  async function consultarEnderecoPorCep() {
    if (!validarConsultaCep(enderecoConsultado)) {
      esconder();
      return;
    }
    try {
      setIsBuscandoDadosEndereco(true);
      const [enderecoData] = await Promise.all([
        EnderecoService.getEnderecoByCep(enderecoConsultado),
      ]);
      setPsicologo({
        ...psicologo,
        cep: enderecoData.cep,
        logradouro: enderecoData.logradouro,
        complemento: enderecoData.complemento,
        bairro: enderecoData.bairro,
        cidade: enderecoData.cidade,
        estado: enderecoData.estado,
        numero: enderecoData.numero,
      });
      setIsBuscandoDadosEndereco(false);
    } catch (error) {
      setIsBuscandoDadosEndereco(false);
      setEnderecoConsultado("");
      esconder();
      Messages.errorMessage("Erro", error.response.data.message);
    }
  }

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados do psicologo">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="psicologoId">
                  <b>ID: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={psicologo?.id !== undefined ? psicologo.id : ""}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="nomePsicologo">
                  <b>Nome: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={psicologo?.nome !== undefined ? psicologo.nome : ""}
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      nome: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="telefonePsicologo">
                  <b>Telefone: </b>
                </label>
                <br></br>
                <InputMask
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  mask="(99) 99999-9999"
                  value={
                    psicologo?.telefone !== undefined ? psicologo.telefone : ""
                  }
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      telefone: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>

            <Row>
            <Col>
                <label style={{ color: "#24345a" }} htmlFor="psicologoSexo">
                  <b>Sexo: </b>
                </label>
                <br></br>
                <Dropdown
                  disabled={isVisualizacao}
                  style={{ width: "100%", background: "#7988ac" }}
                  value={psicologo?.sexo !== undefined ? psicologo.sexo : ""}
                  options={sexoList}
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      sexo: e.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="emailPsicologo">
                  <b>Email: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="email"
                  value={psicologo?.email !== undefined ? psicologo.email : ""}
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      email: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="crpPsicologo">
                  <b>CRP: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={psicologo?.crp !== undefined ? psicologo.crp : ""}
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      crp: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="psicologoAtivo">
                  <b>Ativo: </b>
                </label>
                <br></br>
                <Checkbox
                  style={{ marginTop: "15px" }}
                  disabled={true}
                  inputId="bloqued"
                  checked={
                    psicologo?.ativo !== undefined ? psicologo.ativo : ""
                  }
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      ativo: e.target.value,
                    });
                  }}
                ></Checkbox>
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        <Panel header="Dados de contato">
          <Container>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="psicologoSelecionadoCep"
                >
                  <b>CEP: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={psicologo?.cep !== undefined ? psicologo.cep : ""}
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      cep: e.target.value,
                    });
                    setEnderecoConsultado(e.target.value);
                  }}
                />
                {isBuscandoDadosEndereco && (
                  <label htmlFor="buscandoEnderecoPsicologoLabel">
                    Buscando dados do endereço...
                  </label>
                )}
              </Col>
              {!isVisualizacao && (
                <Col>
                  <div className="BotaoConsultaCep SpinnerContainer">
                    <DefaultButton
                      fontSize={10}
                      icon={<FiltrarIcon />}
                      buttonLabel={"Buscar"}
                      messageToolTip={"Busca o endereço pelo CEP informado"}
                      click={(_) => consultarEnderecoPorCep()}
                      width={130}
                      height={30}
                    />
                    {isBuscandoDadosEndereco && (
                      <div style={{ marginLeft: "10px" }}>
                        <ProgressSpinner
                          strokeWidth="8"
                          className="blue-spinner"
                          style={{ width: "40px" }}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="psicologoSelecionadoEndereco"
                >
                  <b>Endereço: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    psicologo?.logradouro !== undefined
                      ? psicologo.logradouro
                      : ""
                  }
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      logradouro: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="psicologoSelecionadoNumero"
                >
                  <b>Número: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    psicologo?.numero !== undefined ? psicologo.numero : ""
                  }
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      numero: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="psicologoSelecionadoComplemento"
                >
                  <b>Complemento: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    psicologo?.complemento !== undefined
                      ? psicologo.complemento
                      : ""
                  }
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      complemento: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="psicologoSelecionadoBairro"
                >
                  <b>Bairro: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    psicologo?.bairro !== undefined ? psicologo.bairro : ""
                  }
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      bairro: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="psicologoSelecionadoCidade"
                >
                  <b>Cidade: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    psicologo?.cidade !== undefined ? psicologo.cidade : ""
                  }
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      cidade: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="psicologoSelecionadoEstado"
                >
                  <b>Estado: </b>
                </label>
                <br></br>
                <Dropdown
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  filter
                  style={{ width: "100%", background: "#7988ac" }}
                  value={
                    psicologo?.estado !== undefined ? psicologo.estado : ""
                  }
                  options={Ufs}
                  emptyMessage="Não há estados cadastrados no sistema"
                  onChange={(e) => {
                    setPsicologo({
                      ...psicologo,
                      estado: e.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        {!isVisualizacao && (
          <div className="Footer">
            <DefaultButton
              fontSize={15}
              icon={<SaveIcon />}
              buttonLabel={"Salvar"}
              messageToolTip={"Salvar operação"}
              click={(_) => salvar(psicologo)}
              width={150}
              height={30}
            />
            <DefaultButton
              fontSize={15}
              icon={<CancelIcon />}
              buttonLabel={"Cancelar"}
              messageToolTip={"Cancelar operação"}
              click={esconder}
              width={150}
              height={30}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}

DialogCRUDPsicologo.propTypes = {
  display: PropTypes.any,
  esconder: PropTypes.any,
  psicologo: PropTypes.any,
  setPsicologo: PropTypes.any,
  isVisualizacao: PropTypes.any,
  salvar: PropTypes.any,
};
