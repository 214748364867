import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";
import { RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO } from "../../model/dto/request/RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO";
import { RelatorioHistoricoAtendimentosFilteredRequestDTO } from "../../model/dto/request/RelatorioHistoricoAtendimentosFilteredRequestDTO";

class RelatorioDataService {
  env = environment.getEnvironment();
  RELATORIO_ENDPOINTS = `${this.env.BACKEND_API_URL}/relatorios`;
  token = storageTokenConfig.getToken();

  getRelatorioHistoricoAlteracaoPsicologo(
    size: number,
    page: number,
    filters: RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO
  ) {
    return axios.post(
      `${this.RELATORIO_ENDPOINTS}/historico-alteracao-psicologo?size=${size}&&page=${page}`,
      filters,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  getRelatorioHistoricoAtendimentos(
    size: number,
    page: number,
    filters: RelatorioHistoricoAtendimentosFilteredRequestDTO
  ) {
    return axios.post(
      `${this.RELATORIO_ENDPOINTS}/historico-atendimentos?size=${size}&&page=${page}`,
      filters,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }
}

const relatorioDataService = new RelatorioDataService();
export default relatorioDataService;
