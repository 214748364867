import { MeuPacienteDTO } from "./MeuPacienteDTO";
import { UsuarioDTO } from "./UsuarioDTO";

export class MeusPacientesDTO {
  id?: number;
  nrPacientePsicologo?: number;
  paciente?: MeuPacienteDTO;
  isPsicologoAtual?: boolean;
  dataInicioVinculo?: Date;
  dataFimVinculo?: Date;
  dataHoraUltimaAtualizacao?: Date;
  usuarioUltimaAtualizacao?: UsuarioDTO;

  constructor(options: Partial<MeusPacientesDTO> = {}) {
    this.id = options.id;
    this.nrPacientePsicologo = options.nrPacientePsicologo;
    this.paciente = options.paciente;
    this.isPsicologoAtual = options.isPsicologoAtual;
    this.dataInicioVinculo = options.dataInicioVinculo;
    this.dataFimVinculo = options.dataFimVinculo;
    this.dataHoraUltimaAtualizacao = options.dataHoraUltimaAtualizacao;
    this.usuarioUltimaAtualizacao = options.usuarioUltimaAtualizacao;
  }
}
