import { formatData, formatDataHora } from "../../../utils/FormatDataUtils";

export const RelatorioHistoricoAlteracaoPsicologoHeader = [
  { field: "nrPacientePsicologo", header: "Nº paciente", size: 100 },
  {
    field: "dataInicioVinculo",
    header: "Data início vínculo",
    body: getDataInicioVinculo,
  },
  {
    field: "dataFimVinculo",
    header: "Data fim vínculo",
    body: getDataFimVinculo,
  },
  { field: "paciente.paciente", header: "Nome paciente", size: 500 },
  { field: "paciente.nrCarteirinha", header: "Nº carteirinha" },
  {
    field: "paciente.ativo",
    header: "Status do paciente",
    body: getStatusPaciente,
  },
  { field: "psicologo.nome", header: "Nome psicólogo", size: 400 },
  { field: "psicologo.crp", header: "CRP" },
  {
    field: "isPsicologoAtual",
    header: "Psicólogo atual",
    body: getIsPsicologoAtual,
  },
  {
    field: "usuarioUltimaAtualizacao.nome",
    header: "Usuário ult. atualização",
  },
  {
    field: "dataHoraUltimaAtualizacao",
    header: "Data hora ult. atualização",
    body: getDataHoraUltimaAtualizacao,
  },
];

function getIsPsicologoAtual(rowData) {
  if (rowData.isPsicologoAtual) {
    return "Sim";
  } else {
    return "Não";
  }
}

function getStatusPaciente(rowData) {
  if (rowData.paciente.ativo) {
    return <label>Ativo</label>;
  } else {
    return <label style={{ color: "red" }}>Inativo</label>;
  }
}

function getDataInicioVinculo(rowData) {
  return formatData(rowData.dataInicioVinculo);
}

function getDataFimVinculo(rowData) {
  if (rowData.dataFimVinculo !== null) {
    return formatData(rowData.dataFimVinculo);
  }
}

function getDataHoraUltimaAtualizacao(rowData) {
  if (rowData.dataHoraUltimaAtualizacao) {
    return formatDataHora(rowData.dataHoraUltimaAtualizacao);
  }
}
