import PerfilAcessoDataService from "../data_services/perfil-acesso/PerfilAcessoDataService";
import { PerfilAcessoDTO } from "../model/dto/PerfilAcessoDTO";

class PerfilAcessoService {
  static async getAll(): Promise<PerfilAcessoDTO[]> {
    const response = await PerfilAcessoDataService.getAll();
    const perfis: PerfilAcessoDTO[] = response.data;
    return perfis;
  }
}

export default PerfilAcessoService;
