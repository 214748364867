export default function FooterInformations() {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        height: "auto",
        marginTop: "40px",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div>
        <label htmlFor="labelCopright" style={{ fontSize: 10 }}>
          <b style={{ color: "#24345a" }}>© Copyriright: </b> Psicologando - Instituto de Saúde Mental e Emocional
        </label>
      </div>
    </div>
  );
}
