import { forwardRef } from "react";
import PrintableHeader from "./header/PrintableHeader";
import PrintableFooter from "./footer/PrintableFooter";
import "./PrintableContent.css";
import PrintableData from "./data/PrintableData";

const PrintableContent = forwardRef(
  ({ conteudoImprimir, dadosProntuario }, ref) => {
    return (
      <div style={{ display: "none" }}>
        <div ref={ref}>
          <PrintableHeader />
          <PrintableFooter />
          <PrintableData
            conteudoImprimir={conteudoImprimir}
            dadosProntuario={dadosProntuario}
          />
        </div>
      </div>
    );
  }
);

export default PrintableContent;
