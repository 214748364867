export class UsuarioRequestDTO {
  nome?: string;
  email?: string;
  login?: string;
  senha?: string;
  isPsicologo?: boolean;
  psicologoId?: number;
  perfilAcessoIds?: number[];

  constructor(options: Partial<UsuarioRequestDTO> = {}) {
    this.nome = options.nome;
    this.email = options.email;
    this.login = options.login;
    this.senha = options.senha;
    this.isPsicologo = options.isPsicologo;
    this.psicologoId = options.psicologoId;
    this.perfilAcessoIds = options.perfilAcessoIds;
  }
}
