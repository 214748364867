import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthDataService from "../data_services/auth/AuthDataService";
import FuncionalidadeEnum from "../model/enums/FuncionalidadeEnum";
import BemVindo from "../views/BemVindo";
import Login from "../views/Login";
import Especialidade from "../views/especialidade/Especialidade";
import Home from "../views/home/Home";
import Medico from "../views/medico/Medico";
import MeusAtendimentos from "../views/meus-atendimentos/MeusAtendimentos";
import MeusPacientes from "../views/meus-pacientes/MeusPacientes";
import ModeloProntuario from "../views/modelos-prontuario/ModeloProntuario";
import NotFound from "../views/not-found/NotFound";
import Paciente from "../views/paciente/Paciente";
import PerfilAcesso from "../views/perfil-acesso/PerfilAcesso";
import Prontuario from "../views/prontuario/Prontuario";
import ProntuariosPaciente from "../views/prontuario/ProntuariosPaciente";
import Psicologo from "../views/psicologo/Psicologo";
import Usuario from "../views/usuario/Usuario";
import PrivatePermissionRoute from "./PrivatePermissionRoute";
import Relatorios from "../views/relatorios/Relatorios";
import Agenda from "../views/agenda/Agenda";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      AuthDataService.isUserLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export const RoutesConfig = () => {
  return (
    <Switch>
      <Route exact path="/" component={BemVindo}>
        {AuthDataService.isUserLoggedIn() ? (
          <Redirect push to="/home" />
        ) : (
          <BemVindo />
        )}
      </Route>
      <Route path="/login" component={Login}>
        {AuthDataService.isUserLoggedIn() ? (
          <Redirect push to="/home" />
        ) : (
          <Login />
        )}
      </Route>
      <PrivateRoute path="/home" component={Home} />
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.VISUALIZACAO_AGENDA}
        path="/agenda"
        component={Agenda}
      ></PrivatePermissionRoute>
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.VISUALIZACAO_ESPECIALIDADE}
        path="/especialidade"
        component={Especialidade}
      />
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.CADASTRO_PERFIL}
        path="/perfil-acesso"
        component={PerfilAcesso}
      />
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.VISUALIZACAO_USUARIO}
        path="/usuario"
        component={Usuario}
      />
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.VISUALIZACAO_PSICOLOGO}
        path="/psicologo"
        component={Psicologo}
      />
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.VISUALIZACAO_MEDICO}
        path="/medico"
        component={Medico}
      />
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.VISUALIZACAO_PACIENTE}
        path="/paciente"
        component={Paciente}
      />
      <PrivatePermissionRoute
        functionality={"free"}
        path="/modelos-prontuario"
        component={ModeloProntuario}
      ></PrivatePermissionRoute>
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.VISUALIZAR_MEUS_PACIENTES}
        path="/meus-pacientes"
        component={MeusPacientes}
      ></PrivatePermissionRoute>
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.VISUALIZAR_MEUS_ATENDIMENTOS}
        path="/meus-atendimentos"
        component={MeusAtendimentos}
      ></PrivatePermissionRoute>
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.CADASTRO_PRONTUARIO}
        path="/prontuario/cadastrar/:prontuarioId/:mode(visualizar|editar)"
        component={Prontuario}
        openInNewTab={true}
      ></PrivatePermissionRoute>
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.VISUALIZACAO_PRONTUARIO}
        path="/prontuario/paciente/:pacienteId"
        component={ProntuariosPaciente}
      ></PrivatePermissionRoute>
      <PrivatePermissionRoute
        functionality={FuncionalidadeEnum.VISUALIZAR_RELATORIOS}
        path="/relatorios"
        component={Relatorios}
      ></PrivatePermissionRoute>
      <Route path="*" component={NotFound}></Route>
    </Switch>
  );
};
