import PropTypes from "prop-types";

import { Dialog } from "primereact/dialog";
import { UserIcon } from "../../icons/Icons";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

import { Container, Row, Col } from "react-bootstrap";

import moment from "moment";
import CustomDataTable from "../../table/CustomDataTable";

export default function DialogInformacoesUsuario(props) {
  const { exibirDialog, usuario, esconder } = props;

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<UserIcon />} {"Meus dados"}
      </div>
    );
  };

  const cabecalhoPerfil = [
    { field: "nome", header: "Perfil" },
    { field: "descricao", header: "Descrição" },
  ];


  const cabecalhoAcessos = [
    { field: "nome", header: "Acessos" },
  ];

  function formatDate(date) {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  }

  function getAcessosUsuario(perfilAcessoList) {
    const acessos = [];
    for (let perfil of perfilAcessoList) {
      for (let funcionalidade of perfil.funcionalidadeList) {
        if (!acessos.includes(funcionalidade.nome)) {
          acessos.push({ nome: funcionalidade.nome });
        }
      }
    }
    return acessos;
  }

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={exibirDialog}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados de login">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioId">
                  <b>ID: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={usuario?.id !== undefined ? "#" + usuario.id : ""}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioNome">
                  <b>Nome: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={usuario?.nome !== undefined ? usuario.nome : ""}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioEmail">
                  <b>E-mail: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={usuario?.email !== undefined ? usuario.email : ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioLogin">
                  <b>Login: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={usuario?.login !== undefined ? usuario.login : ""}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioAtivo">
                  <b>Ativo: </b>
                </label>
                <br></br>
                <Checkbox
                  style={{ marginTop: "15px" }}
                  disabled={true}
                  inputId="bloqued"
                  checked={usuario?.ativo !== undefined ? usuario.ativo : ""}
                ></Checkbox>
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="usuarioDataCriacao"
                >
                  <b>Data entrada sistema: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    usuario?.dataCriacao !== undefined
                      ? formatDate(usuario.dataCriacao)
                      : ""
                  }
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        <Panel header="Perfis">
          <div className="card">
            <CustomDataTable
              values={usuario.perfilAcessoList}
              totalRecords={
                usuario?.perfilAcessoList ? usuario.perfilAcessoList.length : 0
              }
              dataKey="id"
              headerList={cabecalhoPerfil}
              actionTemplate={null}
              paginator={true}
              exibeActionTemplate={false}
            />
          </div>
        </Panel>
        <br></br>
        <Panel header="Acessos">
          <div className="card">
            <CustomDataTable
              values={getAcessosUsuario(usuario.perfilAcessoList)}
              totalRecords={
                getAcessosUsuario(usuario.perfilAcessoList).length
              }
              dataKey="id"
              headerList={cabecalhoAcessos}
              actionTemplate={null}
              paginator={true}
              exibeActionTemplate={false}
            />
          </div>
        </Panel>
      </Dialog>
    </div>
  );
}

DialogInformacoesUsuario.propTypes = {
  exibirDialog: PropTypes.bool,
  usuario: PropTypes.object,
  esconder: PropTypes.func,
};
