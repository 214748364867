import "./Messages.scss";
import Swal from 'sweetalert2/dist/sweetalert2.js'

export class Message{
    successMessage(title, message){
        return Swal.fire({
            icon: 'success',
            title: title,
            text: message,
            imageAlt: 'Psicologando',
            confirmButtonColor: '#fe4a49',
            confirmButtonText: 'Ok'
        })
    }

    errorMessage(title, message){
        return Swal.fire({
            icon: 'error',
            title: title,
            text: message,
            confirmButtonColor: '#24345a',
            confirmButtonText: 'Ok'
        })        
    }

    questionMessage(title, message, confirmButtonText, dennyButtonText){
        return Swal.fire({
            icon: "warning",
            title: title,
            text: message,
            showDenyButton: true,
            confirmButtonText: confirmButtonText,
            confirmButtonColor: '#fe4a49',
            denyButtonColor: '#451e3e',
            denyButtonText: dennyButtonText,
        })
    }
}
const message = new Message()
export default message;