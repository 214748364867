import "./NavBar.css";

import { Dropdown } from "react-bootstrap";

import { useState } from "react";

import AuthDataService from "../../data_services/auth/AuthDataService";
import DialogInformacoesUsuario from "./components/DialogInformacoesUsuario";
import DialogAlterarSenha from "./components/DialogAlterarSenha";
import { LogoutIcon, PasswordIcon, UsuarioIcon } from "../icons/Icons";
import secureLocalStorage from "react-secure-storage";

export default function NavBar() {
  const userLogged = JSON.parse(secureLocalStorage.getItem("userSession"));

  const [displayDadosUsuario, setDisplayDadosUsuario] = useState(false);
  const [displayDialogAlteraSenha, setDisplayDialogAlteraSenha] =
    useState(false);

  function logout() {
    AuthDataService.doLogout();
  }

  function exibirDialogInformacoesUsuario() {
    setDisplayDadosUsuario(true);
  }

  function fecharDialogInformacoesUsuario() {
    setDisplayDadosUsuario(false);
  }

  function exibirDialogAlteraSenha() {
    setDisplayDialogAlteraSenha(true);
  }

  function fecharDialogAlteraSenha() {
    setDisplayDialogAlteraSenha(false);
  }

  function getPrefixoUsuario() {
    if (userLogged.isPsicologo) {
      if (userLogged.psicologo.sexo === "M") {
        return `Dr. ${userLogged.psicologo.nome}`;
      } else if (userLogged.psicologo.sexo === "F") {
        return `Dra. ${userLogged.psicologo.nome}`;
      } else {
        return `${userLogged.nome}`;
      }
    }

    return `${userLogged.nome}`;
  }

  return (
    <>
      {AuthDataService.isUserLoggedIn() && (
        <div className="NavBarContainer">
          <div style={{ marginTop: "8px" }}>
            <Dropdown align={"end"}>
              <Dropdown.Toggle
                style={{
                  backgroundColor: "#24345a",
                  borderColor: "#24345a",
                  marginRight: 25,
                }}
                id="dropdown-basic"
              >
                {getPrefixoUsuario()}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ backgroundColor: "#7988ac", fontSize: 12 }}
              >
                <Dropdown.Item style={{ color: "#24345a" }} onClick={logout}>
                  <LogoutIcon /> Logout
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ color: "#24345a" }}
                  onClick={exibirDialogInformacoesUsuario}
                >
                  <UsuarioIcon /> Meus dados
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ color: "#24345a" }}
                  onClick={exibirDialogAlteraSenha}
                >
                  <PasswordIcon /> Alterar senha
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <DialogInformacoesUsuario
              exibirDialog={displayDadosUsuario}
              usuario={userLogged}
              esconder={fecharDialogInformacoesUsuario}
            />
            <DialogAlterarSenha
              exibirDialog={displayDialogAlteraSenha}
              usuario={userLogged}
              esconder={fecharDialogAlteraSenha}
            />
          </div>
        </div>
      )}
    </>
  );
}
