import EnderecoDataService from "../data_services/endereco/EnderecoDataService";
import { EnderecoDTO } from "../model/dto/EnderecoDTO";
import { ConsultaEnderecoRequestDTO } from "../model/dto/request/ConsultaEnderecoRequestDTO";

class EnderecoService {
  static async getEnderecoByCep(cep: string): Promise<EnderecoDTO[]> {
    const response = await EnderecoDataService.getEnderecoByCep(this.montaRequest(cep));
    const endereco: EnderecoDTO[] = response.data;
    return endereco;
  }

  private static montaRequest(cep: string) {
    return new ConsultaEnderecoRequestDTO({
        cep: cep
    })
  }
}

export default EnderecoService;
