import { UsuarioRequestDTO } from "./../model/dto/request/UsuarioRequestDTO";
import UsuarioDataService from "../data_services/usuario/UsuarioDataService";
import { PerfilAcessoDTO } from "../model/dto/PerfilAcessoDTO";
import { UsuarioDTO } from "../model/dto/UsuarioDTO";
import { AtivarInativarUsuarioRequestDTO } from "../model/dto/request/AtivarInativarUsuarioRequestDTO";
import { UsuarioInfoDTO } from "../model/dto/UsuarioInfoDTO";

class UsuarioService {
  static async getAll(): Promise<UsuarioDTO[]> {
    const response = await UsuarioDataService.getAll();
    const usuarios: UsuarioDTO[] = response.data;
    return usuarios;
  }

  static async salvarDadosDoUsuario(id: number, usuario: any) {
    const usuarioRequest = this.montaRequest(usuario);
    if (id) {
      await this.atualizarUsuario(id, usuarioRequest);
    } else {
      await this.criarUsuario(usuarioRequest);
    }
  }

  static async trocarStatusUsuário(usuario: UsuarioDTO) {
    await UsuarioDataService.ativarInativarUsuario(
      this.montaRequestAtivarInativarUsuario(usuario)
    );
  }

  static async getUsuariosPsicologos(): Promise<UsuarioInfoDTO[]> {
    const response = await UsuarioDataService.getUsuariosPsicologos();
    const usuariosPsicologos: UsuarioInfoDTO[] = response.data;
    return usuariosPsicologos;
  }

  private static montaRequestAtivarInativarUsuario(usuario: UsuarioDTO) {
    return new AtivarInativarUsuarioRequestDTO({
      usuarioId: usuario.id,
      ativo: !usuario.ativo,
    });
  }

  private static async atualizarUsuario(
    id: number,
    usuario: UsuarioRequestDTO
  ) {
    await UsuarioDataService.atualizarUsuario(id, usuario);
  }

  private static async criarUsuario(usuario: UsuarioRequestDTO) {
    await UsuarioDataService.criarUsuario(usuario);
  }

  private static montaRequest(usuario: any) {
    return new UsuarioRequestDTO({
      nome: usuario.nome,
      email: usuario.email,
      login: usuario.login,
      senha: usuario.senha,
      isPsicologo: usuario.isPsicologo,
      psicologoId: usuario?.psicologo ? usuario.psicologo.id : null,
      perfilAcessoIds:
        usuario.perfilAcessoList?.map(
          (perfilAcesso: PerfilAcessoDTO) => perfilAcesso.id
        ) || [],
    });
  }
}

export default UsuarioService;
