import PRONPSI_LOGO from "../../../images/pronpsi-timbado-logo.png";
import "../PrintableContent.css";

export default function PrintableHeader() {
  return (
    <>
      <div className="page-header">
        <img src={PRONPSI_LOGO} height={40} width={230} alt="cabecalhoPsicologando"></img>
      </div>
    </>
  );
}
