import moment from "moment";
import relatorioDataService from "../data_services/relatorios/RelatorioDataService";
import { RelatorioHistoricoAlteracaoPsicologoDTO } from "../model/dto/RelatorioHistoricoAlteracaoPsicologoDTO";
import { RelatorioHistoricoAtendimentosDTO } from "../model/dto/RelatorioHistoricoAtendimentosDTO";
import { RelatorioHistoricoAlteracaoPsicologoExportDTO } from "../model/dto/export/RelatorioHistoricoAlteracaoPsicologoExportDTO";
import { RelatorioHistoricoAtendimentosExportDTO } from "../model/dto/export/RelatorioHistoricoAtendimentosExportDTO";
import { RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO } from "../model/dto/request/RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO";
import { RelatorioHistoricoAtendimentosFilteredRequestDTO } from "../model/dto/request/RelatorioHistoricoAtendimentosFilteredRequestDTO";

class RelatorioService {
  static async getRelatorioHistoricoAlteracaoPsicologo(
    size: number,
    page: number,
    filters: RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO
  ): Promise<RelatorioHistoricoAlteracaoPsicologoDTO[]> {
    if (!filters) {
      filters = new RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO();
    }
    const response =
      await relatorioDataService.getRelatorioHistoricoAlteracaoPsicologo(
        size,
        page,
        filters
      );
    const dados: RelatorioHistoricoAlteracaoPsicologoDTO[] = response.data;
    return dados;
  }

  static async getRelatorioHistoricoAtendimentos(
    size: number,
    page: number,
    filters: RelatorioHistoricoAtendimentosFilteredRequestDTO
  ): Promise<RelatorioHistoricoAtendimentosDTO[]> {
    if (!filters) {
      filters = new RelatorioHistoricoAtendimentosFilteredRequestDTO();
    }
    const response =
      await relatorioDataService.getRelatorioHistoricoAtendimentos(
        size,
        page,
        filters
      );
    const dados: RelatorioHistoricoAtendimentosDTO[] = response.data;
    return dados;
  }

  static formatDate(date: any) {
    if (date !== null) {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }

  static formatDateTime(date: any) {
    if (date !== null) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    } else {
      return "";
    }
  }

  static getStatus(value: any) {
    if (value === true) {
      return "SIM";
    } else {
      return "NÃO";
    }
  }

  static montaObjetoParaExportacaoRelatorioHistoricoAlteracaoPsicologo(
    dadosRelatorio: RelatorioHistoricoAlteracaoPsicologoDTO[]
  ) {
    return dadosRelatorio.map(
      (dado: RelatorioHistoricoAlteracaoPsicologoDTO) => {
        return new RelatorioHistoricoAlteracaoPsicologoExportDTO({
          "Nº paciente": dado.nrPacientePsicologo?.toString(),
          "Nome paciente": dado.paciente?.paciente,
          "Nº carteirinha": dado.paciente?.nrCarteirinha,
          "Nome psicólogo": dado.psicologo?.nome,
          CRP: dado.psicologo?.crp,
          "Psicólogo atual?": this.getStatus(dado.isPsicologoAtual),
          "Data início vínculo": this.formatDate(dado.dataInicioVinculo),
          "Data fim vínculo": this.formatDate(dado.dataFimVinculo),
          "Usuário ult. atualização": dado.usuarioUltimaAtualizacao?.nome,
        });
      }
    );
  }

  static montaObjetoParaExportacaoRelatorioHistoricoAtendimentos(
    dadosRelatorio: RelatorioHistoricoAtendimentosDTO[]
  ) {
    return dadosRelatorio.map((dado: RelatorioHistoricoAtendimentosDTO) => {
      return new RelatorioHistoricoAtendimentosExportDTO({
        "Nº prontuário": dado.nrProntuario,
        "Tipo atendimento": dado.tipoProntuario?.nome,
        "Nome paciente": dado.paciente?.paciente,
        "Nº carteirinha": dado.paciente?.nrCarteirinha,
        "Nome psicólogo": dado.psicologo?.nome,
        CRP: dado.psicologo?.crp,
        "Primeira sessão?": this.getStatus(dado.isPrimeiraSessao),
        "Atendimento finalizado?": this.getStatus(dado.isFinalizado),
        "Data criação prontuário": this.formatDate(dado.dataHoraCriacao),
        "Data hora ult. atualização": this.formatDateTime(
          dado.dataHoraUltimaAtualizacao
        ),
      });
    });
  }
}

export default RelatorioService;
