export class RelatorioHistoricoAlteracaoPsicologoExportDTO {
  "Nº paciente"?: string;
  "Nome paciente"?: string;
  "Nº carteirinha"?: string;
  "Nome psicólogo"?: string;
  "CRP"?: string;
  "Psicólogo atual?"?: string;
  "Data início vínculo"?: string;
  "Data fim vínculo"?: string;
  "Usuário ult. atualização"?: string;

  constructor(
    options: Partial<RelatorioHistoricoAlteracaoPsicologoExportDTO> = {}
  ) {
    this["Nº paciente"] = options["Nº paciente"];
    this["Nome paciente"] = options["Nome paciente"];
    this["Nº carteirinha"] = options["Nº carteirinha"];
    this["Nome psicólogo"] = options["Nome psicólogo"];
    this["CRP"] = options["CRP"];
    this["Psicólogo atual?"] = options["Psicólogo atual?"];
    this["Data início vínculo"] = options["Data início vínculo"];
    this["Data fim vínculo"] = options["Data fim vínculo"];
    this["Usuário ult. atualização"] = options["Usuário ult. atualização"];
  }
}
