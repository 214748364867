import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";
import { MeusAtendimentosFilteredRequestDTO } from "../../model/dto/request/MeusAtendimentosFilteredRequestDTO";

class MeusAtendimentosDataService {
  env = environment.getEnvironment();
  MEUS_ATENDIMENTOS_ENDPOINT = `${this.env.BACKEND_API_URL}/meus-atendimentos`;
  token = storageTokenConfig.getToken();

  getMeusAtendimentos(
    size: number,
    page: number,
    filters?: MeusAtendimentosFilteredRequestDTO
  ) {
    return axios.post(
      `${this.MEUS_ATENDIMENTOS_ENDPOINT}?size=${size}&&page=${page}`,
      filters,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }
}

const meusAtendimentosDataService = new MeusAtendimentosDataService();
export default meusAtendimentosDataService;
