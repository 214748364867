
export const exportExcel = (exportData) => {
  const dataForExport = exportData.map((item) => {
    return {
      ...item,
      listaDeSessao: item.listaDeSessao ? item.listaDeSessao.join(", ") : "",
    };
  });
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(dataForExport);
    const workbook = { Sheets: { Pacientes: worksheet }, SheetNames: ["Pacientes"] };
    
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAsExcelFile(excelBuffer, "Pacientes");
  });
  
};

const saveAsExcelFile = (buffer, fileName) => {
  import("file-saver").then((module) => {
    if (module?.default) {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });

      module.default.saveAs(
        data,
        fileName + "_exportados_" + new Date().getTime() + EXCEL_EXTENSION
      );
    }
  });
};
