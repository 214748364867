import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";
import { AtivarInativarPacienteRequestDTO } from "../../model/dto/request/AtivarInativarPacienteRequestDTO";
import { AtualizaSessaoRequestDTO } from "../../model/dto/request/AtualizaSessaoRequestDTO";
import { PacienteFiltrosRequestDTO } from "../../model/dto/request/PacienteFiltrosRequestDTO";
import { PacientePsicologoRequestDTO } from "../../model/dto/request/PacientePsicologoRequestDTO";
import { PacienteRequestDTO } from "../../model/dto/request/PacienteRequestDTO";
import { PacienteSessaoRequestDTO } from "../../model/dto/request/PacienteSessaoRequestDTO";

class PacienteDataService {
  env = environment.getEnvironment();
  PACIENTE_ENDPOINTS = `${this.env.BACKEND_API_URL}/paciente`;
  token = storageTokenConfig.getToken();

  criarPaciente(paciente: PacienteRequestDTO) {
    return axios.post(`${this.PACIENTE_ENDPOINTS}`, paciente, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  atualizarPaciente(id: number, paciente: PacienteRequestDTO) {
    return axios.put(`${this.PACIENTE_ENDPOINTS}/${id}`, paciente, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  ativarInativarPaciente(
    ativarInativarPacienteRequest: AtivarInativarPacienteRequestDTO
  ) {
    return axios.patch(
      `${this.PACIENTE_ENDPOINTS}/`,
      ativarInativarPacienteRequest,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  getAll() {
    return axios.get(`${this.PACIENTE_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  adicionarSessao(
    pacienteId: number,
    pacienteSessaoRequestList: PacienteSessaoRequestDTO[]
  ) {
    return axios.patch(
      `${this.PACIENTE_ENDPOINTS}/sessao/adicionar/${pacienteId}`,
      pacienteSessaoRequestList,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  atualizarSessao(atualizaSessaoRequest: AtualizaSessaoRequestDTO) {
    return axios.put(
      `${this.PACIENTE_ENDPOINTS}/sessao/atualizar`,
      atualizaSessaoRequest,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  removerSessao(sessaoId: number) {
    return axios.delete(
      `${this.PACIENTE_ENDPOINTS}/sessao/remover/${sessaoId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  getAllPacientesFiltrados(pacienteFiltrosRequest: PacienteFiltrosRequestDTO) {
    return axios.post(
      `${this.PACIENTE_ENDPOINTS}/filtrar`,
      pacienteFiltrosRequest,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  alterarDataVinculoPacientePsicologo(
    id: number,
    pacientePsicologoRequestDTO: PacientePsicologoRequestDTO
  ) {
    return axios.put(
      `${this.PACIENTE_ENDPOINTS}/psicologo/alterar-data-vinculo/${id}`,
      pacientePsicologoRequestDTO,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  getAllPacientesPaginate(
    size: number,
    page: number,
    filters?: PacienteFiltrosRequestDTO
  ) {
    return axios.post(
      `${this.PACIENTE_ENDPOINTS}/buscar?size=${size}&&page=${page}`,
      filters,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }
}

const pacienteDataService = new PacienteDataService();
export default pacienteDataService;
