import { Dialog } from "primereact/dialog";
import { IniciarNovoAtendimentoIcon, TipoProntuarioIcon } from "../../../components/icons/Icons";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";

export default function DialogSelecionarTipoAtendimento({
  display,
  esconder,
  tiposProntuario,
  iniciarAtendimento,
  nomePaciente,
  dataNascimento
}) {
  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<IniciarNovoAtendimentoIcon />}{" "}
        {`Iniciar novo atendimento para o paciente ${nomePaciente}`}
        {dataNascimento !== null ? ` (${calcularIdade()} anos)` : ""}
      </div>
    );
  };

  function calcularIdade() {
    const hoje = new Date();
    const dataNasc = new Date(dataNascimento);
    let idade = hoje.getFullYear() - dataNasc.getFullYear();
    if (
      hoje.getMonth() < dataNasc.getMonth() ||
      (hoje.getMonth() === dataNasc.getMonth() &&
        hoje.getDate() < dataNasc.getDate())
    ) {
      idade--;
    }
  
    return idade.toString();
  }

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Selecione o tipo do atendimento">
          <Container>
            <Row>
              {tiposProntuario &&
                tiposProntuario.map((tipoProntuario, i) => {
                  return (
                    <Col key={tipoProntuario.id}>
                      <DefaultButton
                        fontSize={15}
                        icon={<TipoProntuarioIcon />}
                        buttonLabel={tipoProntuario.nome}
                        messageToolTip={tipoProntuario.nomeExibicao}
                        click={(_) => iniciarAtendimento(tipoProntuario)}
                        width={250}
                        height={40}
                      />
                    </Col>
                  );
                })}
            </Row>
          </Container>
        </Panel>
      </Dialog>
    </div>
  );
}
