import "./style/ProntuarioObservacoesEvolucao.css";
import { InputTextarea } from "primereact/inputtextarea";
import ProntuarioLabel from "./ProntuarioLabel";
import { Col, Row } from "react-bootstrap";

export default function ProntuarioObservacoesEvolucao({
  prontuario,
  setProntuario,
  isVisualizacao,
}) {
  return (
    <div className="ProntuarioObservacoesEvolucao">
      <Row>
        <Col>
          <ProntuarioLabel titulo={"Observações: "} />
          <InputTextarea
            className="ProntuarioObservacoesEvolucaoInputTextArea"
            disabled={isVisualizacao}
            rows={10}
            style={{ width: "100%", textAlign: "center", backgroundColor: "white", color: "#24345a" }}
            type="text"
            value={prontuario?.observacoes ?? ""}
            onChange={(e) => {
              setProntuario({
                ...prontuario,
                observacoes: e.target.value,
              });
            }}
          />
        </Col>
        <Col>
          <ProntuarioLabel titulo={"Evolução: "} />
          <InputTextarea
            className="ProntuarioObservacoesEvolucaoInputTextArea"
            disabled={isVisualizacao}
            rows={10}
            style={{ width: "100%", textAlign: "center", backgroundColor: "white", color: "#24345a" }}
            type="text"
            value={prontuario?.evolucao ?? ""}
            onChange={(e) => {
              setProntuario({
                ...prontuario,
                evolucao: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
