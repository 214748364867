import moment from "moment";
import "./MeusPacientesHeader.css";

export const MeusPacientesHeader = [
  {
    field: "paciente.paciente",
    header: "Nome",
    body: getNomePacienteBody,
    size: 500,
  },
  { field: "nrPacientePsicologo", header: "Nº interno paciente", size: 110 },
  { field: "isPsicologoAtual", header: "Cadastro ativo?", body: getStatusBody },
  { field: "paciente.nrCarteirinha", header: "Nº carteirinha" },
  { field: "paciente.medico.nome", header: "Médico", size: 400 },
  {
    field: "paciente.dataNascimento",
    header: "Data de nascimento",
    body: getDataNascimentoBody,
  },
  { field: "paciente.idade", header: "Idade", body: calcularIdade },
  {
    field: "dataInicioVinculo",
    header: "Início do vínculo",
    body: getDataInicioVinculo,
  },
  {
    field: "dataFimVinculo",
    header: "Fim do vínculo",
    body: getDataFimVinculo,
  },
];

function getNomePacienteBody(rowData) {
  if (rowData.isPsicologoAtual) {
    return (
      <label className="PacienteList" htmlFor="pacienteNomeList">
        {rowData.paciente.paciente}
      </label>
    );
  } else {
    return (
      <label
        style={{ color: "red" }}
        className="PacienteList"
        htmlFor="pacienteNomeList"
      >
        {rowData.paciente.paciente}
      </label>
    );
  }
}

function getStatusBody(rowData) {
  if (rowData.isPsicologoAtual) {
    return (
      <label htmlFor="templatePadraoAtivo" style={{ color: "green" }}>
        ✓
      </label>
    );
  } else {
    return (
      <label htmlFor="templatePadraoInativo" style={{ color: "red" }}>
        x
      </label>
    );
  }
}

function getDataNascimentoBody(rowData) {
  return formatDate(rowData.paciente.dataNascimento);
}

function getDataInicioVinculo(rowData) {
  return formatDate(rowData.dataInicioVinculo);
}

function getDataFimVinculo(rowData) {
  return formatDate(rowData.dataFimVinculo);
}

function formatDate(date) {
  if (date !== null) {
    return moment(date).format("DD/MM/YYYY");
  } else {
    return "";
  }
}

function calcularIdade(rowData) {
  const hoje = new Date();
  const dataNasc = new Date(rowData.paciente.dataNascimento);
  let idade = hoje.getFullYear() - dataNasc.getFullYear();
  if (
    hoje.getMonth() < dataNasc.getMonth() ||
    (hoje.getMonth() === dataNasc.getMonth() &&
      hoje.getDate() < dataNasc.getDate())
  ) {
    idade--;
  }

  return idade.toString();
}
