export const ModeloPadraoProntuarioHeader = [
  { field: "nomeExibicao", header: "Nome" },
  { field: "ativo", header: "Ativo", body: getStatusBody },
];

function getStatusBody(rowData) {
  if (rowData.ativo) {
    return (
      <label htmlFor="templatePadraoAtivo" style={{ color: "green" }}>
        ✓
      </label>
    );
  } else {
    return (
      <label htmlFor="templatePadraoInativo" style={{ color: "red" }}>
        x
      </label>
    );
  }
}
