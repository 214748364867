const RelatoriosOptions = [
  {
    id: 1,
    nome: "Histórico de alteração de psicólogo",
    valor: "HISTORICO_ALTERACAO_PSICOLOGO",
    inputId: "historicoAlteracaoPsicologo",
  },
  {
    id: 2,
    nome: "Histórico de atendimentos",
    valor: "HISTORICO_ATENDIMENTOS",
    inputId: "historicoAtendimentos",
  },
];

export default RelatoriosOptions;
