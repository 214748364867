import PropTypes from "prop-types";
//Label botão: buttonLabel
//Função: onClickButton
//Cor da label: labelColor
//Cor do botão: backgroundColor
//Cor da borda do botão: borderColor
//Cor quando hover: hoverColor
//Cor da borda quando hover: onHoverBorderColor
//Cor quando click: onClickColor
//Cor da borda quandoClick: onClickBorderColor

import { useState } from "react";
import "./DefaultButton.css";

import Tooltip from "@material-ui/core/Tooltip";

//Cor do click: clickColor
export default function DefaultButton(props) {
  const [backgroundColor, setBackgroundColor] = useState(
    props.backgroundColor || "#fff"
  );
  const [borderColor, setBorderColor] = useState(props.borderColor || "#24345a");

  const styleButton = {
    fontFamily: "system-ui, -apple-system, Arial, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue'",
    fontSize: props.fontSize || 16,
    color: props.labelColor || "#5685a5",
    backgroundColor: `${backgroundColor}`,
    borderColor: `${borderColor}`,
    width: parseInt(props.width) || 150,
    height: parseInt(props.height) || 50,
  };

  function onMouseUpButton() {
    return () => {
      setBackgroundColor(props.hoverColor || "#24345a");
      setBorderColor(props.onHoverBorderColor || "#fff");
    };
  }

  function onMouseOutButton() {
    return () => {
      setBackgroundColor(props.backgroundColor || "#fff");
      setBorderColor(props.borderColor || "#24345a");
    };
  }

  function onMouseClick() {
    return () => {
      setBackgroundColor(props.onClickColor || "#1e1f26");
      setBorderColor(props.onClickBorderColor || "#6077db");
    };
  }

  return (
    <div className="DefaultButton">
      <Tooltip
        disableFocusListener={!props.messageToolTip}
        disableHoverListener={!props.messageToolTip}
        disableTouchListener={!props.messageToolTip}
        title={props.messageToolTip ? props.messageToolTip : ""}
      >
        <button
          style={styleButton}
          onMouseEnter={onMouseUpButton()}
          onMouseLeave={onMouseOutButton()}
          onMouseDown={onMouseClick()}
          onMouseUp={onMouseOutButton()}
          onClick={props.click}
          onBlur={onMouseOutButton}
        >
          {props.icon ? props.icon : ""} {props.buttonLabel}
        </button>
      </Tooltip>
    </div>
  );
}

DefaultButton.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  fontSize: PropTypes.number,
  labelColor: PropTypes.string,
  hoverColor: PropTypes.string,
  onHoverBorderColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClickColor: PropTypes.string,
  onClickBorderColor: PropTypes.string,
  messageToolTip: PropTypes.string,
  click: PropTypes.func,
  icon: PropTypes.any,
  buttonLabel: PropTypes.string
};