import { dev } from "./environment.dev";
import { hml } from "./environment.hml";
import { prod } from "./environment.prod";
export const environment = {
  getEnvironment: (): any => {
    if (process.env.REACT_APP_NODE_ENV === undefined) {
      return dev;
    }

    if (process.env.REACT_APP_NODE_ENV === "dev") {
      return dev;
    }

    if (process.env.REACT_APP_NODE_ENV === "HML") {
      return hml;
    }

    if (process.env.REACT_APP_NODE_ENV === "PROD") {
      return prod;
    }
  },
};
