export class MeusAtendimentosFilteredRequestDTO {
  tipoProntuarioId?: number;
  pacienteId?: number;
  isPrimeiraSessao?: boolean;
  isFinalizado?: boolean;
  dataAtendimentoInicial?: Date;
  dataAtendimentoFinal?: Date;

  constructor(options: Partial<MeusAtendimentosFilteredRequestDTO> = {}) {
    this.tipoProntuarioId = options.tipoProntuarioId;
    this.pacienteId = options.pacienteId;
    this.isPrimeiraSessao = options.isPrimeiraSessao;
    this.isFinalizado = options.isFinalizado;
    this.dataAtendimentoInicial = options.dataAtendimentoInicial;
    this.dataAtendimentoFinal = options.dataAtendimentoFinal;
  }
}
