import PsicologoDataService from "../data_services/psicologo/PsicologoDataService";
import { PsicologoDTO } from "../model/dto/PsicologoDTO";
import { PsicologoExportDTO } from "../model/dto/export/PsicologoExportDTO";
import { AtivarInativarPsicologoRequestDTO } from "../model/dto/request/AtivarInativarPsicologoRequestDTO";
import { PsicologoRequestDTO } from "../model/dto/request/PsicologoRequestDTO";

class PsicologoService {
  static async salvarDadosPsicologo(id: number, psicologo: any) {
    const psicologoRequest = this.montaRequest(psicologo);
    if (id) {
      await this.atualizarPsicologo(id, psicologoRequest);
    } else {
      await this.criarPsicologo(psicologoRequest);
    }
  }

  static async trocarStatusPsicologo(psicologo: PsicologoDTO) {
    await PsicologoDataService.ativarInativarPsicologo(
      this.montaRequestAtivarInativarPsicologo(psicologo)
    );
  }

  static async getAll(): Promise<PsicologoDTO[]> {
    const response = await PsicologoDataService.getAll();
    const psicologos: PsicologoDTO[] = response.data;
    return psicologos;
  }

  static montaObjetoPsicologoExportacao(psicologos : PsicologoDTO[]) {
    return psicologos.map((psicologo : PsicologoDTO) => {
      return new PsicologoExportDTO({
        "Nome": psicologo.nome,
        "Telefone": psicologo.telefone,
        "Email": psicologo.email,
        "CRP": psicologo.crp,
        "Ativo": this.getStatus(psicologo.ativo),
        "CEP": psicologo.cep,
        "Endereço": psicologo.logradouro,
        "Número": psicologo.numero,
        "Complemento": psicologo.complemento,
        "Bairro": psicologo.bairro,
        "Cidade": psicologo.cidade,
        "Estado": psicologo.estado,
        "Sexo": psicologo.sexo
      })
    })
  }

  private static montaRequestAtivarInativarPsicologo(psicologo: PsicologoDTO) {
    return new AtivarInativarPsicologoRequestDTO({
      psicologoId: psicologo.id,
      ativo: !psicologo.ativo,
    });
  }

  private static async atualizarPsicologo(
    id: number,
    psicologo: PsicologoRequestDTO
  ) {
    await PsicologoDataService.atualizarPsicologo(id, psicologo);
  }

  private static async criarPsicologo(psicologo: PsicologoRequestDTO) {
    await PsicologoDataService.criarPsicologo(psicologo);
  }

  private static montaRequest(psicologo: any) {
    return new PsicologoRequestDTO({
      nome: psicologo.nome,
      telefone: psicologo.telefone,
      email: psicologo.email,
      crp: psicologo.crp,
      cidade: psicologo.cidade,
      estado: psicologo.estado,
      cep: psicologo.cep,
      logradouro: psicologo.logradouro,
      complemento: psicologo.complemento,
      bairro: psicologo.bairro,
      numero: psicologo.numero,
      sexo: psicologo.sexo
    });
  }

  static getStatus(ativo: any) {
    if (ativo === true) {
      return "SIM";
    } else {
      return "NÃO";
    }
  }
}

export default PsicologoService;
