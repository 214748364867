import { TipoProntuarioDTO } from "./TipoProntuarioDTO";
import { UsuarioDTO } from "./UsuarioDTO";

export class TemplatePadraoProntuarioUsuarioDTO {
  id?: number;
  tipoProntuario?: TipoProntuarioDTO;
  usuario?: UsuarioDTO;
  htmlTemplate?: string;
  ativo?: boolean;

  constructor(options: Partial<TemplatePadraoProntuarioUsuarioDTO> = {}) {
    this.id = options.id;
    this.tipoProntuario = options.tipoProntuario;
    this.usuario = options.usuario;
    this.htmlTemplate = options.htmlTemplate;
    this.ativo = options.ativo;
  }
}
