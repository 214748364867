export const MedicoHeaderList = [
  { field: "nome", header: "Nome" },
  { field: "crm", header: "CRM" },
  { field: "ativo", header: "Ativo", body: getStatusBody },
  { field: "especialidades", header: "Especialidades", body: getEspecialidadesBody },
  { field: "estado", header: "Estado" },
];

function getEspecialidadesBody(rowData) {
  if (!rowData.especialidades || rowData.especialidades.length === 0) {
    return <label htmlFor="medicoSemEspecialidadeBody">{""}</label>;
  }

  const especialidades = rowData.especialidades
    .filter((especialidade) => especialidade.nome)
    .map((especialidade) => especialidade.nome);

  return <label>{especialidades.join(", ")}</label>;
}

function getStatusBody(rowData) {
  if (rowData.ativo) {
    return (
      <label htmlFor="medicoStatusAtivo" style={{ color: "green" }}>
        ✓
      </label>
    );
  } else {
    return (
      <label htmlFor="medicoStatusInativo" style={{ color: "red" }}>
        x
      </label>
    );
  }
}
