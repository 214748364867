import authDataService from "../../../data_services/auth/AuthDataService";
import FuncionalidadeEnum from "../../../model/enums/FuncionalidadeEnum";
import "../ModeloProntuario.css";
export function ModeloPadraoProntuarioActionTemplate(props) {
  const {
    botaoVisualizarTemplatePadrao,
    botaoEditarTemplatePadrao,
    botaoImprimirTemplatePadrao,
  } = props;

  return (
    <div className="ActionItens">
      {authDataService.hasPermission(
        FuncionalidadeEnum.VISUALIZACAO_TEMPLATE_PRONTUARIO
      ) && <div>{botaoVisualizarTemplatePadrao()}</div>}
      {authDataService.hasPermission(
        FuncionalidadeEnum.ATUALIZACAO_TEMPLATE_PRONTUARIO
      ) && <div>{botaoEditarTemplatePadrao()}</div>}
      {authDataService.hasPermission(
        FuncionalidadeEnum.VISUALIZACAO_TEMPLATE_PRONTUARIO
      ) && <div>{botaoImprimirTemplatePadrao()}</div>}
    </div>
  );
}
