import { ModeloProntuarioIcon } from "../../components/icons/Icons";
import authDataService from "../../data_services/auth/AuthDataService";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import "./ModeloProntuario.css";
import ModeloPadraoProntuario from "./padrao/ModeloPadraoProntuario";
import ModeloUsuarioProntuario from "./usuario/ModeloUsuarioProntuario";

export default function ModeloProntuario() {
  return (
    <>
      <div className="ModeloProntuario">
        <div className="Title">
          <span>
            <ModeloProntuarioIcon /> Modelos de Prontuário
          </span>
        </div>
        <div></div>
      </div>

      <div className="Body">
        {authDataService.hasPermission(
          FuncionalidadeEnum.VISUALIZACAO_TEMPLATE_PRONTUARIO
        ) && (
          <div style={{ marginTop: 25 }}>
            <ModeloPadraoProntuario />
          </div>
        )}

        <div style={{ marginTop: 25 }}>
          <ModeloUsuarioProntuario />
        </div>
      </div>
    </>
  );
}
