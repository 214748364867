export class RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO {
  pacienteId?: number;
  psicologoId?: number;
  dataInicialInicioVinculo?: Date;
  dataFinalInicioVinculo?: Date;
  usuarioId?: number;

  constructor(
    options: Partial<RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO> = {}
  ) {
    this.pacienteId = options.pacienteId;
    this.psicologoId = options.psicologoId;
    this.dataInicialInicioVinculo = options.dataInicialInicioVinculo;
    this.dataFinalInicioVinculo = options.dataFinalInicioVinculo;
    this.usuarioId = options.usuarioId;
  }
}
