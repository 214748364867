import "../PrintableContent.css";
import { Panel } from "primereact/panel";
import { formatData, formatDataHora } from "../../../utils/FormatDataUtils";
import ProntuarioInformacoesPaciente from "../../../views/prontuario/components/ProntuarioInformacoesPaciente";
import InformacoesProntuario from "../../../views/prontuario/components/InformacoesProntuario";
import ProntuarioItensQueixaInicialList from "../../../views/prontuario/components/ProntuarioItemsQueixaInicialList";
import ProntuarioLabel from "../../../views/prontuario/components/ProntuarioLabel";
import ProntuarioObservacoesEvolucao from "../../../views/prontuario/components/ProntuarioObservacoesEvolucao";
export default function PrintableData({ conteudoImprimir, dadosProntuario }) {
  return (
    <table>
      <thead>
        <tr>
          <td>
            <div className="page-header-space"></div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className="conteudo">
              {dadosProntuario ? (
                <ProntuarioData prontuario={dadosProntuario} />
              ) : (
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{ __html: conteudoImprimir }}
                />
              )}
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

function ProntuarioData({ prontuario }) {
  function getHeaderTemplate() {
    return (
      <div className="PanelHeader">
        <label>
          <b>Prontuário Nº: </b>
          {prontuario.nrProntuario}
        </label>
        <label>
          <b>Criado em: </b>
          {prontuario !== undefined
            ? formatDataHora(prontuario.dataHoraCriacao)
            : ""}
        </label>
      </div>
    );
  }
  return (
    <div className="BodyData">
      <Panel style={{ height: "100%" }} headerTemplate={getHeaderTemplate}>
        <ProntuarioInformacoesPaciente prontuario={prontuario} />
        <InformacoesProntuario prontuario={prontuario} />
        <div style={{ textAlign: "left" }}>
          <ProntuarioLabel
            titulo={"Data da consulta: "}
            valor={
              prontuario.dataConsulta !== null
                ? formatData(prontuario.dataConsulta)
                : ""
            }
          />
        </div>
        <div style={{ marginTop: 25 }}>
          <ProntuarioItensQueixaInicialList
            itemsQueixaInicial={
              prontuario !== undefined &&
              prontuario.itemsQueixaInicial !== undefined
                ? prontuario.itemsQueixaInicial
                : []
            }
          />
        </div>
        <div style={{ marginTop: 25 }}>
          <div style={{ textAlign: "left" }}>
            <ProntuarioLabel titulo={"Registros de atendimento: "} />
          </div>
          <div
            style={{ border: "3px solid #24345a", padding: "5px" }}
            className="ql-editor"
            dangerouslySetInnerHTML={{ __html: prontuario.registroAtendimento }}
          />
        </div>
        <div style={{ marginTop: 25 }}>
          <ProntuarioObservacoesEvolucao
            prontuario={prontuario}
            setProntuario={null}
            isVisualizacao={true}
          />
        </div>
      </Panel>
    </div>
  );
}
