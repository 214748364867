export class PerfilAcessoRequestDTO {
  codigo?: string;
  nome?: string;
  descricao?: string;
  funcionalidadeIds?: number[];

  constructor(options: Partial<PerfilAcessoRequestDTO> = {}) {
    this.codigo = options.codigo;
    this.nome = options.nome;
    this.descricao = options.descricao;
    this.funcionalidadeIds = options.funcionalidadeIds;
  }
}
