import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";

class HomeDashboardDataService {
  env = environment.getEnvironment();
  DASHBOARD_ENDPOINTS = `${this.env.BACKEND_API_URL}/dashboard`;
  token = storageTokenConfig.getToken();

  getPsicologoDashboard(psicologoId: number) {
    return axios.get(`${this.DASHBOARD_ENDPOINTS}/${psicologoId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const homeDashboardDataService = new HomeDashboardDataService();
export default homeDashboardDataService;
