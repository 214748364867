import moment from "moment";

export const UsuarioHeaderList = [
  { field: "nome", header: "Nome" },
  { field: "email", header: "Email" },
  { field: "login", header: "Login" },
  { field: "ativo", header: "Ativo", body: getStatusBody },
  { field: "dataCriacao", header: "Data de criação", body: getDataCriacaoBody },
  { field: "isPsicologo", header: "É psicólogo?", body: getIsPsicologoBody },
  { field: "psicologo.nome", header: "Psicólogo" },
];

function getStatusBody(rowData) {
  if (rowData.ativo) {
    return (
      <label htmlFor="usuarioStatusAtivo" style={{ color: "green" }}>
        ✓
      </label>
    );
  } else {
    return (
      <label htmlFor="usuarioStatusInativo" style={{ color: "red" }}>
        x
      </label>
    );
  }
}

function getIsPsicologoBody(rowData) {
  if (rowData.isPsicologo) {
    return (
      <label htmlFor="usuarioIsPsicologoTrue" style={{ color: "green" }}>
        ✓
      </label>
    );
  } else {
    return (
      <label htmlFor="usuarioIsPsicologoFalse" style={{ color: "red" }}>
        x
      </label>
    );
  }
}

function getDataCriacaoBody(rowData) {
  return formatDate(rowData.dataCriacao);
}

function formatDate(date) {
  return moment(date).format("DD/MM/YYYY HH:mm:ss");
}
