import prontuarioDataService from "../data_services/prontuario/ProntuarioDataService";
import { ProntuarioDTO } from "../model/dto/ProntuarioDTO";
import { QueixaInicialItemDTO } from "../model/dto/QueixaInicialItemDTO";
import { AtualizarProntuarioRequestDTO } from "../model/dto/request/AtualizarProntuarioRequestDTO";
import { CriarProntuarioRequestDTO } from "../model/dto/request/CriarProntuarioRequestDTO";
import { ProntuarioPacienteFilteredRequestDTO } from "../model/dto/request/ProntuarioPacienteFilteredRequestDTO";

class ProntuarioService {
  static async getProntuarioById(prontuarioId: number): Promise<ProntuarioDTO> {
    const response = await prontuarioDataService.getProntuarioById(
      prontuarioId
    );
    const prontuario: ProntuarioDTO = response.data;
    return prontuario;
  }

  static async getProntuariosByPacienteId(
    pacienteId: number,
    size: number,
    page: number,
    filters: ProntuarioPacienteFilteredRequestDTO
  ): Promise<ProntuarioDTO[]> {
    if (!filters) {
      filters = new ProntuarioPacienteFilteredRequestDTO();
    }
    const response = await prontuarioDataService.getProntuariosByPacienteId(
      pacienteId,
      size,
      page,
      filters
    );
    const prontuarios: ProntuarioDTO[] = response.data;
    return prontuarios;
  }

  static async getProntuariosByPsicologo(
    size: number,
    page: number
  ): Promise<ProntuarioDTO[]> {
    const response = await prontuarioDataService.getProntuariosByPsicologo(
      size,
      page
    );
    const prontuarios: ProntuarioDTO[] = response.data;
    return prontuarios;
  }

  static async criarProntuario(
    tipoProntuarioId: any,
    pacienteId: any
  ): Promise<ProntuarioDTO> {
    const request = this.montaRequestCriarProntuario(
      tipoProntuarioId,
      pacienteId
    );
    const response = await prontuarioDataService.criarProntuario(request);
    const prontuario = response.data;
    return prontuario;
  }

  static async atualizarProntuario(
    prontuarioId: any,
    prontuario: any,
    isFinalizado: boolean
  ) {
    const request = this.montaRequestAtualizarProntuario(
      prontuario,
      isFinalizado
    );
    await prontuarioDataService.atualizarProntuario(prontuarioId, request);
  }

  private static montaRequestCriarProntuario(
    tipoProntuarioId: any,
    pacienteId: any
  ) {
    return new CriarProntuarioRequestDTO({
      tipoProntuarioId: tipoProntuarioId,
      pacienteId: pacienteId,
    });
  }

  private static montaRequestAtualizarProntuario(
    prontuario: any,
    isFinalizado: boolean
  ) {
    return new AtualizarProntuarioRequestDTO({
      registroAtendimento: prontuario.registroAtendimento,
      observacoes: prontuario.observacoes,
      evolucao: prontuario.evolucao,
      finalizado: isFinalizado,
      itemsQueixaInicialId:
        prontuario.itemsQueixaInicial?.map(
          (itemQueixaInicial: QueixaInicialItemDTO) => itemQueixaInicial.id
        ) || [],
      dataConsulta: prontuario.dataConsulta,
    });
  }
}

export default ProntuarioService;
