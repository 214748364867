import "./CustomDataTable.css";

import PropTypes from "prop-types";

import { useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { ExportarExcelIcon } from "../icons/Icons";
import DefaultButton from "../buttons/DefaultButton";

export default function CustomDataTable(props) {
  const [expandedRows, setExpandedRows] = useState(null);
  const {
    exportar,
    dadosParaExportacao,
    values,
    totalRecords,
    dataKey,
    headerList,
    actionTemplate,
    paginator,
    exibeActionTemplate,
    expandable,
    expandableTitle,
    expandableObjectName,
    expandableHeader,
    expandableAttributeName,
    exportFunction,
    showInLeft,
    callbackSelectionChange,
  } = props;

  const rowsPerPage = 10;
  const first = 0;
  const [sortField, setSortField] = useState(dataKey);
  const [sortOrder, setSortOrder] = useState(-1);

  const [globalFilter, setGlobalFilter] = useState(null);

  const dataTableFuncMap = {
    globalFilter: setGlobalFilter,
  };

  const renderHeader = (globalFilterKey) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
        }}
      >
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) =>
              dataTableFuncMap[`${globalFilterKey}`](e.target.value)
            }
            placeholder={"Buscar"}
          />
        </span>
        {exportar && exportar === true && (
          <div className="flex align-items-center gap-2">
            <DefaultButton
              fontSize={10}
              icon={<ExportarExcelIcon />}
              buttonLabel={"Exportar"}
              messageToolTip={"Exportar para excel"}
              click={() => exportFunction(dadosParaExportacao)}
              width={150}
              height={30}
            />
          </div>
        )}
      </div>
    );
  };

  const header = renderHeader("globalFilter");

  function footerTable(total) {
    return `Total de registros encontrados: ${total}`;
  }

  function onSort(e) {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
    values.sort((a, b) => (a[e.sortField] > b[e.sortField] ? e.sortOrder : -1));
  }

  const valuesList = headerList.map((h, i) => {
    return (
      <Column
        bodyStyle={{
          fontSize: 10,
          textAlign: "center",
          minWidth: h.size ? h.size : 200,
          border: "0.5px solid gray",
          // backgroundColor: "#fff",
        }}
        headerStyle={{
          fontSize: 10,
          textAlign: "center",
          minWidth: h.size ? h.size : 200,
          color: "white",
          backgroundColor: "#24345a",
          border: "0.5px solid gray",
        }}
        key={h.field}
        field={h.field}
        header={h.header}
        style={{ textAlign: "center" }}
        sortable={true}
        body={h.body !== undefined ? h.body : null}
      />
    );
  });

  const expandableValuesList = expandable
    ? expandableHeader.map((h, i) => {
        return (
          <Column
            bodyStyle={{
              fontSize: 10,
              textAlign: "center",
              minWidth: h.size ? h.size : 200,
              border: "0.5px solid gray",
            }}
            headerStyle={{
              fontSize: 10,
              textAlign: "center",
              minWidth: h.size ? h.size : 200,
              color: "white",
              backgroundColor: "#24345a",
              border: "0.5px solid gray",
            }}
            key={h.field}
            field={h.field}
            header={h.header}
            style={{ textAlign: "center" }}
            sortable={true}
            body={h.body !== undefined ? h.body : null}
          />
        );
      })
    : null;

  const rowExpansionTemplate = (data) => {
    return (
      <>
        {expandable && (
          <div
            className="p-3"
            style={{
              border: "5px solid black",
              overflow: "auto",
              maxHeight: "150px",
              maxWidth: "25%",
              background: "#24345a",
              overflowX: "auto",
              position: "sticky",
              left: 0,
              zIndex: 1,
            }}
          >
            <h5 style={{ color: "white" }}>
              <b>
                {expandableTitle} {data[expandableAttributeName]}
              </b>
            </h5>
            <DataTable
              value={data[expandableObjectName]}
              emptyMessage={"Nenhum registro encontrado"}
              selectionMode="single"
              style={{ fontSize: 10, textAlign: "center" }}
              size="small"
            >
              {expandableValuesList}
            </DataTable>
          </div>
        )}
      </>
    );
  };

  return (
    <div style={{ overflow: "auto", maxHeight: "300px", display: "block" }}>
      <DataTable
        selectionMode="single"
        style={{ fontSize: 10, textAlign: "center" }}
        header={header}
        globalFilter={globalFilter}
        value={values}
        rowsPerPageOptions={[5, 10, 15, 30]}
        paginator={paginator}
        paginatorClassName={"Paginator"}
        rows={rowsPerPage}
        first={first}
        sortField={sortField}
        sortOrder={sortOrder}
        totalRecords={totalRecords}
        footer={footerTable(totalRecords)}
        dataKey={dataKey}
        onSort={(e) => onSort(e)}
        emptyMessage={"Nenhum registro encontrado"}
        responsive="true"
        size="small"
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onSelectionChange={
          callbackSelectionChange !== undefined ? callbackSelectionChange : null
        }
      >
        {expandable && (
          <Column
            headerStyle={{
              fontSize: 10,
              textAlign: "center",
              width: 170,
              color: "white",
              backgroundColor: "#24345a",
              border: "0.5px solid gray",
            }}
            bodyStyle={{
              fontSize: 10,
              textAlign: "center",
              minWidth: 5,
              border: "0.5px solid gray",
            }}
            expander={expandable}
          />
        )}
        {actionTemplate && exibeActionTemplate && showInLeft === true && (
          <Column
            header={"Ações"}
            body={actionTemplate}
            style={{ textAlign: "center", border: "0.5px solid gray" }}
            headerStyle={{
              fontSize: 10,
              textAlign: "center",
              width: 170,
              color: "white",
              backgroundColor: "#24345a",
              border: "0.5px solid gray",
            }}
          />
        )}
        {valuesList}
        {actionTemplate &&
          exibeActionTemplate &&
          (showInLeft === false || showInLeft === undefined) && (
            <Column
              header={"Ações"}
              body={actionTemplate}
              style={{ textAlign: "center", border: "0.5px solid gray" }}
              headerStyle={{
                fontSize: 10,
                textAlign: "center",
                width: 170,
                color: "white",
                backgroundColor: "#24345a",
                border: "0.5px solid gray",
              }}
            />
          )}
      </DataTable>
    </div>
  );
}

CustomDataTable.propTypes = {
  values: PropTypes.any,
  totalRecords: PropTypes.any,
  dataKey: PropTypes.any,
  headerList: PropTypes.any,
  actionTemplate: PropTypes.any,
  paginator: PropTypes.any,
  exibeActionTemplate: PropTypes.any,
  exportar: PropTypes.any,
  dadosParaExportacao: PropTypes.any,
  expandable: PropTypes.any,
  expandableTitle: PropTypes.any,
  expandableObjectName: PropTypes.any,
  expandableHeader: PropTypes.any,
  expandableAttributeName: PropTypes.any,
  exportFunction: PropTypes.any,
};
