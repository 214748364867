import PropTypes from "prop-types";
import AuthDataService from "../../data_services/auth/AuthDataService";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import "./Psicologo.css";

export default function PsicologoActionTemplate(props) {
  const {
    botaoVisualizarPsicologo,
    botaoEditarPsicologo,
    botaoTrocarStatusPsicologo,
  } = props;

  return (
    <div className="ActionItens">
      {AuthDataService.hasPermission(
        FuncionalidadeEnum.VISUALIZACAO_PSICOLOGO
      ) && <div>{botaoVisualizarPsicologo()}</div>}
      {AuthDataService.hasPermission(
        FuncionalidadeEnum.ATUALIZACAO_PSICOLOGO
      ) && <div>{botaoEditarPsicologo()}</div>}
      {AuthDataService.hasPermission(FuncionalidadeEnum.INATIVACAO_PSICOLOGO) && (
        <div>{botaoTrocarStatusPsicologo()}</div>
      )}
    </div>
  );
}

PsicologoActionTemplate.propTypes = {
  botaoVisualizarPsicologo: PropTypes.any,
  botaoEditarPsicologo: PropTypes.any,
  botaoTrocarStatusPsicologo: PropTypes.any,
};
