import queixaInicialItemDataService from "../data_services/queixa-inicial/QueixaInicialItemDataService";
import { QueixaInicialItemDTO } from "../model/dto/QueixaInicialItemDTO";

class QueixaInicialItemService {
  static async getAllAtivos(): Promise<QueixaInicialItemDTO[]> {
    const response = await queixaInicialItemDataService.getAllAtivos();
    const itensQueixaInicial: QueixaInicialItemDTO[] = response.data;
    return itensQueixaInicial;
  }
}

export default QueixaInicialItemService;
