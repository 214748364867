export function handleModalNovoPsicologo(
    setIsEdicao,
    setDisplayModal,
    setPsicologoSelecionado,
    setIsVisualizacao
  ) {
    setIsEdicao(false);
    setDisplayModal(true);
    setPsicologoSelecionado({});
    setIsVisualizacao(false);
  }
  
  export function handleModalVisualizarPsicologo(
    rowData,
    setDisplayModal,
    setPsicologoSelecionado,
    setIsVisualizacao
  ) {
    setDisplayModal(true);
    setPsicologoSelecionado(rowData);
    setIsVisualizacao(true);
  }
  
  export function handleModalEditarPsicologo(
    rowData,
    setIsEdicao,
    setDisplayModal,
    setPsicologoSelecionado,
    setIsVisualizacao
  ) {
    setIsEdicao(true);
    setDisplayModal(true);
    setPsicologoSelecionado(rowData);
    setIsVisualizacao(false);
  }
  
  export function fecharModalPsicologo(
    setIsEdicao,
    setDisplayModal,
    setPsicologoSelecionado,
    setIsVisualizacao
  ) {
    setIsEdicao(false);
    setDisplayModal(false);
    setPsicologoSelecionado({});
    setIsVisualizacao(false);
  }
  