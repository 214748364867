import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import AuthDataService from "../data_services/auth/AuthDataService";

export const PrivatePermissionRoute = ({
  component: Component,
  functionality,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      AuthDataService.hasPermission(functionality) ||
      (functionality === "free" && AuthDataService.isUserLoggedIn()) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/home" />
      )
    }
  />
);

PrivatePermissionRoute.propTypes = {
  component: PropTypes.any,
  functionality: PropTypes.string,
};

export default PrivatePermissionRoute;
