import PropTypes from "prop-types";

import "./DialogCRUDPerfil.css";

import { Dialog } from "primereact/dialog";
import {
  CancelIcon,
  PerfilAcessoIcon,
  SaveIcon,
} from "../../../components/icons/Icons";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import DefaultButton from "../../../components/buttons/DefaultButton";
import { MultiSelect } from "primereact/multiselect";

export default function DialogCRUDPerfil(props) {
  const {
    display,
    esconder,
    perfil,
    setPerfil,
    isVisualizacao,
    salvar,
    funcionalidades,
  } = props;

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<PerfilAcessoIcon />} {"Cadastro e gerenciamento de perfis"}
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados da especialidade">
          <Container>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="perfilId"
                >
                  <b>ID: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={perfil?.id !== undefined ? "#" + perfil.id : ""}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="codigoPerfil"
                >
                  <b>Código do perfil: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={perfil?.codigo !== undefined ? perfil.codigo : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^\w]/g, "");
                    const uppercaseValue = inputValue.toUpperCase();
                    setPerfil({
                      ...perfil,
                      codigo: uppercaseValue
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="nomePerfil"
                >
                  <b>Perfil: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={perfil?.nome !== undefined ? perfil.nome : ""}
                  onChange={(e) => {
                    setPerfil({
                      ...perfil,
                      nome: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="descricaoPerfil"
                >
                  <b>Detalhes do perfil: </b>
                </label>
                <br></br>
                <InputTextarea
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    perfil?.descricao !== undefined ? perfil.descricao : ""
                  }
                  onChange={(e) => {
                    setPerfil({
                      ...perfil,
                      descricao: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        <Panel header="Funcionalidades atreladas ao perfil">
          <div className="card">
            <MultiSelect
              itemClassName="CustomMultiSelect"
              disabled={isVisualizacao}
              value={
                perfil?.funcionalidadeList !== undefined
                  ? perfil.funcionalidadeList
                  : []
              }
              options={funcionalidades}
              optionLabel="nome"
              placeholder="Selecione as funcionalidades"
              filter
              className="multiselect-custom CustomMultiSelect"
              display="chip"
              onChange={(e) => {
                setPerfil({
                  ...perfil,
                  funcionalidadeList: e.target.value,
                });
              }}
            />
          </div>
        </Panel>
        {!isVisualizacao && (
          <div className="Footer">
            <DefaultButton
              fontSize={15}
              icon={<SaveIcon />}
              buttonLabel={"Salvar"}
              messageToolTip={"Salvar operação"}
              click={(_) => salvar(perfil)}
              width={150}
              height={30}
            />
            <DefaultButton
              fontSize={15}
              icon={<CancelIcon />}
              buttonLabel={"Cancelar"}
              messageToolTip={"Cancelar operação"}
              click={esconder}
              width={150}
              height={30}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}

DialogCRUDPerfil.propTypes = {
  display: PropTypes.any,
  esconder: PropTypes.any,
  perfil: PropTypes.any,
  setPerfil: PropTypes.any,
  isVisualizacao: PropTypes.any,
  salvar: PropTypes.any,
  funcionalidades: PropTypes.any,
};
