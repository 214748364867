import Messages from "../components/Messages";
import { validate } from "./Validator";

export function validarRequestBodyPsicologo(usuario) {
  const camposValidar = [{ name: "Nome", value: usuario.nome }];
  return validate(camposValidar);
}

export async function validarTrocaDeStatusPsicologoMessage(rowData) {
    return Messages.questionMessage(
      "Confirme a sua opção",
      `O psicologo(a) ${rowData.nome} será ${
        rowData.ativo ? "inativado" : "ativado"
      }`,
      `Sim. ${rowData.ativo ? "Inativar" : "Ativar"}`,
      "Não."
    );
  }
  