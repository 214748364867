export class RelatorioHistoricoAtendimentosFilteredRequestDTO {
  nrProntuario?: string;
  tipoProntuarioId?: number;
  pacienteId?: number;
  psicologoId?: number;
  dataAtendimentoInicial?: Date;
  dataAtendimentoFinal?: Date;

  constructor(
    options: Partial<RelatorioHistoricoAtendimentosFilteredRequestDTO> = {}
  ) {
    this.nrProntuario = options.nrProntuario;
    this.tipoProntuarioId = options.tipoProntuarioId;
    this.pacienteId = options.pacienteId;
    this.psicologoId = options.psicologoId;
    this.dataAtendimentoInicial = options.dataAtendimentoInicial;
    this.dataAtendimentoFinal = options.dataAtendimentoFinal;
  }
}
