import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import message from "../../components/Messages";
import DefaultButton from "../../components/buttons/DefaultButton";
import {
  EditIcon,
  IniciarNovoAtendimentoIcon,
  SaveIcon,
  VoltarIcon,
} from "../../components/icons/Icons";
import PrintButton from "../../components/print/PrintButton";
import PrintProntuario from "../../components/print/prontuario/PrintProntuario";
import { ProntuarioDTO } from "../../model/dto/ProntuarioDTO";
import ProntuarioService from "../../service/ProntuarioService";
import QueixaInicialItemService from "../../service/QueixaInicialItemService";
import { formatData, formatDataHora } from "../../utils/FormatDataUtils";
import "./Prontuario.css";
import InformacoesProntuario from "./components/InformacoesProntuario";
import ProntuarioInformacoesPaciente from "./components/ProntuarioInformacoesPaciente";
import ProntuarioItemsQueixaInicial from "./components/ProntuarioItemsQueixaInicial";
import ProntuarioItensQueixaInicialList from "./components/ProntuarioItemsQueixaInicialList";
import ProntuarioLabel from "./components/ProntuarioLabel";
import ProntuarioObservacoesEvolucao from "./components/ProntuarioObservacoesEvolucao";
import ProntuarioRegistroAtendimento from "./components/ProntuarioRegistroAtendimento";

const DOIS_SEGUNDOS = 2000;
const DEZ_SEGUNDOS = 10000;

export default function Prontuario() {
  const prontuarioRef = useRef();
  const params = useParams();
  const [isVisualizacao, setIsVisualizacao] = useState(true);
  const history = useHistory();
  const { prontuarioId } = useParams();
  const [blockUi, setBlockUi] = useState(false);
  const [prontuario, setProntuario] = useState(new ProntuarioDTO());
  const [prontuarioInicial, setProntuarioInicial] = useState(
    new ProntuarioDTO()
  );
  const [itemsQueixaInicial, setItemsQueixaInicial] = useState([]);

  const setProntuarioCallback = useCallback(
    function (m) {
      setProntuario(m);
    },
    [setProntuario]
  );

  useEffect(() => {
    if (params.mode === "visualizar") {
      setIsVisualizacao(true);
    } else {
      setIsVisualizacao(false);
    }
    carregarProntuario();
    carregarItensQueixaInicial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isVisualizacao && !prontuario.isFinalizado) {
      setProntuarioInicial(prontuario);
      const interval = setInterval(verificarMudancasProntuario, DEZ_SEGUNDOS);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prontuario]);

  async function verificarMudancasProntuario() {
    if (!isEqual(prontuario, prontuarioInicial)) {
      await salvaRascunhoPorAlteracaoAutomatica();
    }
  }

  function isEqual(prontuarioEmEdicao, prontuarioCarregado) {
    if (!prontuarioEmEdicao && !prontuarioCarregado) {
      return true;
    }
    if (
      prontuarioEmEdicao.itemsQueixaInicial === null ||
      prontuarioEmEdicao.itemsQueixaInicial === undefined
    ) {
      prontuarioEmEdicao.itemsQueixaInicial = [];
    }
    if (
      prontuarioCarregado.itemsQueixaInicial === null ||
      prontuarioCarregado.itemsQueixaInicial === undefined
    ) {
      prontuarioCarregado.itemsQueixaInicial = [];
    }

    let isEqual =
      prontuarioEmEdicao.dataConsulta === prontuarioCarregado.dataConsulta &&
      prontuarioEmEdicao.registroAtendimento ===
        prontuarioCarregado.registroAtendimento &&
      prontuarioEmEdicao.observacoes === prontuarioCarregado.observacoes &&
      prontuarioEmEdicao.evolucao === prontuarioCarregado.evolucao &&
      prontuarioEmEdicao.itemsQueixaInicial.length ===
        prontuarioCarregado.itemsQueixaInicial.length &&
      prontuarioEmEdicao.itemsQueixaInicial.every((item, index) => {
        return item.id === prontuarioCarregado.itemsQueixaInicial[index].id;
      });
    return isEqual;
  }

  function navigate(url) {
    history.push(url);
  }

  function navigateBack() {
    history.goBack();
  }

  async function carregarProntuario() {
    setBlockUi(true);
    await ProntuarioService.getProntuarioById(prontuarioId)
      .then((response) => {
        setProntuario(response);
        setBlockUi(false);
      })
      .catch((error) => {
        message.errorMessage("Opss ...", error.response.data.message);
        setBlockUi(false);
        navigate("/meus-pacientes");
      });
  }

  async function salvaRascunhoPorAlteracaoAutomatica() {
    setProntuario({
      ...prontuario,
      isFinalizado: false,
    });
    await ProntuarioService.atualizarProntuario(
      prontuario.id,
      prontuario,
      false
    )
      .then((_) => {
        setProntuarioInicial(prontuario);
      })
      .catch((_) => {
        message.errorMessage(
          "Opss ..",
          "Erro no processo de salvamento de rascunho. Volte à tela inicial do sistema. Se o problema persistir, entre em contato com um administrador!"
        );
      });
  }

  async function salvaRascunho() {
    setProntuario({
      ...prontuario,
      isFinalizado: false,
    });
    setBlockUi(true);
    await ProntuarioService.atualizarProntuario(
      prontuario.id,
      prontuario,
      false
    )
      .then((_) => {
        setProntuarioInicial(prontuario);
        message.successMessage("Sucesso", "Prontuário salvo com sucesso!");
      })
      .catch((error) => {
        message.errorMessage("Opss ..", error.response.data.message);
        setBlockUi(false);
      });
    setBlockUi(false);
  }

  async function finalizarProntuario() {
    setProntuario({
      ...prontuario,
      isFinalizado: true,
    });
    setBlockUi(true);
    await ProntuarioService.atualizarProntuario(prontuario.id, prontuario, true)
      .then((_) => {
        setProntuarioInicial(prontuario);
        message.successMessage("Sucesso", "Prontuário salvo com sucesso!");
        setTimeout(() => {
          navigate(`/prontuario/paciente/${prontuario.paciente.id}`);
        }, DOIS_SEGUNDOS);
      })
      .catch((error) => {
        message.errorMessage("Ops..", error.response.data.message);
        setBlockUi(false);
      });
    setBlockUi(false);
  }

  async function carregarItensQueixaInicial() {
    setBlockUi(true);
    try {
      const [itensQueixaInicialData] = await Promise.all([
        QueixaInicialItemService.getAllAtivos(),
      ]);
      setItemsQueixaInicial(itensQueixaInicialData);
      setBlockUi(false);
    } catch (error) {
      message.errorMessage("Opss ...", error.response.data.message);
    }
    setBlockUi(false);
  }

  function getHeaderTemplate() {
    return (
      <div className="PanelHeader">
        <label>
          <b>Prontuário Nº: </b>
          {prontuario.nrProntuario}
        </label>
        <label>
          <b>Criado em: </b>
          {prontuario !== undefined
            ? formatDataHora(prontuario.dataHoraCriacao)
            : ""}
        </label>
      </div>
    );
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div className="Prontuario">
        <div className="Title">
          <span>
            <IniciarNovoAtendimentoIcon />{" "}
            {isVisualizacao ? "Visualizar" : "Registro de "} prontuário de
            atendimento do paciente {prontuario.paciente?.paciente}{" "}
            {!prontuario.isFinalizado ? "(Rascunho)" : ""}
          </span>
        </div>
        <div></div>
      </div>
      <div className="Body">
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
        >
          <PrintButton
            contentRef={prontuarioRef}
            messageToolTip={"Imprimir este prontuário"}
            titulo={
              prontuario !== undefined && prontuario.paciente !== undefined
                ? `${
                    prontuario.nrProntuario
                  }_${prontuario.paciente.paciente.toUpperCase()}`
                : ""
            }
            callbackFunction={null}
            rowData={prontuario}
            label={"Imprimir"}
            width={180}
          />
          <DefaultButton
            fontSize={15}
            icon={<VoltarIcon />}
            messageToolTip={"Volta para a página de prontuários do paciente"}
            click={(_) => navigateBack()}
            width={180}
            height={30}
            buttonLabel={"Voltar"}
          />
        </div>
        <Panel headerTemplate={getHeaderTemplate}>
          <ProntuarioInformacoesPaciente prontuario={prontuario} />
          <InformacoesProntuario prontuario={prontuario} />
          <div
            style={{
              marginTop: 10,
              marginBottom: 15,
              display: "flex",
              alignItems: "center",
            }}
          >
            <ProntuarioLabel
              titulo={"Data da consulta: "}
              valor={
                isVisualizacao && prontuario.dataConsulta
                  ? formatData(prontuario.dataConsulta)
                  : ""
              }
            />

            {!isVisualizacao && (
              <InputText
                disabled={isVisualizacao}
                style={{ marginLeft: 15, width: "15%", height: "80%" }}
                type="date"
                value={prontuario.dataConsulta ? prontuario.dataConsulta : ""}
                onChange={(e) =>
                  setProntuario({
                    ...prontuario,
                    dataConsulta: e.target.value,
                  })
                }
              />
            )}
          </div>
          {!isVisualizacao ? (
            <ProntuarioItemsQueixaInicial
              prontuario={prontuario}
              itemsQueixaInicial={itemsQueixaInicial}
              setProntuario={setProntuarioCallback}
              isVisualizacao={isVisualizacao}
            />
          ) : (
            <ProntuarioItensQueixaInicialList
              itemsQueixaInicial={
                prontuario !== undefined &&
                prontuario.itemsQueixaInicial !== undefined
                  ? prontuario.itemsQueixaInicial
                  : []
              }
            />
          )}
          <ProntuarioRegistroAtendimento
            prontuario={prontuario}
            setProntuario={setProntuarioCallback}
            isVisualizacao={isVisualizacao}
          />
          <ProntuarioObservacoesEvolucao
            prontuario={prontuario}
            setProntuario={setProntuarioCallback}
            isVisualizacao={isVisualizacao}
          />

          {!isVisualizacao && (
            <div className="ProntuarioFooter">
              <DefaultButton
                fontSize={15}
                icon={<SaveIcon />}
                buttonLabel={"Finalizar"}
                messageToolTip={"Finaliza este prontuário."}
                click={(_) => finalizarProntuario()}
                width={150}
                height={30}
              />
              <DefaultButton
                fontSize={15}
                icon={<EditIcon />}
                buttonLabel={"Salvar"}
                messageToolTip={"Salva o prontuário como rascunho"}
                click={(_) => salvaRascunho()}
                width={150}
                height={30}
              />
            </div>
          )}
        </Panel>
      </div>
      <div></div>
      <PrintProntuario
        ref={prontuarioRef}
        conteudoImprimir={prontuario.registroAtendimento}
        dadosProntuario={prontuario}
      />
    </BlockUI>
  );
}
