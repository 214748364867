export function handleModalAdicionarSessao(
  setDisplayModalAdicionarParcela,
  sessoes,
  setSessoesPaciente,
  pacienteSelecionado,
  setPacienteSelecionado
) {
  setDisplayModalAdicionarParcela(true);
  setSessoesPaciente(sessoes);
  setPacienteSelecionado(pacienteSelecionado)
}

export function fecharModalAdicionarSessao(
  setDisplayModal,
  setPacienteSelecionado,
  setSessoesPaciente
) {
  setDisplayModal(false);
  setPacienteSelecionado({});
  setSessoesPaciente([]);
}
