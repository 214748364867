export class ProntuarioPacienteFilteredRequestDTO {
  dataAtendimentoInicial?: Date;
  dataAtendimentoFinal?: Date;
  tipoAtendimentoId?: number;
  dataAtualizacaoInicial?: Date;
  dataAtualizacaoFinal?: Date;
  isFinalizado?: boolean;

  constructor(options: Partial<ProntuarioPacienteFilteredRequestDTO> = {}) {
    this.dataAtendimentoInicial = options.dataAtendimentoInicial;
    this.dataAtendimentoFinal = options.dataAtendimentoFinal;
    this.tipoAtendimentoId = options.tipoAtendimentoId;
    this.dataAtualizacaoInicial = options.dataAtualizacaoInicial;
    this.dataAtualizacaoFinal = options.dataAtualizacaoFinal;
    this.isFinalizado = options.isFinalizado;
  }
}
