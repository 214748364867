export class PacienteExportDTO {
  "Nome"?: string;
  "Nr. Carteirinha"?: string;
  "Psicólogo"?: string;
  "CRP"?: string;
  "Inicio"?: string;
  "Termino"?: string;
  "Sexo"?: string;
  "Data de nascimento"?: string;
  "Idade"?: number;
  "Ativo"?: string;
  "Médico"?: string;
  "Especialidade"?: string;
  "CEP"?: string;
  "Logradouro"?: string;
  "Complemento"?: string;
  "Bairro"?: string;
  "Cidade"?: string;
  "Estado"?: string;
  "Número"?: string;
  "Telefone"?: string;
  listaDeSessao?: string[];

  constructor(options: Partial<PacienteExportDTO> = {}) {
    this.Nome = options.Nome;
    this["Nr. Carteirinha"] = options["Nr. Carteirinha"];
    this["Psicólogo"] = options["Psicólogo"];
    this["CRP"] = options["CRP"];
    this.Inicio = options.Inicio;
    this.Termino = options.Termino;
    this.Sexo = options.Sexo;
    this["Data de nascimento"] = options["Data de nascimento"];
    this.Idade = options.Idade;
    this.Ativo = options.Ativo;
    this["Médico"] = options["Médico"];
    this.Especialidade = options.Especialidade;
    this["CEP"] = options["CEP"];
    this["Logradouro"] = options["Logradouro"];
    this["Complemento"] = options["Complemento"];
    this["Bairro"] = options["Bairro"];
    this["Cidade"] = options["Cidade"];
    this["Estado"] = options["Estado"];
    this["Número"] = options["Número"];
    this["Telefone"] = options["Telefone"];
    this.listaDeSessao = options.listaDeSessao;
  }
}
