import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { RoutesConfig as Routes } from "./config/RoutesConfig";
import { SessionControl } from "./config/SessionControl";
import ToggleMenu from "./components/toggle-menu/ToggleMenu";
import AuthDataService from "./data_services/auth/AuthDataService";
import { Provider } from "react-redux";
import store from "./reducers/store";
import ForceHttps from "./config/https/ForceHttps";

const root = document.getElementById("root");

ReactDOM.render(
  <main>
    <div>
      <Provider store={store}>
        <BrowserRouter forceRefresh={true}>
          <ForceHttps />
          <SessionControl />
          {AuthDataService.isUserLoggedIn() && <ToggleMenu></ToggleMenu>}
          {!AuthDataService.isUserLoggedIn() && <Routes />}
        </BrowserRouter>
      </Provider>
    </div>
  </main>,
  root
);
