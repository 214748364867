export class PsicologoRequestDTO {
  nome?: string;
  telefone?: string;
  email?: string;
  crp?: string;
  cidade?: string;
  estado?: string;
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  numero?: string;
  sexo?: string;

  constructor(options: Partial<PsicologoRequestDTO> = {}) {
    this.nome = options.nome;
    this.telefone = options.telefone;
    this.email = options.email;
    this.crp = options.crp;
    this.cidade = options.cidade;
    this.estado = options.estado;
    this.cep = options.cep;
    this.logradouro = options.logradouro;
    this.complemento = options.complemento;
    this.bairro = options.bairro;
    this.numero = options.numero;
    this.sexo = options.sexo;
  }
}
