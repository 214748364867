export class PacienteSessaoRequestDTO {
  id?: number;
  dataSessao?: Date;
  baixada?: boolean;
  observacao?: string;

  constructor(options: Partial<PacienteSessaoRequestDTO> = {}) {
    this.id = options.id;
    this.dataSessao = options.dataSessao;
    this.baixada = options.baixada;
    this.observacao = options.observacao;
  }
}
