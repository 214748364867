import "../PrintableContent.css";

export default function PrintableFooter() {
  return (
    <>
      <footer className="page-footer">
        <p style={{color: "#24345a"}}>
            PSICOLOGANDO - INSTITUTO DE SAÚDE MENTAL E EMOCIONAL - CNPJ
            42.113L.121/0001-90<br></br>
            Rua Albuquerque Lins, 77 A - Retiro da Mantiqueira, CEP: 12.712-630
            - Cruzeiro/SP<br></br>
            Tel. Whatsapp (12)9 9135-0419 - E-mail: psicologandoism@gmail.com
        </p>
      </footer>
    </>
  );
}
