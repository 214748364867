import "./Psicologo.css";
import {
  AtivarIcon,
  EditIcon,
  InativarIcon,
  NovoIcon,
  PsicologoIcon,
  ViewIcon,
} from "../../components/icons/Icons";
import { useCallback, useEffect, useState } from "react";
import Messages from "../../components/Messages";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import DefaultButton from "../../components/buttons/DefaultButton";
import AuthDataService from "../../data_services/auth/AuthDataService";
import CustomDataTable from "../../components/table/CustomDataTable";
import PsicologoService from "../../service/PsicologoService";
import { PsicologoDTO } from "../../model/dto/PsicologoDTO";
import {
  validarRequestBodyPsicologo,
  validarTrocaDeStatusPsicologoMessage,
} from "../../validator/PsicologoValidator";
import {
  fecharModalPsicologo,
  handleModalEditarPsicologo,
  handleModalNovoPsicologo,
  handleModalVisualizarPsicologo,
} from "./dialog/DialogCRUDPsicologoUtils";
import PsicologoActionTemplate from "./PsicologoActionTemplate";
import { PsicologoHeaderList } from "./header/PsicologoHeaderList";
import DialogCRUDPsicologo from "./dialog/DialogCRUDPsicologo";
import { RadioButton } from "primereact/radiobutton";
import TipoExibicaoEnum from "../../model/enums/TipoExibicaoEnum";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { exportPsicologosExcel } from "../../utils/excel/ExportPsicologoExcelUtils";

export default function Psicologo() {
  const [blockUi, setBlockUi] = useState(false);
  const [tipoExibicao, setTipoExibicao] = useState(TipoExibicaoEnum.TODOS);
  const [psicologosSemFiltro, setPsicologosSemFiltro] = useState([]);
  const [psicologos, setPsicologos] = useState([]);
  const [psicologoSelecionado, setPsicologoSelecionado] = useState(
    new PsicologoDTO()
  );
  const [totalPsicologos, setTotalPsicologos] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [isVisualizacao, setIsVisualizacao] = useState(false);
  const [isEdicao, setIsEdicao] = useState(false);

  const setPsicologoSelecionadoCallback = useCallback(
    function (p) {
      setPsicologoSelecionado(p);
    },
    [setPsicologoSelecionado]
  );

  useEffect(() => {
    carregarDados();
  }, []);

  async function alteraVisualizacao(value) {
    setTipoExibicao(value);
    switch (value) {
      case TipoExibicaoEnum.INATIVOS:
        await atualizaListaDePsicologos(false);
        break;
      case TipoExibicaoEnum.ATIVOS:
        await atualizaListaDePsicologos(true);
        break;
      default:
        await carregarDados();
        break;
    }
  }

  async function atualizaListaDePsicologos(value) {
    await carregarDados();
    const psicologosFiltrados = psicologosSemFiltro.filter(
      (u) => u.ativo === value
    );
    setPsicologos(psicologosFiltrados);
    setTotalPsicologos(psicologosFiltrados.length);
  }

  async function carregarDados() {
    setBlockUi(true);
    const [psicologosData] = await Promise.all([PsicologoService.getAll()]);
    setPsicologos(psicologosData);
    setPsicologosSemFiltro(psicologosData);
    setTotalPsicologos(psicologosData.length);
    setBlockUi(false);
  }

  async function salvar(psicologo) {
    if (!validarRequestBodyPsicologo(psicologo, isEdicao)) {
      fecharModalPsicologo(
        setIsEdicao,
        setDisplayModal,
        setPsicologoSelecionado,
        setIsVisualizacao
      );
      return;
    }
    try {
      setBlockUi(true);
      await PsicologoService.salvarDadosPsicologo(psicologo.id, psicologo);
      if (isEdicao) {
        fecharModalPsicologo(
          setIsEdicao,
          setDisplayModal,
          setPsicologoSelecionado,
          setIsVisualizacao
        );
        setBlockUi(false);
        Messages.successMessage("Sucesso", "Psicólogo editado com sucesso!");
        setTipoExibicao(TipoExibicaoEnum.TODOS);
        carregarDados();
      } else {
        fecharModalPsicologo(
          setIsEdicao,
          setDisplayModal,
          setPsicologoSelecionado,
          setIsVisualizacao
        );
        setBlockUi(false);
        Messages.successMessage("Sucesso", "Psicólogo criado com sucesso!");
        setTipoExibicao(TipoExibicaoEnum.TODOS);
        carregarDados();
      }
    } catch (error) {
      fecharModalPsicologo(
        setIsEdicao,
        setDisplayModal,
        setPsicologoSelecionado,
        setIsVisualizacao
      );
      setBlockUi(false);
      Messages.errorMessage("Erro", error.response.data.message);
    }
    setBlockUi(false);
  }

  async function trocarStatusPsicologo(rowData) {
    const result = await validarTrocaDeStatusPsicologoMessage(rowData);
    if (result.isConfirmed) {
      try {
        setBlockUi(true);
        await PsicologoService.trocarStatusPsicologo(rowData);
        fecharModalPsicologo(
          setIsEdicao,
          setDisplayModal,
          setPsicologoSelecionado,
          setIsVisualizacao
        );
        setBlockUi(false);
        Messages.successMessage("Sucesso", "Status alterado com sucesso");
        carregarDados();
      } catch (error) {
        fecharModalPsicologo(
          setIsEdicao,
          setDisplayModal,
          setPsicologoSelecionado,
          setIsVisualizacao
        );
        setBlockUi(false);
        Messages.errorMessage("Erro", error.response.data.message);
      }
    }
    setBlockUi(false);
  }

  function fecharModal() {
    fecharModalPsicologo(
      setIsEdicao,
      setDisplayModal,
      setPsicologoSelecionado,
      setIsVisualizacao
    );
  }

  function botaoVisualizarPsicologo(rowData) {
    return (
      <DefaultButton
        fontSize={15}
        icon={<ViewIcon />}
        messageToolTip={"Visualizar psicólogo"}
        click={(_) =>
          handleModalVisualizarPsicologo(
            rowData,
            setDisplayModal,
            setPsicologoSelecionado,
            setIsVisualizacao
          )
        }
        width={40}
        height={30}
      />
    );
  }

  function botaoEditarPsicologo(rowData) {
    return (
      <DefaultButton
        fontSize={15}
        icon={<EditIcon />}
        messageToolTip={"Editar dados do psicólogo"}
        click={(_) =>
          handleModalEditarPsicologo(
            rowData,
            setIsEdicao,
            setDisplayModal,
            setPsicologoSelecionado,
            setIsVisualizacao
          )
        }
        width={40}
        height={30}
      />
    );
  }

  function botaoTrocarStatusPsicologo(rowData) {
    return (
      <DefaultButton
        fontSize={15}
        icon={rowData.ativo ? <InativarIcon /> : <AtivarIcon />}
        messageToolTip={`${
          rowData.ativo ? "Inativar psicólogo" : "Ativar psicólogo"
        }`}
        click={(_) => trocarStatusPsicologo(rowData)}
        width={40}
        height={30}
      />
    );
  }

  function psicologoActionTemplate(rowData) {
    return (
      <PsicologoActionTemplate
        botaoVisualizarPsicologo={() => botaoVisualizarPsicologo(rowData)}
        botaoEditarPsicologo={() => botaoEditarPsicologo(rowData)}
        botaoTrocarStatusPsicologo={() => botaoTrocarStatusPsicologo(rowData)}
      />
    );
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div className="Psicologo">
        <div className="Title">
          <span>
            <PsicologoIcon /> Psicólogos
          </span>
        </div>
        <div></div>
      </div>
      <div className="Body">
        {AuthDataService.hasPermission(
          FuncionalidadeEnum.CADASTRO_PSICOLOGO
        ) && (
          <div className="NovoPsicologo">
            <DefaultButton
              fontSize={12}
              icon={<NovoIcon />}
              buttonLabel={"Adicionar"}
              messageToolTip={"Cadastrar um novo psicólogo(a)"}
              click={(_) =>
                handleModalNovoPsicologo(
                  setIsEdicao,
                  setDisplayModal,
                  setPsicologoSelecionado,
                  setIsVisualizacao
                )
              }
              width={150}
              height={30}
            />{" "}
            <div style={{ marginLeft: 10 }}></div>
            <div className="flex flex-wrap gap-3">
              <div className="flex align-items-center">
                <RadioButton
                  inputId="todos"
                  name="todos"
                  value={TipoExibicaoEnum.TODOS}
                  onChange={(e) => alteraVisualizacao(e.value)}
                  checked={tipoExibicao === TipoExibicaoEnum.TODOS}
                />
                <label htmlFor="todos" className="ml-2">
                  Todos
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="inativos"
                  name="inativos"
                  value={TipoExibicaoEnum.INATIVOS}
                  onChange={(e) => alteraVisualizacao(e.value)}
                  checked={tipoExibicao === TipoExibicaoEnum.INATIVOS}
                />
                <label htmlFor="inativos" className="ml-2">
                  Inativos
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="ativos"
                  name="ativos"
                  value={TipoExibicaoEnum.ATIVOS}
                  onChange={(e) => alteraVisualizacao(e.value)}
                  checked={tipoExibicao === TipoExibicaoEnum.ATIVOS}
                />
                <label htmlFor="ativos" className="ml-2">
                  Ativos
                </label>
              </div>
            </div>
          </div>
        )}
        <CustomDataTable
          exportar={true}
          dadosParaExportacao={PsicologoService.montaObjetoPsicologoExportacao(psicologos)}
          exportFunction={exportPsicologosExcel}
          values={psicologos}
          totalRecords={totalPsicologos}
          dataKey="id"
          headerList={PsicologoHeaderList}
          actionTemplate={psicologoActionTemplate}
          paginator={true}
          exibeActionTemplate={true}
        />
      </div>
      <DialogCRUDPsicologo
        display={displayModal}
        esconder={fecharModal}
        psicologo={psicologoSelecionado}
        setPsicologo={setPsicologoSelecionadoCallback}
        isVisualizacao={isVisualizacao}
        salvar={salvar}
      />
    </BlockUI>
  );
}
