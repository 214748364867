import { FuncionalidadeDTO } from "./FuncionalidadeDTO";

export class PerfilAcessoDTO {
  id?: number;
  codigo?: string;
  nome?: string;
  descricao?: string;
  funcionalidadeList?: FuncionalidadeDTO[];

  constructor(options: Partial<PerfilAcessoDTO> = {}) {
    this.id = options.id;
    this.codigo = options.codigo;
    this.nome = options.nome;
    this.descricao = options.descricao;
    this.funcionalidadeList = options.funcionalidadeList;
  }
}
