import { TemplatePadraoProntuarioRequestDTO } from "./../model/dto/request/TemplatePadraoProntuarioRequestDTO";
import templatePadraoProntuarioDataService from "../data_services/templates-prontuario/TemplatePadraoProntuarioDataService";
import { TemplatePadraoProntuarioDTO } from "../model/dto/TemplatePadraoProntuarioDTO";

class TemplatePadraoProntuarioService {
  static async getAllTemplatesPadrao(): Promise<TemplatePadraoProntuarioDTO[]> {
    const response =
      await templatePadraoProntuarioDataService.getAllTemplatesPadrao();
    const templates: TemplatePadraoProntuarioDTO[] = response.data;
    return templates;
  }

  static async getTemplatePadraoByTipoProntuarioId(
    tipoProntuarioId: number
  ): Promise<TemplatePadraoProntuarioDTO> {
    const response =
      await templatePadraoProntuarioDataService.getTemplatePadraoByTipoProntuarioId(
        tipoProntuarioId
      );
    const template: TemplatePadraoProntuarioDTO = response.data;
    return template;
  }

  static async salvarDadosTemplatePadraoProntuario(
    id: number,
    templatePadraoProntuario: any
  ) {
    const templatePadraoProntuarioRequestDTO = this.montaRequest(
      templatePadraoProntuario
    );
    if (id) {
      await this.atualizarTemplatePadraoProntuario(
        id,
        templatePadraoProntuarioRequestDTO
      );
    } else {
      await this.criarTemplatePadraoProntuario(
        templatePadraoProntuarioRequestDTO
      );
    }
  }

  private static async criarTemplatePadraoProntuario(
    templatePadraoProntuarioRequestDTO: TemplatePadraoProntuarioRequestDTO
  ) {
    await templatePadraoProntuarioDataService.criarTemplatePadraoProntuario(
      templatePadraoProntuarioRequestDTO
    );
  }

  private static async atualizarTemplatePadraoProntuario(
    templatePadraoProntuarioId: number,
    templatePadraoProntuarioRequestDTO: TemplatePadraoProntuarioRequestDTO
  ) {
    await templatePadraoProntuarioDataService.atualizarTemplatePadraoProntuario(
      templatePadraoProntuarioId,
      templatePadraoProntuarioRequestDTO
    );
  }

  private static montaRequest(
    templatePadraoProntuarioDTO: TemplatePadraoProntuarioDTO
  ) {
    return new TemplatePadraoProntuarioRequestDTO({
      tipoProntuarioId: templatePadraoProntuarioDTO.tipoProntuario?.id,
      htmlTemplate: templatePadraoProntuarioDTO.htmlTemplate,
      ativo: templatePadraoProntuarioDTO.ativo,
    });
  }
}

export default TemplatePadraoProntuarioService;
