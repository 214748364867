import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";
import {
  IniciarNovoAtendimentoIcon,
  TipoProntuarioIcon,
} from "../../../components/icons/Icons";

export default function DialogIniciarNovoAtendimento({
  display,
  esconder,
  tiposProntuario,
  pacientes,
  pacienteIdNomeSelecionado,
  setPacienteIdNomeSelecionado,
  iniciarAtendimento,
}) {
  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<IniciarNovoAtendimentoIcon />} Iniciar novo atendimento
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Informe os dados do novo atendimento">
          <Container>
            <Row>
              <label htmlFor="pacienteSelecionado">
                Paciente: &nbsp;&nbsp;
              </label>
              <Dropdown
                filter
                placeholder="Selecione um paciente"
                name="pacienteSelecionado"
                id="pacienteSelecionado"
                style={{
                  width: "100%",
                  height: "50%",
                  background: "#7988ac",
                }}
                value={
                  pacienteIdNomeSelecionado !== undefined
                    ? pacienteIdNomeSelecionado
                    : null
                }
                options={pacientes}
                optionLabel="paciente"
                emptyMessage="Não há informações"
                onChange={(e) => {
                  setPacienteIdNomeSelecionado(e.target.value);
                }}
              />
            </Row>
            <br></br>
            <Row>
              <label htmlFor="tipoAtendimentoSelect">
                Tipo do atendimento: &nbsp;&nbsp;
              </label>
              {tiposProntuario &&
                tiposProntuario.map((tipoProntuario, i) => {
                  return (
                    <Col key={tipoProntuario.id}>
                      <DefaultButton
                        fontSize={15}
                        icon={<TipoProntuarioIcon />}
                        buttonLabel={tipoProntuario.nome}
                        messageToolTip={tipoProntuario.nomeExibicao}
                        click={(_) => iniciarAtendimento(tipoProntuario)}
                        width={250}
                        height={40}
                      />
                    </Col>
                  );
                })}
            </Row>
          </Container>
        </Panel>
      </Dialog>
    </div>
  );
}
