import tipoAusenciaDataService from "../data_services/tipo-ausencia/TipoAusenciaDataService";
import { TipoAusenciaDTO } from "../model/dto/TipoAusenciaDTO";

class TipoAusenciaService {
  static async getAllTiposAusencia(): Promise<TipoAusenciaDTO[]> {
    const response = await tipoAusenciaDataService.getAllTiposAusencia();
    const tiposAusencia: TipoAusenciaDTO[] = response.data;
    return tiposAusencia;
  }
}

export default TipoAusenciaService;
