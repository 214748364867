import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";

class TipoAusenciaDataService {
  env = environment.getEnvironment();
  TIPO_AUSENCIA_ENDPOINTS = `${this.env.BACKEND_API_URL}/tipo-ausencia`;
  token = storageTokenConfig.getToken();

  getAllTiposAusencia() {
    return axios.get(`${this.TIPO_AUSENCIA_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const tipoAusenciaDataService = new TipoAusenciaDataService();
export default tipoAusenciaDataService;
