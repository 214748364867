import moment from "moment";
import PacienteDataService from "../data_services/paciente/PacienteDataService";
import { PacienteDTO } from "../model/dto/PacienteDTO";
import { PacientePsicologoDTO } from "../model/dto/PacientePsicologoDTO";
import { PsicologoDTO } from "../model/dto/PsicologoDTO";
import { PacienteExportDTO } from "../model/dto/export/PacienteExportDTO";
import { AtivarInativarPacienteRequestDTO } from "../model/dto/request/AtivarInativarPacienteRequestDTO";
import { PacienteFiltrosRequestDTO } from "../model/dto/request/PacienteFiltrosRequestDTO";
import { PacientePsicologoRequestDTO } from "../model/dto/request/PacientePsicologoRequestDTO";
import { PacienteRequestDTO } from "../model/dto/request/PacienteRequestDTO";
import { PacienteSessaoRequestDTO } from "../model/dto/request/PacienteSessaoRequestDTO";

class PacienteService {
  static async salvarDadosPaciente(id: number, paciente: any) {
    const pacienteRequest = this.montaRequest(paciente);
    if (id) {
      await this.atualizarPaciente(id, pacienteRequest);
    } else {
      await this.criarPaciente(pacienteRequest);
    }
  }

  static async trocarStatusPaciente(paciente: PacienteDTO) {
    await PacienteDataService.ativarInativarPaciente(
      this.montaRequestAtivarInativarPaciente(paciente)
    );
  }

  static async getAll(): Promise<PacienteDTO[]> {
    const response = await PacienteDataService.getAll();
    const pacientes: PacienteDTO[] = response.data;
    return pacientes;
  }

  static async filtrarPacientes(
    psicologoId: number,
    ativo: any,
    especialidadeId: number,
    exibeSessoesBaixadas: any
  ): Promise<PsicologoDTO[]> {
    const response = await PacienteDataService.getAllPacientesFiltrados(
      this.montaRequestFiltrarPaciente(
        psicologoId,
        ativo,
        especialidadeId,
        exibeSessoesBaixadas
      )
    );
    const pacientes: PacienteDTO[] = response.data;
    return pacientes;
  }

  static async adicionarSessoes(
    pacienteId: number,
    pacienteSessaoRequestList: any
  ) {
    await PacienteDataService.adicionarSessao(
      pacienteId,
      pacienteSessaoRequestList.map((sessao: any) => {
        return new PacienteSessaoRequestDTO({
          id: sessao.id,
          dataSessao: sessao.dataSessao,
          baixada: sessao.baixada,
          observacao: sessao.observacao,
        });
      })
    );
  }

  static async alterarDataVinculoPacientePsicologo(
    id: number,
    pacientePsicologo: PacientePsicologoDTO
  ) {
    await PacienteDataService.alterarDataVinculoPacientePsicologo(
      id,
      this.montaRequestAlterarDataVinculoPsicologo(pacientePsicologo)
    );
  }

  static async getAllPacientesPaginate(
    size?: number,
    page?: number,
    filters?: PacienteFiltrosRequestDTO
  ): Promise<PacienteDTO[]> {
    if (!filters) {
      filters = new PacienteFiltrosRequestDTO({});
    }
    if (!size) {
      size = 10;
    }

    if (!page) {
      page = 0;
    }

    const response = await PacienteDataService.getAllPacientesPaginate(
      size,
      page,
      filters
    );
    const pacientes: PacienteDTO[] = response.data;
    return pacientes;
  }

  static montaObjetoPacienteParaExportacao(pacientes: PacienteDTO[]) {
    return pacientes.map((paciente: PacienteDTO) => {
      return new PacienteExportDTO({
        Nome: paciente.paciente,
        "Nr. Carteirinha": paciente.nrCarteirinha,
        Psicólogo: paciente.psicologo?.nome,
        CRP: paciente.psicologo?.crp,
        Inicio: this.formatDate(paciente.inicioPlano),
        Termino: this.formatDate(paciente.fimPlano),
        Sexo: paciente.sexo,
        "Data de nascimento": this.formatDate(paciente.dataNascimento),
        Idade: paciente.idade,
        Ativo: this.getStatus(paciente.ativo),
        Médico: paciente.medico?.nome,
        Especialidade: paciente?.especialidade?.nome,
        CEP: paciente.cep,
        Logradouro: paciente.logradouro,
        Complemento: paciente.complemento,
        Bairro: paciente.bairro,
        Cidade: paciente.cidade,
        Estado: paciente.estado,
        Número: paciente.numero,
        Telefone: paciente.telefone,
        listaDeSessao:
          paciente.sessaoList && paciente.sessaoList.length > 0
            ? paciente.sessaoList.map((sessao: any) =>
                this.formatDate(sessao.dataSessao)
              )
            : [],
      });
    });
  }

  static getStatus(ativo: any) {
    if (ativo === true) {
      return "SIM";
    } else {
      return "NÃO";
    }
  }

  static formatDate(date: any) {
    if (date !== null) {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }

  private static montaRequestAtivarInativarPaciente(paciente: PacienteDTO) {
    return new AtivarInativarPacienteRequestDTO({
      pacienteId: paciente.id,
      ativo: !paciente.ativo,
    });
  }

  private static async atualizarPaciente(
    id: number,
    paciente: PacienteRequestDTO
  ) {
    await PacienteDataService.atualizarPaciente(id, paciente);
  }

  private static async criarPaciente(paciente: PacienteRequestDTO) {
    await PacienteDataService.criarPaciente(paciente);
  }

  private static montaRequest(paciente: PacienteDTO) {
    return new PacienteRequestDTO({
      paciente: paciente.paciente,
      nrCarteirinha: paciente.nrCarteirinha,
      psicologoId: paciente.psicologo?.id,
      inicioPlano: paciente.inicioPlano,
      fimPlano: paciente.fimPlano,
      sexo: paciente.sexo,
      dataNascimento: paciente.dataNascimento,
      idade: paciente.idade,
      medicoId: paciente.medico?.id,
      sessoes: paciente.sessaoList,
      cep: paciente.cep,
      logradouro: paciente.logradouro,
      complemento: paciente.complemento,
      bairro: paciente.bairro,
      cidade: paciente.cidade,
      estado: paciente.estado,
      numero: paciente.numero,
      telefone: paciente.telefone,
      especialidadeId: paciente.especialidade?.id,
      observacao: paciente.observacao,
      emitirVpp: paciente.emitirVpp,
    });
  }

  private static montaRequestFiltrarPaciente(
    psicologoId: number,
    ativo: any,
    especialidadeId: number,
    exibeSessoesBaixadas: any
  ) {
    return new PacienteFiltrosRequestDTO({
      psicologoId: psicologoId,
      ativo: ativo,
      especialidadeId: especialidadeId,
      exibeSessoesBaixadas: exibeSessoesBaixadas,
    });
  }

  private static montaRequestAlterarDataVinculoPsicologo(
    pacientePsicologo: PacientePsicologoDTO
  ) {
    return new PacientePsicologoRequestDTO({
      dataInicioVinculo: pacientePsicologo.dataInicioVinculo,
      dataFimVinculo: pacientePsicologo.dataFimVinculo,
    });
  }
}

export default PacienteService;
