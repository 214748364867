export function handleModalNovoMedico(
    setIsEdicao,
    setDisplayModal,
    setMedicoSelecionado,
    setIsVisualizacao
  ) {
    setIsEdicao(false);
    setDisplayModal(true);
    setMedicoSelecionado({});
    setIsVisualizacao(false);
  }
  
  export function handleModalVisualizarMedico(
    rowData,
    setDisplayModal,
    setMedicoSelecionado,
    setIsVisualizacao
  ) {
    setDisplayModal(true);
    setMedicoSelecionado(rowData);
    setIsVisualizacao(true);
  }
  
  export function handleModalEditarMedico(
    rowData,
    setIsEdicao,
    setDisplayModal,
    setMedicoSelecionado,
    setIsVisualizacao
  ) {
    setIsEdicao(true);
    setDisplayModal(true);
    setMedicoSelecionado(rowData);
    setIsVisualizacao(false);
  }
  
  export function fecharModalMedico(
    setIsEdicao,
    setDisplayModal,
    setMedicoSelecionado,
    setIsVisualizacao
  ) {
    setIsEdicao(false);
    setDisplayModal(false);
    setMedicoSelecionado({});
    setIsVisualizacao(false);
  }
  