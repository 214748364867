/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function ForceHttps() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const isHttp = window.location.protocol === "http:";
    if (
      (process.env.REACT_APP_NODE_ENV === "HML" ||
        process.env.REACT_APP_NODE_ENV === "PROD") &&
      isHttp
    ) {
      const newUrl = window.location.href.replace(/^http:/, "https:");
      window.location.replace(newUrl);
    }
  }, [history, location]);

  return null;
}
