export class MeusPacientesIdNomeDTO {
  id?: number;
  paciente?: string;
  nrCarteirinha?: string;

  constructor(options: Partial<MeusPacientesIdNomeDTO> = {}) {
    this.id = options.id;
    this.paciente = options.paciente;
    this.nrCarteirinha = options.nrCarteirinha;
  }
}
