import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";
import { MeusPacientesFilteredRequestDTO } from "../../model/dto/request/MeusPacientesFilteredRequestDTO";

class MeusPacientesDataService {
  env = environment.getEnvironment();
  MEUS_PACIENTES_ENDPOINT = `${this.env.BACKEND_API_URL}/meus-pacientes`;
  token = storageTokenConfig.getToken();

  getMeusPacientes(
    size: number,
    page: number,
    filters?: MeusPacientesFilteredRequestDTO
  ) {
    return axios.post(
      `${this.MEUS_PACIENTES_ENDPOINT}?size=${size}&&page=${page}`,
      filters,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  getMeusPacientesIdNome() {
    return axios.get(`${this.MEUS_PACIENTES_ENDPOINT}/all`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const meusPacientesDataService = new MeusPacientesDataService();
export default meusPacientesDataService;
