export class EspecialidadeDTO {
  id?: number;
  nome?: string;
  cbo?: string;

  constructor(options: Partial<EspecialidadeDTO> = {}) {
    this.id = options.id;
    this.nome = options.nome;
    this.cbo = options.cbo;
  }
}
