import PropTypes from "prop-types";
import { CancelIcon, PasswordIcon, SaveIcon } from "../../icons/Icons";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";

import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import DefaultButton from "../../buttons/DefaultButton";
import UsuarioDataService from "../../../data_services/usuario/UsuarioDataService";
import Messages from "../../Messages";

export default function DialogAlterarSenha(props) {
  const { exibirDialog, usuario, esconder } = props;

  const [antigaSenha, setAntigaSenha] = useState("");
  const [novaSenha, setNovaSenha] = useState("");

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<PasswordIcon />} {"Alterar minha senha"}
      </div>
    );
  };

  function alterarSenha() {
    if (antigaSenha === "" || novaSenha === "") {
      esconder();
      Messages.errorMessage(
        "Erro!",
        "É necessário preencher os campos 'antiga senha' e 'nova senha'"
      );
      return;
    }
    UsuarioDataService.alterarSenha(usuario, antigaSenha, novaSenha)
      .then((_) => {
        esconder();
        Messages.successMessage("Sucesso!", "Senha alterada com sucesso!");
      })
      .catch((error) => {
        esconder();
        Messages.errorMessage("Erro!", error.response.data.message);
      });
  }

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={exibirDialog}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Insira os dados da senha antiga para alterar!">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioId">
                  <b>Senha antiga: </b>
                </label>
                <br></br>
                <InputText
                  style={{ width: "100%", textAlign: "center" }}
                  type="password"
                  onChange={(e) => {
                    setAntigaSenha(e.target.value);
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioId">
                  <b>Senha Nova: </b>
                </label>
                <br></br>
                <Password
                  promptLabel={"Informe uma senha"}
                  weakLabel={"Complexidade da senha: fraca"}
                  mediumLabel={"Complexidade da senha: média"}
                  strongLabel={"Complexidade da senha: forte"}
                  toggleMask
                  style={{width: "100%", textAlign: "center" }}
                  type="password"
                  onChange={(e) => {
                    setNovaSenha(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </Container>
          <div className="Footer">
            <DefaultButton
              fontSize={15}
              icon={<SaveIcon />}
              buttonLabel={"Alterar"}
              messageToolTip={"Alterar a senha"}
              click={(_) => alterarSenha()}
              width={150}
              height={50}
            />
            <DefaultButton
              fontSize={15}
              icon={<CancelIcon />}
              buttonLabel={"Cancelar"}
              messageToolTip={"Cancelar operação"}
              click={esconder}
              width={150}
              height={50}
            />
          </div>
        </Panel>
      </Dialog>
    </div>
  );
}

DialogAlterarSenha.propTypes = {
  exibirDialog: PropTypes.bool,
  usuario: PropTypes.object,
  esconder: PropTypes.func,
};
