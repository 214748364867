import { PACIENTE_SELECIONADO } from "../actions/PacienteSelecionadoAction";

const initialState = {
  pacienteSelecionado: {},
};

export const pacienteSelecionadoReducer = (state = initialState, action) => {
  switch (action.type) {
    case PACIENTE_SELECIONADO:
      return {
        ...state,
        pacienteSelecionado: action.pacienteSelecionado,
      };
    default:
      return state; // Retorna o estado atual por padrão
  }
};
