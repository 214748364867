import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import message from "../../../components/Messages";
import DefaultButton from "../../../components/buttons/DefaultButton";
import { FiltrarIcon, LimparIcon } from "../../../components/icons/Icons";
import CustomPaginateDataTable from "../../../components/table/CustomPaginateDataTable";
import { PacienteDTO } from "../../../model/dto/PacienteDTO";
import { PsicologoDTO } from "../../../model/dto/PsicologoDTO";
import { UsuarioDTO } from "../../../model/dto/UsuarioDTO";
import { RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO } from "../../../model/dto/request/RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO";
import RelatorioService from "../../../service/RelatorioService";
import { exportRelatorioHistoricoAlteracaoPsicologo } from "../../../utils/excel/ExportRelatorioHistoricoAlteracaoPsicologoUtils";
import "./RelatorioHistoricoAlteracaoPsicologo.css";
import { RelatorioHistoricoAlteracaoPsicologoHeader } from "./RelatorioHistoricoAlteracaoPsicologoHeader";

export default function RelatorioHistoricoAlteracaoPsicologo({
  psicologos,
  pacientes,
  usuarios,
}) {
  const [blockUi, setBlockUi] = useState(false);
  const [dados, setDados] = useState([]);
  const [totalDados, setTotalDados] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(10);
  const [pagina, setPagina] = useState(0);

  const [filtros, setFiltros] = useState(
    new RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO()
  );

  const [filtroPsicologoSelecionado, setFiltroPsicologoSelecionado] = useState(
    new PsicologoDTO()
  );
  const [filtroPacienteSelecionado, setFiltroPacienteSelecionado] = useState(
    new PacienteDTO()
  );
  const [filtroUsuarioSelecionado, setFiltroUsuarioSelecionado] = useState(
    new UsuarioDTO()
  );

  const setLinhasPorPaginaCallback = useCallback(
    function (l) {
      setLinhasPorPagina(l);
    },
    [setLinhasPorPagina]
  );

  const setPaginaCallback = useCallback(
    function (p) {
      setPagina(p);
    },
    [setPagina]
  );

  async function filtrarRelatorio(size, page) {
    setBlockUi(true);
    RelatorioService.getRelatorioHistoricoAlteracaoPsicologo(
      size,
      page,
      filtros
    ).then((response) => {
      if (response.content.length === 0) {
        message.errorMessage(
          "Atenção!",
          "Não existem registros de acordo com os filtros informados!"
        );
        setBlockUi(false);
        limpar();
      } else {
        setDados(response.content);
        setTotalDados(response.totalElements);
        setBlockUi(false);
      }
    });
    setBlockUi(false);
  }

  function limpar() {
    setFiltros(new RelatorioHistoricoAlteracaoPsicologoFilteredRequestDTO());
    setFiltroPacienteSelecionado(new PacienteDTO());
    setFiltroPsicologoSelecionado(new PsicologoDTO());
    setFiltroUsuarioSelecionado(new UsuarioDTO());
    setLinhasPorPagina(10);
    setPagina(0);
    setDados([]);
    setTotalDados(0);
  }

  return (
    <>
      <BlockUI
        width={"100%"}
        template={
          <ProgressSpinner
            strokeWidth="8"
            className="blue-spinner"
            style={{ width: "40px" }}
          />
        }
        blocked={blockUi}
      >
        <div className="RelatorioHistoricoAlteracaoPsicologo">
          <h4>Relatório de histórico de alteração de psicólogos</h4>
          <Container>
            <Row>
              <Col>
                <label htmlFor="pacienteFilter">Paciente: &nbsp;&nbsp;</label>
                <Dropdown
                  filter
                  className="FiltroPaciente"
                  placeholder="Selecione um paciente"
                  name="pacienteFilter"
                  id="pacienteFilter"
                  style={{
                    width: "100%",
                    height: "50%",
                    background: "#7988ac",
                  }}
                  value={
                    filtroPacienteSelecionado?.id !== undefined
                      ? filtroPacienteSelecionado
                      : {}
                  }
                  options={pacientes}
                  optionLabel="paciente"
                  emptyMessage="Não há pacientes"
                  onChange={(e) => {
                    setFiltroPacienteSelecionado(e.target.value);
                    setFiltros({
                      ...filtros,
                      pacienteId: e.target.value.id,
                    });
                  }}
                />
              </Col>
              <Col>
                <label htmlFor="psicologoFilter">Psicólogo: &nbsp;&nbsp;</label>
                <Dropdown
                  filter
                  className="FiltroPsicologo"
                  placeholder="Selecione um psicólogo"
                  name="psicologoFilter"
                  id="psicologoFilter"
                  style={{
                    width: "100%",
                    height: "50%",
                    background: "#7988ac",
                  }}
                  value={
                    filtroPsicologoSelecionado?.id !== undefined
                      ? filtroPsicologoSelecionado
                      : {}
                  }
                  options={psicologos}
                  optionLabel="nome"
                  emptyMessage="Não há psicologos"
                  onChange={(e) => {
                    setFiltroPsicologoSelecionado(e.target.value);
                    setFiltros({
                      ...filtros,
                      psicologoId: e.target.value.id,
                    });
                  }}
                />
              </Col>
              <Col>
                <label htmlFor="dataInicioVinculoFilter">
                  Data início vínculo (de): &nbsp;&nbsp;
                </label>
                <InputText
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    filtros?.dataInicialInicioVinculo !== undefined
                      ? filtros.dataInicialInicioVinculo
                      : ""
                  }
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      dataInicialInicioVinculo: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <label htmlFor="dataFimVinculoFilter">
                  Data início vínculo (até): &nbsp;&nbsp;
                </label>
                <InputText
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    filtros?.dataFinalInicioVinculo !== undefined
                      ? filtros.dataFinalInicioVinculo
                      : ""
                  }
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      dataFinalInicioVinculo: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <label htmlFor="usuarioFilter">
                  Usuário ult. atualização: &nbsp;&nbsp;
                </label>
                <Dropdown
                  filter
                  className="FiltroUsuario"
                  placeholder="Selecione um usuário"
                  name="usuarioFilter"
                  id="usuarioFilter"
                  style={{
                    width: "100%",
                    height: "50%",
                    background: "#7988ac",
                  }}
                  value={
                    filtroUsuarioSelecionado?.id !== undefined
                      ? filtroUsuarioSelecionado
                      : {}
                  }
                  options={usuarios}
                  optionLabel="nome"
                  emptyMessage="Não há usuários"
                  onChange={(e) => {
                    setFiltroUsuarioSelecionado(e.target.value);
                    setFiltros({
                      ...filtros,
                      usuarioId: e.target.value.id,
                    });
                  }}
                />
              </Col>
            </Row>
            <div className="FilterRelatorioHistoricoAlteracaoPsicologo">
              <DefaultButton
                fontSize={12}
                icon={<FiltrarIcon />}
                buttonLabel={"Buscar"}
                messageToolTip={
                  "Busca os resultados de acordo com os parâmetros"
                }
                click={(_) => {
                  filtrarRelatorio(linhasPorPagina, pagina);
                }}
                width={150}
                height={30}
              />{" "}
              <DefaultButton
                fontSize={12}
                icon={<LimparIcon />}
                buttonLabel={"Limpar"}
                messageToolTip={"Limpa os filtros e os dados da tabela"}
                click={limpar}
                width={150}
                height={30}
              />{" "}
            </div>
          </Container>
          {totalDados > 0 && (
            <CustomPaginateDataTable
              exportar={true}
              dadosParaExportacao={RelatorioService.montaObjetoParaExportacaoRelatorioHistoricoAlteracaoPsicologo(
                dados
              )}
              exportFunction={exportRelatorioHistoricoAlteracaoPsicologo}
              values={dados}
              totalRecords={totalDados}
              dataKey="id"
              headerList={RelatorioHistoricoAlteracaoPsicologoHeader}
              actionTemplate={null}
              paginator={true}
              exibeActionTemplate={false}
              callbackFunction={filtrarRelatorio}
              linhasPorPagina={linhasPorPagina}
              setLinhasPorPagina={setLinhasPorPaginaCallback}
              setPagina={setPaginaCallback}
              fieldToSort={"dataHoraUltimaAtualizacao"}
              showInLeft={true}
            />
          )}
        </div>
      </BlockUI>
    </>
  );
}
