import { useState } from "react";
import {
  AgendaIcon,
  EspecialidadeIcon,
  HomeIcon,
  MedicoIcon,
  MenuIcon,
  MeusAtendimentosIcon,
  MeusPacientesIcon,
  ModeloProntuarioIcon,
  PacienteIcon,
  PerfilAcessoIcon,
  PsicologoIcon,
  RelatoriosIcon,
  UsuarioIcon,
} from "../icons/Icons";
import "./ToggleMenu.css";
import { RoutesConfig } from "../../config/RoutesConfig";
import AuthDataService from "../../data_services/auth/AuthDataService";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import { useHistory } from "react-router-dom";
import NavBar from "../nav/NavBar";
import { Tooltip } from "primereact/tooltip";

function ToggleMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  function navigate(url) {
    history.push(url);
  }

  const handleTrigger = () => setIsOpen(!isOpen);

  return (
    <>
      <NavBar />
      <div className="App">
        <div className="page">
          <div className="content">
            <RoutesConfig />
          </div>

          <div className={`sidebar ${isOpen ? "sidebar--open" : ""}`}>
            <div className="trigger" onClick={handleTrigger}>
              <MenuIcon />
            </div>

            <div
              className="sidebar-position"
              onClick={() => {
                navigate("/home");
              }}
            >
              <HomeIcon className="HomeIcon" />
              <span>Home</span>
              {!isOpen && <Tooltip target={".HomeIcon"}>Home page</Tooltip>}
            </div>
            {AuthDataService.hasPermission(
              FuncionalidadeEnum.VISUALIZAR_MEUS_PACIENTES
            ) && (
              <div
                className="sidebar-position"
                onClick={() => {
                  navigate("/meus-pacientes");
                }}
              >
                <MeusPacientesIcon className="PacientesPsicologo" />
                <span>Meus pacientes</span>
                {!isOpen && (
                  <Tooltip target={".PacientesPsicologo"}>
                    Meus pacientes
                  </Tooltip>
                )}
              </div>
            )}
            {AuthDataService.hasPermission(
              FuncionalidadeEnum.VISUALIZACAO_AGENDA
            ) && (
              <div
                className="sidebar-position"
                onClick={() => {
                  navigate("/agenda");
                }}
              >
                <AgendaIcon className="AgendaPsicologo" />
                <span>Agenda</span>
                {!isOpen && (
                  <Tooltip target={".AgendaPsicologo"}>
                    Agenda
                  </Tooltip>
                )}
              </div>
            )}
            {AuthDataService.hasPermission(
              FuncionalidadeEnum.VISUALIZAR_MEUS_ATENDIMENTOS
            ) && (
              <div
                className="sidebar-position"
                onClick={() => {
                  navigate("/meus-atendimentos");
                }}
              >
                <MeusAtendimentosIcon className="AtendimentosPsicologo" />
                <span>Meus atendimentos</span>
                {!isOpen && (
                  <Tooltip target={".AtendimentosPsicologo"}>
                    Meus atendimentos
                  </Tooltip>
                )}
              </div>
            )}
            {AuthDataService.hasPermission(
              FuncionalidadeEnum.VISUALIZACAO_PSICOLOGO
            ) && (
              <div
                className="sidebar-position"
                onClick={() => {
                  navigate("/psicologo");
                }}
              >
                <PsicologoIcon className="Psicologos" />
                <span>Psicólogos</span>
                {!isOpen && (
                  <Tooltip target={".Psicologos"}>Psicólogos</Tooltip>
                )}
              </div>
            )}
            {AuthDataService.hasPermission(
              FuncionalidadeEnum.VISUALIZACAO_PACIENTE
            ) && (
              <div
                className="sidebar-position"
                onClick={() => {
                  navigate("/paciente");
                }}
              >
                <PacienteIcon className="Pacientes" />
                <span>Cadastro de Pacientes</span>
                {!isOpen && (
                  <Tooltip target={".Pacientes"}>Cadastro de Pacientes</Tooltip>
                )}
              </div>
            )}
            {AuthDataService.hasPermission(
              FuncionalidadeEnum.VISUALIZACAO_ESPECIALIDADE
            ) && (
              <div
                className="sidebar-position"
                onClick={() => {
                  navigate("/especialidade");
                }}
              >
                <EspecialidadeIcon className="Especialidades" />
                <span>Especialidades</span>
                {!isOpen && (
                  <Tooltip target={".Especialidades"}>Especialidades</Tooltip>
                )}
              </div>
            )}
            {AuthDataService.hasPermission(
              FuncionalidadeEnum.CADASTRO_PERFIL
            ) && (
              <div
                className="sidebar-position"
                onClick={() => {
                  navigate("/perfil-acesso");
                }}
              >
                <PerfilAcessoIcon className="Permissoes" />
                <span>Permissões</span>
                {!isOpen && (
                  <Tooltip target={".Permissoes"}>Permissões</Tooltip>
                )}
              </div>
            )}
            {AuthDataService.hasPermission(
              FuncionalidadeEnum.VISUALIZACAO_USUARIO
            ) && (
              <div
                className="sidebar-position"
                onClick={() => {
                  navigate("/usuario");
                }}
              >
                <UsuarioIcon className="Usuarios" />
                <span>Usuários</span>
                {!isOpen && <Tooltip target={".Usuarios"}>Usuários</Tooltip>}
              </div>
            )}
            {AuthDataService.hasPermission(
              FuncionalidadeEnum.VISUALIZACAO_MEDICO
            ) && (
              <div
                className="sidebar-position"
                onClick={() => {
                  navigate("/medico");
                }}
              >
                <MedicoIcon className="Medicos" />
                <span>Médicos</span>
                {!isOpen && <Tooltip target={".Medicos"}>Médicos</Tooltip>}
              </div>
            )}
            <div
              className="sidebar-position"
              onClick={() => {
                navigate("/modelos-prontuario");
              }}
            >
              <ModeloProntuarioIcon className="Modelos" />
              <span>Modelos de prontuário</span>
              {!isOpen && (
                <Tooltip target={".Modelos"}>Modelos de prontuário</Tooltip>
              )}
            </div>
            {AuthDataService.hasPermission(
              FuncionalidadeEnum.VISUALIZAR_RELATORIOS
            ) && (
              <div
                className="sidebar-position"
                onClick={() => {
                  navigate("/relatorios");
                }}
              >
                <RelatoriosIcon className="RelatoriosOption" />
                <span>Relatórios</span>
                {!isOpen && (
                  <Tooltip target={".RelatoriosOption"}>Relatórios</Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ToggleMenu;
