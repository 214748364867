import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { addLocale } from "primereact/api";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";
import {
  AgendaIcon,
  AtivarIcon,
  CancelIcon,
  CancelarEventoIcon,
  DeleteIcon,
  SaveIcon,
} from "../../../components/icons/Icons";
import TipoManipulacaoAgendaEnum from "../../../model/enums/TipoManipulacaoAgendaEnum";
import { formatData, formatDataHora } from "../../../utils/FormatDataUtils";
import "./DialogAdicionarEvento.css";
import DialogCancelarEvento from "./DialogCancelarEvento";
import DialogStatusComparecimento from "./DialogStatusComparecimento";

export default function DialogAdicionarEvento({
  display,
  esconder,
  evento,
  setEvento,
  tiposEvento,
  tipoManipulacaoEvento,
  salvarEvento,
  meusPacientes,
  removerEvento,
  cancelarEvento,
  alterarStatusComparecimento,
  tiposAusencia,
}) {
  const [replicaEventoNoMes, setReplicaEventoNoMes] = useState(false);
  const [displayModalCancelarEvento, setDisplayModalCancelarEvento] =
    useState(false);
  const [
    displayModalStatusComparecimento,
    setDisplayModalStatusComparecimento,
  ] = useState(false);

  useEffect(() => {
    addLocale("pt-br", {
      firstDayOfWeek: 1,
      dayNames: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
    });
  }, []);

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<AgendaIcon />}{" "}
        {tipoManipulacaoEvento === TipoManipulacaoAgendaEnum.CRIACAO_EVENTO
          ? "Criar novo evento"
          : evento?.nomeEvento}
        {evento?.inicio && <div>{formatData(evento.inicio)}</div>}
      </div>
    );
  };

  function getBodyDataPaciente() {
    if (
      tipoManipulacaoEvento === TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO
    ) {
      return (
        <Col>
          <label style={{ color: "#24345a" }} htmlFor="pacienteSelecionado">
            <b>Paciente: </b>
          </label>
          <br></br>
          <InputText
            disabled={true}
            style={{ width: "100%", textAlign: "center" }}
            type="text"
            value={evento?.paciente?.paciente ?? ""}
          />
        </Col>
      );
    } else if (
      meusPacientes !== undefined &&
      meusPacientes.length > 0 &&
      evento !== undefined &&
      evento !== null &&
      evento.tipoEventoAgenda !== undefined &&
      evento.tipoEventoAgenda !== null &&
      evento.tipoEventoAgenda.nomeTipoEvento === "Consulta"
    ) {
      return (
        <Col>
          <label style={{ color: "#24345a" }} htmlFor="pacienteSelecionado">
            <b>Paciente: </b>
          </label>
          <br></br>
          <Dropdown
            disabled={
              tipoManipulacaoEvento ===
              TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO
            }
            filter
            style={{ width: "100%", background: "#7988ac" }}
            value={evento?.paciente ?? ""}
            options={meusPacientes}
            optionLabel="paciente"
            emptyMessage="Não há informações"
            onChange={(e) => {
              setEvento({
                ...evento,
                paciente: e.value,
              });
            }}
          />
        </Col>
      );
    }
  }

  function fecharModalCancelarEvento() {
    setDisplayModalCancelarEvento(false);
  }

  function fecharModalStatusComparecimento() {
    setDisplayModalStatusComparecimento(false);
  }

  function handleDisplayModalCancelarEvento() {
    setDisplayModalCancelarEvento(true);
  }

  function handleDisplayStatusComparecimento() {
    setDisplayModalStatusComparecimento(true);
  }

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados do evento">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="nomeEvento">
                  <b>Nome: </b>
                </label>
                <br></br>
                <InputText
                  disabled={
                    tipoManipulacaoEvento ===
                    TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO
                  }
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={evento?.nomeEvento ?? ""}
                  onChange={(e) => {
                    setEvento({
                      ...evento,
                      nomeEvento: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="tipoEventoSelecionado"
                >
                  <b>Tipo: </b>
                </label>
                <br></br>
                <Dropdown
                  disabled={
                    tipoManipulacaoEvento ===
                    TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO
                  }
                  filter
                  style={{ width: "100%", background: "#7988ac" }}
                  value={
                    evento !== null && evento.tipoEventoAgenda !== null
                      ? evento.tipoEventoAgenda
                      : ""
                  }
                  options={tiposEvento}
                  optionLabel="nomeTipoEvento"
                  emptyMessage="Não há informações"
                  onChange={(e) => {
                    setEvento({
                      ...evento,
                      tipoEventoAgenda: e.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>{getBodyDataPaciente()}</Row>
            {evento?.pacienteCompareceu === false && (
              <Row>
                <Col>
                  <label
                    style={{ color: "#24345a" }}
                    htmlFor="justificativaAusencia"
                  >
                    <b>Justificativa da ausência: </b>
                  </label>
                  <br></br>
                  <InputTextarea
                    disabled={true}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      backgroundColor: "#7988ac",
                      color: "#fff",
                    }}
                    type="text"
                    value={evento?.justificativaAusencia ?? ""}
                  />
                </Col>
              </Row>
            )}
            {evento && evento.isCancelado && (
              <Row>
                <Col>
                  <label
                    style={{ color: "#24345a" }}
                    htmlFor="dataCancelamento"
                  >
                    <b>Data do cancelamento: </b>
                  </label>
                  <br></br>
                  <InputText
                    disabled={true}
                    style={{ width: "100%", textAlign: "center" }}
                    type="text"
                    value={formatDataHora(evento?.dataCancelamento) ?? ""}
                  />
                </Col>
                <Col>
                  <label
                    style={{ color: "#24345a" }}
                    htmlFor="motivoCancelamento"
                  >
                    <b>Motivo cancelamento: </b>
                  </label>
                  <br></br>
                  <InputTextarea
                    disabled={true}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      backgroundColor: "#7988ac",
                      color: "#fff",
                    }}
                    type="text"
                    value={evento?.motivoCancelamento ?? ""}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="dataInicioEvento">
                  <b>Início: </b>
                </label>
                <br></br>
                <LocalizationProvider
                  localeText={{
                    timePickerToolbarTitle:
                      "Informe a hora de início do evento",
                    cancelButtonLabel: "Cancelar",
                    okButtonLabel: "Ok",
                  }}
                  dateAdapter={AdapterDayjs}
                >
                  <DemoItem>
                    <MobileTimePicker
                      disabled={
                        tipoManipulacaoEvento ===
                        TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO
                      }
                      ampm={false}
                      sx={{
                        "& div": {
                          backgroundColor: "#7988ac",
                          border: "0.25px solid white",
                          borderRadius: 1.5,
                          "& input": {
                            textAlign: "center",
                            backgroundColor: "#7988ac",
                            borderRadius: 1.5,
                            fontSize: 10,
                            height: "unset",
                            lineHeight: 3.6,
                            p: 0,
                            color: "white",
                          },
                          "& fieldset": {
                            borderColor: "#7988ac",
                          },
                        },
                      }}
                      defaultValue={dayjs(evento?.inicio ?? "")}
                      onChange={(e) => {
                        setEvento({
                          ...evento,
                          inicio: e,
                        });
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="dataFimEvento">
                  <b>Fim: </b>
                </label>
                <br></br>
                <LocalizationProvider
                  localeText={{
                    timePickerToolbarTitle: "Informe a hora de fim do evento",
                    cancelButtonLabel: "Cancelar",
                    okButtonLabel: "Ok",
                  }}
                  dateAdapter={AdapterDayjs}
                >
                  <DemoItem>
                    <MobileTimePicker
                      disabled={
                        tipoManipulacaoEvento ===
                        TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO
                      }
                      ampm={false}
                      sx={{
                        "& div": {
                          backgroundColor: "#7988ac",
                          border: "0.25px solid white",
                          borderRadius: 1.5,
                          "& input": {
                            textAlign: "center",
                            backgroundColor: "#7988ac",
                            borderRadius: 1.5,
                            fontSize: 10,
                            height: "unset",
                            lineHeight: 3.6,
                            p: 0,
                            color: "white",
                          },
                          "& fieldset": {
                            borderColor: "#7988ac",
                          },
                        },
                      }}
                      defaultValue={dayjs(evento?.fim ?? "")}
                      onChange={(e) => {
                        setEvento({
                          ...evento,
                          fim: e,
                        });
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Col>
              {tipoManipulacaoEvento ===
                TipoManipulacaoAgendaEnum.CRIACAO_EVENTO && (
                <Col>
                  <label
                    style={{ color: "#24345a" }}
                    htmlFor="replicarEventoNoMes"
                    className="replicarEventoNoMes"
                  >
                    <b>Replicar evento no mês?: </b>
                    <span>ⓘ</span>
                    <Tooltip target={".replicarEventoNoMes"}>
                      Replica as configurações deste evento, para este dia da
                      semana, no restante do mês corrente.
                    </Tooltip>
                  </label>
                  <br></br>
                  <Checkbox
                    style={{ marginTop: "15px" }}
                    disabled={
                      tipoManipulacaoEvento ===
                      TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO
                    }
                    inputId="bloqued"
                    checked={replicaEventoNoMes}
                    onChange={(e) => {
                      setReplicaEventoNoMes(e.checked);
                    }}
                  ></Checkbox>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="observações">
                  <b>Observações: </b>
                </label>
                <InputTextarea
                  className="ObservacoesAgendaInputTextArea"
                  disabled={
                    tipoManipulacaoEvento ===
                    TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO
                  }
                  rows={3}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#7988ac",
                    color: "#fff",
                  }}
                  type="text"
                  value={evento?.observacao ?? ""}
                  onChange={(e) => {
                    setEvento({
                      ...evento,
                      observacao: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        <div className="FooterDialogAdicionarEvento">
          {tipoManipulacaoEvento !==
            TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO && (
            <DefaultButton
              fontSize={15}
              icon={<SaveIcon />}
              buttonLabel={"Salvar"}
              messageToolTip={"Salvar operação"}
              click={(_) => salvarEvento(evento, replicaEventoNoMes)}
              width={150}
              height={30}
            />
          )}
          {tipoManipulacaoEvento ===
            TipoManipulacaoAgendaEnum.EDICAO_EVENTO && (
            <>
              <DefaultButton
                fontSize={15}
                icon={<DeleteIcon />}
                buttonLabel={"Remover"}
                messageToolTip={"Remover este evento"}
                click={(_) => removerEvento(evento)}
                width={150}
                height={30}
              />
              <DefaultButton
                fontSize={15}
                icon={<CancelarEventoIcon />}
                buttonLabel={
                  evento && evento.isCancelado ? `Reativar` : `Cancelar`
                }
                messageToolTip={
                  evento && evento.isCancelado
                    ? `Reativa este evento`
                    : `Cancela este evento`
                }
                click={handleDisplayModalCancelarEvento}
                width={150}
                height={30}
              />
            </>
          )}
          {evento &&
            tipoManipulacaoEvento === TipoManipulacaoAgendaEnum.EDICAO_EVENTO &&
            !evento?.isCancelado &&
            evento?.tipoEventoAgenda?.nomeTipoEvento === "Consulta" && (
              <DefaultButton
                fontSize={15}
                icon={<AtivarIcon />}
                buttonLabel={"Confirmar consulta"}
                messageToolTip={
                  "Confirma ou não o comparecimento do paciente na consulta"
                }
                click={(_) => handleDisplayStatusComparecimento()}
                width={150}
                height={30}
              />
            )}
          <DefaultButton
            fontSize={15}
            icon={<CancelIcon />}
            buttonLabel={"Fechar"}
            messageToolTip={"Fechar esta janela e cancelar operação"}
            click={esconder}
            width={150}
            height={30}
          />
        </div>
      </Dialog>
      <DialogCancelarEvento
        evento={evento}
        cancelarEvento={cancelarEvento}
        display={displayModalCancelarEvento}
        esconder={fecharModalCancelarEvento}
      />
      <DialogStatusComparecimento
        evento={evento}
        alterarStatusComparecimento={alterarStatusComparecimento}
        display={displayModalStatusComparecimento}
        esconder={fecharModalStatusComparecimento}
        tiposAusencia={tiposAusencia}
      />
    </div>
  );
}
