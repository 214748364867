export function handleModalNovoUsuario(
  setIsEdicao,
  setDisplayModal,
  setUsuarioSelecionado,
  setIsVisualizacao
) {
  setIsEdicao(false);
  setDisplayModal(true);
  setUsuarioSelecionado({});
  setIsVisualizacao(false);
}

export function handleModalVisualizarUsuario(
  rowData,
  setDisplayModal,
  setUsuarioSelecionado,
  setIsVisualizacao
) {
  setDisplayModal(true);
  setUsuarioSelecionado(rowData);
  setIsVisualizacao(true);
}

export function handleModalEditarUsuario(
  rowData,
  setIsEdicao,
  setDisplayModal,
  setUsuarioSelecionado,
  setIsVisualizacao
) {
  setIsEdicao(true);
  setDisplayModal(true);
  setUsuarioSelecionado(rowData);
  setIsVisualizacao(false);
}

export function fecharModalUsuario(
  setIsEdicao,
  setDisplayModal,
  setUsuarioSelecionado,
  setIsVisualizacao
) {
  setIsEdicao(false);
  setDisplayModal(false);
  setUsuarioSelecionado({});
  setIsVisualizacao(false);
}
