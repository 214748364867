import homeDashboardDataService from "../data_services/dashboard/HomeDashboardDataService";
import { HomeDashboardDTO } from "../model/dto/HomeDashboardDTO";

class HomeDashboardService {
  static async getPsicologoDashboard(
    psicologoId: number
  ): Promise<HomeDashboardDTO> {
    const response = await homeDashboardDataService.getPsicologoDashboard(
      psicologoId
    );
    const dashboard: HomeDashboardDTO = response.data;
    return dashboard;
  }
}
export default HomeDashboardService;
