import axios from "axios";
import { environment } from "../../environments/environment";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { PerfilAcessoRequestDTO } from "../../model/dto/request/PerfilAcessoRequestDTO";

class PerfilAcessoDataService {
  env = environment.getEnvironment();
  PERFIL_ACESSO_ENDPOINTS = `${this.env.BACKEND_API_URL}/perfil-acesso`;
  token = storageTokenConfig.getToken();

  getAll() {
    return axios.get(`${this.PERFIL_ACESSO_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  criarPerfilAcesso(perfilAcesso: PerfilAcessoRequestDTO) {
    return axios.post(`${this.PERFIL_ACESSO_ENDPOINTS}`, perfilAcesso, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  atualizarPerfil(perfilId: number, perfilAcesso: PerfilAcessoRequestDTO) {
    return axios.put(
      `${this.PERFIL_ACESSO_ENDPOINTS}/${perfilId}`,
      perfilAcesso,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  deletarPerfil(perfilId: number) {
    return axios.delete(`${this.PERFIL_ACESSO_ENDPOINTS}/${perfilId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const perfilAcessoDataService = new PerfilAcessoDataService();
export default perfilAcessoDataService;
