import { MultiSelect } from "primereact/multiselect";
import "./style/ProntuarioIntesQueixaInicial.css";
import ProntuarioLabel from "./ProntuarioLabel";

export default function ProntuarioItemsQueixaInicial({
  prontuario,
  itemsQueixaInicial,
  setProntuario,
  isVisualizacao,
}) {
  return (
    <div style={{ textAlign: "left" }}>
      <ProntuarioLabel titulo={"Queixa: "} />
      <div className="card">
        <MultiSelect
          className="CustomMultiSelect"
          itemClassName="CustomMultiSelect"
          disabled={isVisualizacao}
          value={
            prontuario !== undefined &&
            prontuario.itemsQueixaInicial !== undefined
              ? prontuario.itemsQueixaInicial
              : []
          }
          options={itemsQueixaInicial}
          optionLabel="nome"
          placeholder="Selecione os itens"
          filter
          display="chip"
          onChange={(e) => {
            setProntuario({
              ...prontuario,
              itemsQueixaInicial: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
}
