import { PsicologoDTO } from "./PsicologoDTO";

export class PacientePsicologoDTO {
  id?: number;
  nrPacientePsicologo?: number;
  psicologo?: PsicologoDTO;
  isPsicologoAtual?: boolean;
  dataInicioVinculo?: Date;
  dataFimVinculo?: Date;

  constructor(options: Partial<PacientePsicologoDTO> = {}) {
    this.id = options.id;
    this.nrPacientePsicologo = options.nrPacientePsicologo;
    this.psicologo = options.psicologo;
    this.isPsicologoAtual = options.isPsicologoAtual;
    this.dataInicioVinculo = options.dataInicioVinculo;
    this.dataFimVinculo = options.dataFimVinculo;
  }
}
