import tipoEventoAgendaDataService from "../data_services/tipo-evento/TipoEventoAgendaDataService";
import { TipoEventoAgendaDTO } from "../model/dto/TipoEventoAgendaDTO";

class TipoEventoAgendaService {
  static async getAllTiposEventoFilteredByUsuarioPsicologo(): Promise<
    TipoEventoAgendaDTO[]
  > {
    const response =
      await tipoEventoAgendaDataService.getAllTiposEventoFilteredByUsuarioPsicologo();
    const tiposEvento: TipoEventoAgendaDTO[] = response.data;
    return tiposEvento;
  }

  static async getAllTiposEvento(): Promise<TipoEventoAgendaDTO[]> {
    const response = await tipoEventoAgendaDataService.getAllTiposEvento();
    const tiposEvento: TipoEventoAgendaDTO[] = response.data;
    return tiposEvento;
  }
}

export default TipoEventoAgendaService;
