import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";
import { TemplatePadraoProntuarioRequestDTO } from "../../model/dto/request/TemplatePadraoProntuarioRequestDTO";

class TemplatePadraoProntuarioUsuarioDataService {
  env = environment.getEnvironment();
  TEMPLATE_PADRAO_PRONTUARIO_USUARIO_ENDPOINTS = `${this.env.BACKEND_API_URL}/prontuario/template/usuario`;
  token = storageTokenConfig.getToken();

  getAllTemplatesByUsuarioLogado() {
    return axios.get(`${this.TEMPLATE_PADRAO_PRONTUARIO_USUARIO_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  getTemplatePadraoByUsuarioIdAndTipoProntuarioId(tipoProntuarioId: number) {
    return axios.get(
      `${this.TEMPLATE_PADRAO_PRONTUARIO_USUARIO_ENDPOINTS}/by-tipo-prontuario/${tipoProntuarioId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  criarTemplatePadraoProntuarioUsuario(
    templatePadraoProntuarioRequestDTO: TemplatePadraoProntuarioRequestDTO
  ) {
    return axios.post(
      `${this.TEMPLATE_PADRAO_PRONTUARIO_USUARIO_ENDPOINTS}`,
      templatePadraoProntuarioRequestDTO,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  atualizarTemplatePadraoProntuarioUsuario(
    templatePadraoProntuarioUsuarioId: number,
    templatePadraoProntuarioRequestDTO: TemplatePadraoProntuarioRequestDTO
  ) {
    return axios.put(
      `${this.TEMPLATE_PADRAO_PRONTUARIO_USUARIO_ENDPOINTS}/${templatePadraoProntuarioUsuarioId}`,
      templatePadraoProntuarioRequestDTO,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }
}

const templatePadraoProntuarioUsuarioDataService =
  new TemplatePadraoProntuarioUsuarioDataService();
export default templatePadraoProntuarioUsuarioDataService;
