import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";
import {
  CancelIcon,
  PacienteIcon,
  SaveIcon,
} from "../../../components/icons/Icons";

export default function DialogAlteraDataVinculo({
  display,
  esconder,
  nomePaciente,
  pacientePsicologo,
  setPacientePsicologo,
  salvar,
  isVisualizacao,
}) {
  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<PacienteIcon />} {"Alterar data de vínculo"}
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados de vínculo">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteId">
                  <b>Paciente: </b> {nomePaciente}
                </label>
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteId">
                  <b>Psicólogo: </b> {pacientePsicologo.psicologo?.nome}
                </label>
              </Col>
            </Row>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteId">
                  <b>Data início vínculo: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    pacientePsicologo !== undefined &&
                    pacientePsicologo.dataInicioVinculo
                      ? pacientePsicologo.dataInicioVinculo
                      : null
                  }
                  onChange={(e) => {
                    setPacientePsicologo({
                      ...pacientePsicologo,
                      dataInicioVinculo: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteId">
                  <b>Data fim vínculo: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    pacientePsicologo !== undefined &&
                    pacientePsicologo.dataFimVinculo
                      ? pacientePsicologo.dataFimVinculo
                      : null
                  }
                  onChange={(e) => {
                    setPacientePsicologo({
                      ...pacientePsicologo,
                      dataFimVinculo: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Panel>

        <div className="Footer">
          <DefaultButton
            fontSize={15}
            icon={<SaveIcon />}
            buttonLabel={"Salvar"}
            messageToolTip={"Salvar operação"}
            click={(_) => salvar(pacientePsicologo)}
            width={150}
            height={30}
          />
          <DefaultButton
            fontSize={15}
            icon={<CancelIcon />}
            buttonLabel={"Cancelar"}
            messageToolTip={"Cancelar operação"}
            click={esconder}
            width={150}
            height={30}
          />
        </div>
      </Dialog>
    </div>
  );
}
