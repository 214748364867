import axios from "axios";
import { environment } from "../../environments/environment";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { MedicoRequestDTO } from "../../model/dto/request/MedicoRequestDTO";
import { AtivarInativarMedicoRequestDTO } from "../../model/dto/request/AtivarInativarMedicoRequestDTO";

class MedicoDataService {
  env = environment.getEnvironment();
  MEDICO_ENDPOINTS = `${this.env.BACKEND_API_URL}/medico`;
  token = storageTokenConfig.getToken();

  criarMedico(medico: MedicoRequestDTO) {
    return axios.post(`${this.MEDICO_ENDPOINTS}`, medico, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  atualizarMedico(id: number, medico: MedicoRequestDTO) {
    return axios.put(`${this.MEDICO_ENDPOINTS}/${id}`, medico, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  ativarInativarMedico(
    ativarInativarMedicoRequest: AtivarInativarMedicoRequestDTO
  ) {
    return axios.patch(
      `${this.MEDICO_ENDPOINTS}/`,
      ativarInativarMedicoRequest,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  getAll() {
    return axios.get(`${this.MEDICO_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const medicoDataService = new MedicoDataService();
export default medicoDataService;
