import { useReactToPrint } from "react-to-print";
import DefaultButton from "../buttons/DefaultButton";
import { ImprimirIcon } from "../icons/Icons";

export default function PrintButton({
  contentRef,
  messageToolTip,
  titulo,
  callbackFunction,
  rowData,
  label,
  width,
  height,
}) {
  const imprimir = useReactToPrint({
    content: () => contentRef.current,
    documentTitle: titulo,
  });

  const handleClickImprimir = () => {
    callbackFunction ? imprimirCallback() : imprimir();
  };

  const imprimirCallback = async () => {
    try {
      const callbackResult = await callbackFunction(rowData);
      if (callbackResult === true) {
        imprimir();
      }
    } catch (error) {
      return;
    }
  };

  return (
    <DefaultButton
      fontSize={15}
      icon={<ImprimirIcon />}
      messageToolTip={messageToolTip}
      click={handleClickImprimir}
      width={width !== undefined ? width : 40}
      height={height !== undefined ? height : 30}
      buttonLabel={label}
    />
  );
}
