import "./Login.css";

import Messages from "../components/Messages";
import AuthDataService from "../data_services/auth/AuthDataService";
import CenterDivider from "../views/app/components/CenterDivider";
import FooterInformations from "../views/app/components/FooterInformations";
import LeftInformations from "../views/app/components/LeftInformations";
import RightInformations from "../views/app/components/RightInformations";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { storageTokenConfig } from "../config/token/StorageTokenConfig";

export default function Login() {
  const history = useHistory();
  const [exibeCamposLogin, setExibeCamposLogin] = useState(false);

  const navigateToHome = () => history.push("/home");

  function doLogin(login, password) {
    if (login === "" && password === "") {
      Messages.errorMessage("Opss..", "Necessário informar login e senha");
      return;
    }
    if (login === "") {
      Messages.errorMessage("Opss..", "Informe o login.");
      return;
    }
    if (password === "") {
      Messages.errorMessage("Opss..", "Informe a senha.");
      return;
    }
    const user = {
      login: login,
      senha: password,
    };
    AuthDataService.doLogin(user)
      .then((response) => {
        storageTokenConfig.setToken(response.data.token);
        delete response.data.token;
        secureLocalStorage.setItem(
          "userSession",
          JSON.stringify(response.data.usuarioDTO)
        );
        savePermissionsInLocalStorage(
          response.data.usuarioDTO.perfilAcessoList
        );
        navigateToHome();
      })
      .catch((error) => {
        Messages.errorMessage("Falha no login", error.response.data.message);
      });
  }

  function savePermissionsInLocalStorage(perfilAcessoList) {
    const permissoes = [];
    for (let perfil of perfilAcessoList) {
      for (let funcionalidade of perfil.funcionalidadeList) {
        if (!permissoes.includes(funcionalidade.codigo)) {
          permissoes.push(funcionalidade.codigo);
        }
      }
    }
    secureLocalStorage.setItem("permissoes", JSON.stringify(permissoes));
  }

  const handleAnimationEnd = () => {
    if (!exibeCamposLogin) {
      setExibeCamposLogin(true);
    }
  };

  return (
    <>
      <>
        <div
          className={`login-fields-container ${exibeCamposLogin ? "show" : ""}`}
          onAnimationEnd={handleAnimationEnd}
        >
          <div id="login">
            <div style={{ marginTop: "10%" }}>
              <div className="grid">
                <LeftInformations />
                <CenterDivider />
                <RightInformations doLogin={doLogin} />
              </div>
            </div>
          </div>
          <div>
            <FooterInformations />
          </div>
        </div>
      </>
      <FooterInformations/>
    </>
  );
}
