import "./Especialidade.css";
import {
  DeleteIcon,
  EditIcon,
  EspecialidadeIcon,
  NovoIcon,
  ViewIcon,
} from "../../components/icons/Icons";
import { useCallback, useEffect, useState } from "react";
import EspecialidadeDataService from "../../data_services/especialidade/EspecialidadeDataService";
import DefaultButton from "../../components/buttons/DefaultButton";
import CustomDataTable from "../../components/table/CustomDataTable";
import DialogCRUDEspecialidade from "./dialog/DialogCRUDEspecialidade";
import { EspecialidadeDTO } from "../../model/dto/EspecialidadeDTO";
import { validate } from "../../validator/Validator";
import Messages from "../../components/Messages";
import AuthDataService from "../../data_services/auth/AuthDataService";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";

export default function Especialidade() {
  const [blockUi, setBlockUi] = useState(false);
  const [especialidades, setEspecialidades] = useState([]);
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState(
    new EspecialidadeDTO()
  );
  const [totalEspecialidades, setTotalEspecialidades] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [isVisualizacao, setIsVisualizacao] = useState(false);

  const setEspecialidadeSelecionadaCallback = useCallback(
    function (e) {
      setEspecialidadeSelecionada(e);
    },
    [setEspecialidadeSelecionada]
  );

  const cabecalho = [
    { field: "id", header: "ID" },
    { field: "nome", header: "Especialidade" },
    { field: "cbo", header: "CBO" },
  ];

  useEffect(() => {
    carregarTodosAsEspecialidades();
  }, []);

  function carregarTodosAsEspecialidades() {
    setBlockUi(true);
    EspecialidadeDataService.getAll().then((response) => {
      setEspecialidades(response.data);
      setTotalEspecialidades(response.data.length);
      setBlockUi(false);
    });
  }

  function handleModalNovaEspecialidade() {
    setDisplayModal(true);
    setEspecialidadeSelecionada({});
    setIsVisualizacao(false);
  }

  function handleModalVisualizarEspecialidade(rowData) {
    setDisplayModal(true);
    setEspecialidadeSelecionada(rowData);
    setIsVisualizacao(true);
  }

  function handleModalEditarEspecialidade(rowData) {
    setDisplayModal(true);
    setEspecialidadeSelecionada(rowData);
    setIsVisualizacao(false);
  }

  function fecharModal() {
    setDisplayModal(false);
    setEspecialidadeSelecionada({});
    setIsVisualizacao(false);
  }

  function salvar(especialidade) {
    if (!validarEspecialidade(especialidade)) {
      fecharModal();
      return;
    }
    if (especialidade.id) {
      setBlockUi(true);
      EspecialidadeDataService.atualizarEspecialidade(
        especialidade.id,
        especialidade
      )
        .then((_) => {
          setBlockUi(false);
          fecharModal();
          Messages.successMessage(
            "Sucesso",
            "Especialidade atualizada com sucesso!"
          );
          carregarTodosAsEspecialidades();
        })
        .catch((error) => {
          setBlockUi(false);
          fecharModal();
          Messages.errorMessage("Erro", error.response.data.message);
        });
    } else {
      setBlockUi(true);
      EspecialidadeDataService.salvarEspecialidade(especialidade)
        .then((_) => {
          setBlockUi(false);
          fecharModal();
          Messages.successMessage(
            "Sucesso",
            "Especialidade criada com sucesso!"
          );
          carregarTodosAsEspecialidades();
        })
        .catch((error) => {
          setBlockUi(false);
          fecharModal();
          Messages.errorMessage("Erro", error.response.data.message);
        });
    }
  }

  function removerEspecialidade(rowData) {
    Messages.questionMessage(
      "Confirme a sua opção",
      `A especialidade ${rowData.nome} será removido permanentemente do sistema`,
      "Sim. Remover",
      "Não."
    ).then((result) => {
      if (result.isConfirmed) {
        setBlockUi(true);
        EspecialidadeDataService.removerEspecialidade(rowData)
          .then((_) => {
            setBlockUi(false);
            fecharModal();
            Messages.successMessage(
              "Sucesso",
              "Especialidade removida com sucesso"
            );
            carregarTodosAsEspecialidades();
          })
          .catch((error) => {
            setBlockUi(false);
            fecharModal();
            Messages.errorMessage("Erro", error.response.data.message);
          });
      }
    });
  }

  function validarEspecialidade(especialidade) {
    const camposValidar = [
      { name: "Especialidade", value: especialidade.nome },
    ];
    return validate(camposValidar);
  }

  function actionTemplate(rowData) {
    return (
      <div className="ActionItens">
        {AuthDataService.hasPermission(
          FuncionalidadeEnum.VISUALIZACAO_ESPECIALIDADE
        ) && (
          <div>
            <DefaultButton
              fontSize={15}
              icon={<ViewIcon />}
              messageToolTip={"Visualizar especialidade"}
              click={(_) => handleModalVisualizarEspecialidade(rowData)}
              width={40}
              height={30}
            />
          </div>
        )}
        {AuthDataService.hasPermission(
          FuncionalidadeEnum.ATUALIZACAO_ESPECIALIDADE
        ) && (
          <div>
            <DefaultButton
              fontSize={15}
              icon={<EditIcon />}
              messageToolTip={"Editar dados da especialidade"}
              click={(_) => handleModalEditarEspecialidade(rowData)}
              width={40}
              height={30}
            />
          </div>
        )}
        {AuthDataService.hasPermission(
          FuncionalidadeEnum.REMOCAO_ESPECIALIDADE
        ) && (
          <div>
            <DefaultButton
              fontSize={15}
              icon={<DeleteIcon />}
              messageToolTip={"Remove a especialidade Selecionada"}
              click={(_) => removerEspecialidade(rowData)}
              width={40}
              height={30}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div className="Especialidade">
        <div className="Title">
          <span>
            <EspecialidadeIcon /> Especialidades
          </span>
        </div>
        <div></div>
      </div>
      <div className="Body">
        {AuthDataService.hasPermission(
          FuncionalidadeEnum.CADASTRO_ESPECIALIDADE
        ) && (
          <div className="NovaEspecialidade">
            <DefaultButton
              fontSize={10}
              icon={<NovoIcon />}
              buttonLabel={"Adicionar"}
              messageToolTip={"Cadastrar nova especialidade no sistema"}
              click={(_) => handleModalNovaEspecialidade()}
              width={150}
              height={30}
            />{" "}
          </div>
        )}
        <CustomDataTable
          values={especialidades}
          totalRecords={totalEspecialidades}
          dataKey="id"
          headerList={cabecalho}
          actionTemplate={actionTemplate}
          paginator={true}
          exibeActionTemplate={true}
        />
      </div>
      <DialogCRUDEspecialidade
        display={displayModal}
        esconder={fecharModal}
        especialidade={especialidadeSelecionada}
        setEspecialidade={setEspecialidadeSelecionadaCallback}
        isVisualizacao={isVisualizacao}
        salvar={salvar}
      />
    </BlockUI>
  );
}
