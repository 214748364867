import EspecialidadeDataService from "../data_services/especialidade/EspecialidadeDataService";
import { EspecialidadeDTO } from "../model/dto/EspecialidadeDTO";

class EspecialidadeService {
    static async getAll(): Promise<EspecialidadeDTO[]> {
        const response = await EspecialidadeDataService.getAll();
        const especialidades: EspecialidadeDTO[] = response.data;
        return especialidades;
      }
}

export default EspecialidadeService;