export class AtualizarProntuarioRequestDTO {
  registroAtendimento?: string;
  observacoes?: string;
  evolucao?: string;
  finalizado?: boolean;
  itemsQueixaInicialId?: number[];
  dataConsulta?: Date;

  constructor(options: Partial<AtualizarProntuarioRequestDTO> = {}) {
    this.registroAtendimento = options.registroAtendimento;
    this.observacoes = options.observacoes;
    this.evolucao = options.evolucao;
    this.finalizado = options.finalizado;
    this.itemsQueixaInicialId = options.itemsQueixaInicialId;
    this.dataConsulta = options.dataConsulta;
  }
}
