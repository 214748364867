import PropTypes from "prop-types";

import {
  AdicionarIcon,
  CancelIcon,
  DeleteIcon,
  PacienteIcon,
  SaveIcon,
} from "../../../components/icons/Icons";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import DefaultButton from "../../../components/buttons/DefaultButton";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";

export default function DialogAdicionarSessaoPaciente(props) {
  const { inputList, setInputList, paciente, display, esconder, salvar } =
    props;

  const handleInputChange = (e, index) => {
    const { name, value, checked, type } = e.target;
    const list = [...inputList];

    if (type === "checkbox") {
      list[index][name] = checked;
    } else {
      list[index][name] = value;
    }
    setInputList(list);
  };

  const handleRemoveClick = () => {
    const list = [...inputList];
    list.splice(inputList.length - 1, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        id: null,
        dataSessao: inputList[inputList.length - 1].dataSessao,
        baixada: false,
        observacao: "",
      },
    ]);
  };

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<PacienteIcon />} {"Sessões do paciente"}
      </div>
    );
  };

  function isSessaoNaoBaixada(sessao) {
    const hoje = new Date();
    const dataSessao = new Date(sessao.dataSessao);
    hoje.setUTCHours(0, 0, 0, 0);
    dataSessao.setUTCHours(0, 0, 0, 0);
    return !!(dataSessao < hoje && sessao.baixada === false);
  }

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header={`Detalhes das sessoes do paciente ${paciente.paciente}`}>
          {inputList.map((x, i) => {
            return (
              <div key={`divPacienteSessao${x + i}`}>
                <Container>
                  <Row>
                    <Col>
                      <label
                        style={{ color: "#24345a" }}
                        htmlFor={`pacienteDataSessao${i}`}
                      >
                        <b>Data sessão: </b>
                      </label>
                      <br></br>
                      <InputText
                        name="dataSessao"
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                        type="date"
                        value={x.dataSessao}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      {isSessaoNaoBaixada(x) && (
                        <label
                          style={{ color: "red"}}
                          htmlFor={`pacienteDataSessaoNaoBaixada${i}`}
                        >
                          <b>Esta sessão não foi baixada</b>
                        </label>
                      )}
                    </Col>
                    <Col>
                      <label
                        style={{ color: "#24345a"}}
                        htmlFor={`pacienteSessaoBaixada${i}`}
                      >
                        <b>Observação: </b>
                      </label>
                      <br></br>
                      <InputText
                        name="observacao"
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                        type="text"
                        value={x.observacao}
                        onChange={(e) => {
                          handleInputChange(e, i);
                        }}
                      />
                    </Col>
                    <Col>
                      <label
                        style={{ color: "#24345a"}}
                        htmlFor={`pacienteObservacao${i}`}
                      >
                        <b>Baixada: </b>
                      </label>
                      <br></br>
                      <Checkbox
                        name="baixada"
                        inputId="bloqued"
                        value={x.baixada}
                        checked={x.baixada}
                        onChange={(e) => {
                          handleInputChange(e, i);
                        }}
                      ></Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {inputList.length - 1 === i && (
                        <div style={{ marginTop: "15%" }}>
                          <DefaultButton
                            fontSize={15}
                            icon={<AdicionarIcon />}
                            buttonLabel={"Adicionar"}
                            messageToolTip={"Adicionar sessão"}
                            click={handleAddClick}
                            width={150}
                            height={30}
                          />
                        </div>
                      )}
                    </Col>
                    <Col>
                      {inputList.length !== 1 && inputList.length - 1 === i && (
                        <div style={{ marginTop: "15%" }}>
                          <DefaultButton
                            fontSize={15}
                            icon={<DeleteIcon />}
                            buttonLabel={"Remover"}
                            messageToolTip={"Remover a ultima sessão da lista"}
                            width={150}
                            height={30}
                            click={handleRemoveClick}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          })}
        </Panel>
        <br></br>
        <div className="Footer">
          <DefaultButton
            fontSize={15}
            icon={<SaveIcon />}
            buttonLabel={"Salvar"}
            messageToolTip={"Salvar operação"}
            click={(_) => salvar(inputList)}
            width={150}
            height={30}
          />
          <DefaultButton
            fontSize={15}
            icon={<CancelIcon />}
            buttonLabel={"Cancelar"}
            messageToolTip={"Cancelar operação"}
            click={esconder}
            width={150}
            height={30}
          />
        </div>
      </Dialog>
    </div>
  );
}

DialogAdicionarSessaoPaciente.propTypes = {
  inputList: PropTypes.any,
  setInputList: PropTypes.any,
  display: PropTypes.any,
  esconder: PropTypes.any,
  paciente: PropTypes.any,
  salvar: PropTypes.any,
};
