import "./style/ProntuarioInformacoesPaciente.css";
import { Container, Col, Row } from "react-bootstrap";
import ProntuarioLabel from "./ProntuarioLabel";
import { calcularIdade, formatData } from "../../../utils/FormatDataUtils";
export default function ProntuarioInformacoesPaciente({ prontuario }) {
  return (
    <div>
      <ProntuarioLabel titulo={"Dados do paciente:"} />
      <div className="ProntuarioInformacoesPaciente">
        <Container>
          <Row>
            <Col>
              <ProntuarioLabel
                titulo={"Nome: "}
                valor={
                  prontuario.paciente !== undefined
                    ? prontuario.paciente.paciente
                    : ""
                }
              />
            </Col>
            <Col>
              <ProntuarioLabel titulo={"Convênio: "} valor={"Sulamerica"} />
            </Col>
            <Col>
              <ProntuarioLabel
                titulo={"Carteirinha: "}
                valor={
                  prontuario.paciente !== undefined
                    ? prontuario.paciente.nrCarteirinha
                    : ""
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProntuarioLabel
                titulo={"Data de nascimento: "}
                valor={
                  prontuario.paciente !== undefined
                    ? formatData(prontuario.paciente.dataNascimento)
                    : ""
                }
              />
            </Col>
            <Col>
              <ProntuarioLabel
                titulo={"Idade: "}
                valor={
                  prontuario.paciente !== undefined
                    ? calcularIdade(prontuario.paciente.dataNascimento) +
                      " anos"
                    : ""
                }
              />
            </Col>
            <Col>
              <ProntuarioLabel
                titulo={"Médico: "}
                valor={
                  prontuario.paciente !== undefined
                    ? prontuario.paciente.medico.nome
                    : ""
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProntuarioLabel
                titulo={"Endereço: "}
                valor={
                  prontuario.paciente !== undefined
                    ? prontuario.paciente.logradouro
                    : ""
                }
              />
            </Col>
            <Col>
              <ProntuarioLabel
                titulo={"Bairro: "}
                valor={
                  prontuario.paciente !== undefined
                    ? prontuario.paciente.bairro
                    : ""
                }
              />
            </Col>
            <Col>
              <ProntuarioLabel
                titulo={"Cidade: "}
                valor={
                  prontuario.paciente !== undefined &&
                  prontuario.paciente.cidade !== null &&
                  prontuario.paciente.estado !== null
                    ? prontuario.paciente.cidade +
                      "/" +
                      prontuario.paciente.estado
                    : ""
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProntuarioLabel
                titulo={"Número: "}
                valor={prontuario.paciente ? prontuario.paciente.numero : ""}
              />
            </Col>
            <Col>
              <ProntuarioLabel
                titulo={"CEP: "}
                valor={prontuario.paciente ? prontuario.paciente.cep : ""}
              />
            </Col>
            <Col>
              <ProntuarioLabel
                titulo={"Telefone: "}
                valor={prontuario.paciente ? prontuario.paciente.telefone : ""}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
