import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";

class TipoEventoAgendaDataService {
  env = environment.getEnvironment();
  TIPO_EVENTO_AGENDA_ENDPOINTS = `${this.env.BACKEND_API_URL}/tipo-evento`;
  token = storageTokenConfig.getToken();

  getAllTiposEventoFilteredByUsuarioPsicologo() {
    return axios.get(`${this.TIPO_EVENTO_AGENDA_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  getAllTiposEvento() {
    return axios.get(`${this.TIPO_EVENTO_AGENDA_ENDPOINTS}/todos`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const tipoEventoAgendaDataService = new TipoEventoAgendaDataService();
export default tipoEventoAgendaDataService;
