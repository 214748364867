export class RelatorioHistoricoAtendimentosExportDTO {
  "Nº prontuário"?: string;
  "Tipo atendimento"?: string;
  "Nome paciente"?: string;
  "Nº carteirinha"?: string;
  "Nome psicólogo"?: string;
  "CRP"?: string;
  "Primeira sessão?"?: string;
  "Atendimento finalizado?"?: string;
  "Data criação prontuário"?: string;
  "Data hora ult. atualização"?: string;

  constructor(options: Partial<RelatorioHistoricoAtendimentosExportDTO> = {}) {
    this["Nº prontuário"] = options["Nº prontuário"];
    this["Tipo atendimento"] = options["Tipo atendimento"];
    this["Nome paciente"] = options["Nome paciente"];
    this["Nº carteirinha"] = options["Nº carteirinha"];
    this["Nome psicólogo"] = options["Nome psicólogo"];
    this["CRP"] = options["CRP"];
    this["Primeira sessão?"] = options["Primeira sessão?"];
    this["Atendimento finalizado?"] = options["Atendimento finalizado?"];
    this["Data criação prontuário"] = options["Data criação prontuário"];
    this["Data hora ult. atualização"] = options["Data hora ult. atualização"];
  }
}
