import { useState } from "react";
import "./DefaultButton.css";

import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
export default function DefaultButtonWithLink(props) {
  const [backgroundColor, setBackgroundColor] = useState(
    props.backgroundColor || "#fff"
  );
  const [borderColor, setBorderColor] = useState(
    props.borderColor || "#24345a"
  );

  const styleButton = {
    fontSize: props.fontSize || 16,
    color: props.labelColor || "#5685a5",
    backgroundColor: `${backgroundColor}`,
    borderColor: `${borderColor}`,
    width: parseInt(props.width) || 150,
    height: parseInt(props.height) || 50,
  };

  function onMouseUpButton() {
    return () => {
      setBackgroundColor(props.hoverColor || "#24345a");
      setBorderColor(props.onHoverBorderColor || "#fff");
    };
  }

  function onMouseOutButton() {
    return () => {
      setBackgroundColor(props.backgroundColor || "#fff");
      setBorderColor(props.borderColor || "#24345a");
    };
  }

  function onMouseClick() {
    return () => {
      setBackgroundColor(props.onClickColor || "#1e1f26");
      setBorderColor(props.onClickBorderColor || "#6077db");
    };
  }

  return (
    <div className="DefaultButton">
      <Tooltip
        disableFocusListener={!props.messageToolTip}
        disableHoverListener={!props.messageToolTip}
        disableTouchListener={!props.messageToolTip}
        title={props.messageToolTip ? props.messageToolTip : ""}
      >
        <Link to={{ pathname: props.href }} style={{ textDecoration: "none" }}>
          <button
            style={styleButton}
            onMouseEnter={onMouseUpButton()}
            onMouseLeave={onMouseOutButton()}
            onMouseDown={onMouseClick()}
            onMouseUp={onMouseOutButton()}
            onClick={props.click}
            onBlur={onMouseOutButton}
          >
            {props.icon ? props.icon : ""} {props.buttonLabel}
          </button>
        </Link>
      </Tooltip>
    </div>
  );
}
