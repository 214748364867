import DefaultButton from "../../../../components/buttons/DefaultButton";
import PrintButton from "../../../../components/print/PrintButton";
import "../../ModeloProntuario.css";

export default function ModeloPadraoProntuarioUsuarioActionTemplate({
  visualizarIcon,
  visualizarMessageTooltip,
  visualizarClickAction,
  editarIcon,
  editarMessageTooltip,
  editarClickAction,
  imprimirRef,
  imprimirMessageTooltip,
  imprimirTituloArquivo,
  imprimirCallbackFunction,
  imprimirRowData,
}) {
  return (
    <div className="ActionItens">
      <div>
        <ModeloUsuarioProntuarioActionButton
          icon={visualizarIcon}
          messageTooltip={visualizarMessageTooltip}
          clickAction={visualizarClickAction}
        />
      </div>
      <div>
        <ModeloUsuarioProntuarioActionButton
          icon={editarIcon}
          messageTooltip={editarMessageTooltip}
          clickAction={editarClickAction}
        />
      </div>
      <div>
        <PrintButton
          contentRef={imprimirRef}
          messageToolTip={imprimirMessageTooltip}
          titulo={imprimirTituloArquivo}
          callbackFunction={imprimirCallbackFunction}
          rowData={imprimirRowData}
        />
      </div>
    </div>
  );
}

export function ModeloUsuarioProntuarioActionButton({
  icon,
  messageTooltip,
  clickAction,
}) {
  return (
    <DefaultButton
      fontSize={15}
      icon={icon}
      messageToolTip={messageTooltip}
      click={clickAction}
      width={40}
      height={30}
    />
  );
}
