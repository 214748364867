import PropTypes from "prop-types";
import AuthDataService from "../../data_services/auth/AuthDataService";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import "./Usuario.css";

export default function UsuarioActionTemplate(props) {
  const {
    botaoVisualizarUsuario,
    botaoEditarUsuario,
    botaoTrocarStatusUsuario,
  } = props;

  return (
    <div className="ActionItens">
      {AuthDataService.hasPermission(
        FuncionalidadeEnum.VISUALIZACAO_USUARIO
      ) && <div>{botaoVisualizarUsuario()}</div>}
      {AuthDataService.hasPermission(
        FuncionalidadeEnum.ATUALIZACAO_USUARIO
      ) && <div>{botaoEditarUsuario()}</div>}
      {AuthDataService.hasPermission(FuncionalidadeEnum.INATIVACAO_USUARIO) && (
        <div>{botaoTrocarStatusUsuario()}</div>
      )}
    </div>
  );
}

UsuarioActionTemplate.propTypes = {
  botaoVisualizarUsuario: PropTypes.any,
  botaoEditarUsuario: PropTypes.any,
  botaoTrocarStatusUsuario: PropTypes.any,
};
