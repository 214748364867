
export const exportPsicologosExcel = (exportData) => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(exportData);
      const workbook = { Sheets: { "Psicólogos": worksheet }, SheetNames: ["Psicólogos"] };
      
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
  
      saveAsExcelFile(excelBuffer, "Psicólogos");
    });
    
  };
  
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module?.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });
  
        module.default.saveAs(
          data,
          fileName + "_exportados_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };
  