import eventoAgendaDataService from "../data_services/agenda/evento/EventoAgendaDataService";
import { EventoAgendaDTO } from "../model/dto/EventoAgendaDTO";
import { AgendaExportDTO } from "../model/dto/export/AgendaExportDTO";
import { AlterarStatusComparecimentoRequestDTO } from "../model/dto/request/AlterarStatusComparecimentoRequestDTO";
import { CancelarEventoRequestDTO } from "../model/dto/request/CancelarEventoRequestDTO";
import { CriarEventoRequestDTO } from "../model/dto/request/CriarEventoRequestDTO";
import { formatDataHora } from "../utils/FormatDataUtils";

class EventoAgendaService {
  static async getAllEventosByUsuarioLogado(): Promise<EventoAgendaDTO[]> {
    const response =
      await eventoAgendaDataService.getAllEventosByUsuarioLogado();
    const eventos: EventoAgendaDTO[] = response.data;
    return eventos;
  }

  static async getAllEventosByUsuarioId(
    usuarioId: number
  ): Promise<EventoAgendaDTO[]> {
    const response = await eventoAgendaDataService.getAllEventosByUsuarioId(
      usuarioId
    );
    const eventos: EventoAgendaDTO[] = response.data;
    return eventos;
  }

  static async salvarDadosEvento(
    eventoId: number,
    evento: any,
    replicaEventoNoMes: boolean
  ) {
    const eventoRequest = this.montaRequestCriacaoEvento(
      replicaEventoNoMes,
      evento
    );
    if (eventoId) {
      await this.atualizarEvento(eventoId, eventoRequest);
    } else {
      await this.criarEvento(eventoRequest);
    }
  }

  static async deletarEvento(eventoId: number) {
    await eventoAgendaDataService.deletarEvento(eventoId);
  }

  static async cancelarEvento(
    eventoId: number,
    motivoCancelamento: string,
    isCancelar: boolean
  ) {
    await eventoAgendaDataService.cancelarEvento(
      eventoId,
      this.montaRequestCancelamentoEvento(motivoCancelamento, isCancelar)
    );
  }

  static async alterarStatusComparecimentoPaciente(
    eventoId: number,
    pacienteCompareceu: boolean,
    tipoAusenciaId: number,
    justificativaAusencia: string
  ) {
    await eventoAgendaDataService.alterarStatusComparecimentoPaciente(
      eventoId,
      this.montaRequestAlterarStatusComparecimentoPaciente(
        pacienteCompareceu,
        tipoAusenciaId,
        justificativaAusencia
      )
    );
  }

  static async getByUsuarioIdAndMesAndAno(
    usuarioId: number,
    mes: number,
    ano: number
  ): Promise<EventoAgendaDTO[]> {
    const response = await eventoAgendaDataService.getByUsuarioIdAndMesAndAno(
      usuarioId,
      mes,
      ano
    );
    const eventos: EventoAgendaDTO[] = response.data;
    return eventos;
  }

  static montaObjetoParaExportacao(eventos: EventoAgendaDTO[]) {
    return eventos.map((evento: EventoAgendaDTO) => {
      return new AgendaExportDTO({
        "Tipo evento": evento?.tipoEventoAgenda?.nomeTipoEvento,
        Psicólogo: evento?.usuario?.psicologo?.nome,
        CRP: evento?.usuario?.psicologo?.crp,
        Paciente: evento?.paciente?.paciente,
        "Nr. Carteirinha": evento?.paciente?.nrCarteirinha,
        "Nome do evento": evento?.nomeEvento,
        Observação: evento?.observacao,
        "Data criação": formatDataHora(evento?.dataCriacao),
        Início: formatDataHora(evento?.inicio),
        Fim: formatDataHora(evento?.fim),
        Cancelado: evento?.isCancelado ? "Sim" : "Não",
        "Motivo cancelamento": evento?.motivoCancelamento,
        "Paciente compareceu?": this.getComparecimentoStatus(evento),
        "Tipo da falta":
          evento.tipoAusencia !== null && evento.tipoAusencia !== undefined
            ? evento.tipoAusencia.nome
            : "",
        Justificativa: evento?.justificativaAusencia,
      });
    });
  }

  private static async criarEvento(
    criarEventoRequestDTO: CriarEventoRequestDTO
  ) {
    await eventoAgendaDataService.criarEvento(criarEventoRequestDTO);
  }

  private static async atualizarEvento(
    eventoId: number,
    criarEventoRequestDTO: CriarEventoRequestDTO
  ) {
    await eventoAgendaDataService.atualizarEvento(
      eventoId,
      criarEventoRequestDTO
    );
  }

  private static montaRequestCriacaoEvento(
    replicaEventoNoMes: boolean,
    eventoDTO: EventoAgendaDTO
  ) {
    return new CriarEventoRequestDTO({
      tipoEventoId: eventoDTO.tipoEventoAgenda?.id,
      pacienteId: eventoDTO.paciente?.id,
      nomeEvento: eventoDTO.nomeEvento,
      observacao: eventoDTO.observacao,
      inicio: eventoDTO.inicio,
      fim: eventoDTO.fim,
      replicaEventoNoMes: replicaEventoNoMes,
    });
  }

  private static montaRequestCancelamentoEvento(
    motivoCancelamento: string,
    isCancelar: boolean
  ) {
    return new CancelarEventoRequestDTO({
      motivoCancelamento: motivoCancelamento,
      isCancelar: isCancelar,
    });
  }

  private static montaRequestAlterarStatusComparecimentoPaciente(
    pacienteCompareceu: boolean,
    tipoAusenciaId: number,
    justificativaAusencia: string
  ) {
    return new AlterarStatusComparecimentoRequestDTO({
      pacienteCompareceu: pacienteCompareceu,
      tipoAusenciaId: tipoAusenciaId,
      justificativaAusencia: justificativaAusencia,
    });
  }

  private static getComparecimentoStatus(evento: EventoAgendaDTO) {
    if (evento.pacienteCompareceu === null) {
      return "Não informado";
    } else if (evento.pacienteCompareceu === true) {
      return "Sim";
    } else {
      return "Não";
    }
  }
}

export default EventoAgendaService;
