import axios from "axios";
import { environment } from "../../environments/environment";
import { Buffer } from "buffer";
import secureLocalStorage from "react-secure-storage";

export const USER_SESSION = "userSession";
const TOKEN = "token";

class AuthDataService {
  env = environment.getEnvironment();
  AUTH_ENDPOINTS = `${this.env.BACKEND_API_URL}/login`;

  isUserLoggedIn() {
    const user = secureLocalStorage.getItem(USER_SESSION);
    return user !== null;
  }

  doLogin(user: any) {
    return axios.post(`${this.AUTH_ENDPOINTS}`, user, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  doLogout() {
    localStorage.removeItem(USER_SESSION);
    localStorage.removeItem("remainingTime")
    localStorage.clear();
    window.location.replace("/");
  }

  verifyExpiredToken() {
    const token: any = secureLocalStorage.getItem(TOKEN);
    const payloadBase64 = token.split(".")[1];
    const decodedJson = Buffer.from(payloadBase64, "base64").toString();
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp;
    return Date.now() >= exp * 1000;
  }

  parseJwt(token: string) {
    return token.split("."[1]);
  }

  isAdminUser() {
    const userLogged: any = secureLocalStorage.getItem(USER_SESSION);
    const userLoggedObject = JSON.parse(userLogged);
    if (
      userLoggedObject.user.login.profileLoginList.filter(
        (el: { profileName: string }) => el.profileName === "ADMIN"
      ).length > 0
    ) {
      return true;
    }
    return false;
  }

  hasPermission(funcionalityName: string){
    let isAllowed = false;
    if(this.isUserLoggedIn()){
      const permissions : any = secureLocalStorage.getItem("permissoes")
      if(permissions.includes(funcionalityName)){
        isAllowed = true;
      }
    }
    return isAllowed;
  }
  
}
const authDataService = new AuthDataService();
export default authDataService;
