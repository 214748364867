import { PerfilAcessoDTO } from "./PerfilAcessoDTO";
import { PsicologoDTO } from "./PsicologoDTO";

export class UsuarioDTO {
  id?: number;
  nome?: string;
  email?: string;
  login?: string;
  ativo?: boolean;
  dataCriacao?: Date;
  usuarioCriador?: number;
  dataInativacao?: Date;
  usuarioInativador?: number;
  isPsicologo?: boolean;
  psicologo?: PsicologoDTO;
  perfilAcessoList?: PerfilAcessoDTO[];

  constructor(options: Partial<UsuarioDTO> = {}) {
    this.id = options.id;
    this.nome = options.nome;
    this.email = options.email;
    this.login = options.login;
    this.ativo = options.ativo;
    this.dataCriacao = options.dataCriacao;
    this.usuarioCriador = options.usuarioCriador;
    this.dataInativacao = options.dataInativacao;
    this.usuarioInativador = options.usuarioInativador;
    this.isPsicologo = options.isPsicologo;
    this.psicologo = options.psicologo;
    this.perfilAcessoList = options.perfilAcessoList;
  }
}
