import DefaultButtonWithLink from "../../../components/buttons/DefaultButtonWithLink";
import PrintButton from "../../../components/print/PrintButton";

export default function ProntuariosPacienteActionButtons({
  visualizarProntuarioIcon,
  visualizarProntuarioMessageTooltip,
  visualizarProntuarioClickAction,
  visualizarProntuarioHref,
  editarProntuarioIcon,
  editarProntuarioMessageToolTip,
  editarProntuarioClickAction,
  editarProntuarioHref,
  imprimirRef,
  imprimirTituloArquivo,
  prontuario,
  callbackFunction,
}) {
  return (
    <div className="ActionItens">
      <div>
        <ProntuariosPacienteActionButtonWithLink
          icon={visualizarProntuarioIcon}
          messageTooltip={visualizarProntuarioMessageTooltip}
          clickAction={visualizarProntuarioClickAction}
          url={visualizarProntuarioHref}
        />
      </div>
      <div>
        <ProntuariosPacienteActionButtonWithLink
          icon={editarProntuarioIcon}
          messageTooltip={editarProntuarioMessageToolTip}
          clickAction={editarProntuarioClickAction}
          url={editarProntuarioHref}
        />
      </div>
      <div>
        <PrintButton
          contentRef={imprimirRef}
          messageToolTip={"Imprimir este prontuário"}
          titulo={imprimirTituloArquivo}
          callbackFunction={callbackFunction}
          rowData={prontuario}
        />
      </div>
      <div></div>
    </div>
  );
}

function ProntuariosPacienteActionButtonWithLink({
  icon,
  messageTooltip,
  clickAction,
  url,
}) {
  return (
    <DefaultButtonWithLink
      fontSize={15}
      icon={icon}
      messageToolTip={messageTooltip}
      click={clickAction}
      width={40}
      height={30}
      href={url}
    />
  );
}
