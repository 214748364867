import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";
import { AtivarIcon, CancelIcon } from "../../../components/icons/Icons";

export default function DialogStatusComparecimento({
  evento,
  alterarStatusComparecimento,
  display,
  esconder,
  tiposAusencia,
}) {
  const statusComparecimentoList = [
    { nome: "Sim", value: true },
    { nome: "Não", value: false },
  ];
  const [statusComparecimento, setStatusComparecimento] = useState(null);
  const [tipoAusenciaSelecionada, setTipoAusenciaSelecionada] = useState(null);
  const [justificativaAusencia, setJustificativaAusencia] = useState("");

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<AtivarIcon />} Alterar status de comparecimento
      </div>
    );
  };

  function getBodyByStatusComparecimento() {
    if (statusComparecimento === true) {
      return (
        <Row>
          <label htmlFor="nao" className="ml-2">
            Clique no botão confirmar para salvar o status de comparecimento.
          </label>
        </Row>
      );
    } else if (statusComparecimento === false) {
      return (
        <>
          <Row>
            <Col>
              <label
                style={{ color: "#24345a" }}
                htmlFor="tipoAusenciaSelecionada"
              >
                <b>Informe o tipo da falta: </b>
              </label>
              <br></br>
              <Dropdown
                filter
                style={{ width: "100%", background: "#7988ac" }}
                value={tipoAusenciaSelecionada}
                options={tiposAusencia}
                optionLabel="nome"
                emptyMessage="Não há informações"
                onChange={(e) => {
                  setTipoAusenciaSelecionada(e.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label style={{ color: "#24345a" }} htmlFor="observações">
                <b>Justificativa da falta: </b>
              </label>
              <InputTextarea
                rows={3}
                style={{
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: "#7988ac",
                  color: "#fff",
                }}
                type="text"
                value={justificativaAusencia}
                onChange={(e) => {
                  if (e.target.value.length <= 200) {
                    setJustificativaAusencia(e.target.value);
                  }
                }}
                maxLength={200}
              />
            </Col>
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados do evento">
          <Container>
            <Row>
              <Col>
                {" "}
                <label htmlFor="pacienteCompareceu" className="ml-2">
                  Paciente compareceu?
                </label>
              </Col>
              <Col>
                <div className="flex flex-wrap gap-3">
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId="sim"
                      name="sim"
                      value={statusComparecimentoList[0].value}
                      onChange={(e) => setStatusComparecimento(e.value)}
                      checked={
                        statusComparecimento ===
                        statusComparecimentoList[0].value
                      }
                    />
                    <label htmlFor="sim" className="ml-2">
                      Sim
                    </label>
                  </div>
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId="nao"
                      name="nao"
                      value={statusComparecimentoList[1].value}
                      onChange={(e) => setStatusComparecimento(e.value)}
                      checked={
                        statusComparecimento ===
                        statusComparecimentoList[1].value
                      }
                    />
                    <label htmlFor="nao" className="ml-2">
                      Não
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            {getBodyByStatusComparecimento()}
          </Container>
        </Panel>
        <br></br>
        <div className="FooterDialogAdicionarEvento">
          <DefaultButton
            fontSize={15}
            icon={<AtivarIcon />}
            buttonLabel={"Confirmar"}
            messageToolTip={"Confirma a troca de status de comparecimento"}
            click={(_) => {
              alterarStatusComparecimento(
                evento.id,
                statusComparecimento,
                tipoAusenciaSelecionada?.id,
                justificativaAusencia
              );
              setStatusComparecimento(null);
              setTipoAusenciaSelecionada(null);
              setJustificativaAusencia("");
              esconder();
            }}
            width={150}
            height={30}
          />
          <DefaultButton
            fontSize={15}
            icon={<CancelIcon />}
            buttonLabel={"Fechar"}
            messageToolTip={"Fechar esta janela e cancelar operação"}
            click={esconder}
            width={150}
            height={30}
          />
        </div>
      </Dialog>
    </div>
  );
}
