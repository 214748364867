import meusPacientesDataService from "../data_services/meus-pacientes/MeusPacientesDataService";
import { MeusPacientesDTO } from "../model/dto/MeusPacientesDTO";
import { MeusPacientesIdNomeDTO } from "../model/dto/MeusPacientesIdNomeDTO";
import { MeusPacientesFilteredRequestDTO } from "../model/dto/request/MeusPacientesFilteredRequestDTO";

class MeusPacientesService {
  static async getMeusPacientes(
    size?: number,
    page?: number,
    filters?: MeusPacientesFilteredRequestDTO
  ): Promise<MeusPacientesDTO[]> {
    if (!filters) {
      filters = new MeusPacientesFilteredRequestDTO({});
    }
    if (!size) {
      size = 10;
    }

    if (!page) {
      page = 0;
    }

    const response = await meusPacientesDataService.getMeusPacientes(
      size,
      page,
      filters
    );
    const meusPacientes: MeusPacientesDTO[] = response.data;
    return meusPacientes;
  }

  static async getMeusPacientesIdNome(): Promise<MeusPacientesIdNomeDTO[]> {
    const response = meusPacientesDataService.getMeusPacientesIdNome();
    const meusPacientes: MeusPacientesIdNomeDTO[] = (await response).data;
    return meusPacientes;
  }
}

export default MeusPacientesService;
