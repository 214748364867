import tipoProntuarioDataService from "../data_services/tipo-prontuario/TipoProntuarioDataService";
import { TipoProntuarioDTO } from "../model/dto/TipoProntuarioDTO";

class TipoProntuarioService {
  static async getAllTiposProntuario(): Promise<TipoProntuarioDTO[]> {
    const response = await tipoProntuarioDataService.getAllTiposProntuario();
    const tiposProntuario = response.data;
    return tiposProntuario;
  }
}

export default TipoProntuarioService;
