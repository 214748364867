import axios from "axios";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { environment } from "../../environments/environment";
import { CriarProntuarioRequestDTO } from "../../model/dto/request/CriarProntuarioRequestDTO";
import { AtualizarProntuarioRequestDTO } from "../../model/dto/request/AtualizarProntuarioRequestDTO";
import { ProntuarioPacienteFilteredRequestDTO } from "../../model/dto/request/ProntuarioPacienteFilteredRequestDTO";

class ProntuarioDataService {
  env = environment.getEnvironment();
  PRONTUARIO_ENDPOINTS = `${this.env.BACKEND_API_URL}/prontuario`;
  token = storageTokenConfig.getToken();

  getProntuarioById(prontuarioId: number) {
    return axios.get(`${this.PRONTUARIO_ENDPOINTS}/${prontuarioId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  getProntuariosByPacienteId(
    pacienteId: number,
    size: number,
    page: number,
    filters: ProntuarioPacienteFilteredRequestDTO
  ) {
    return axios.post(
      `${this.PRONTUARIO_ENDPOINTS}/paciente/${pacienteId}?size=${size}&&page=${page}`,
      filters,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  getProntuariosByPsicologo(size: number, page: number) {
    return axios.get(
      `${this.PRONTUARIO_ENDPOINTS}/psicologo?size=${size}&&page=${page}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  criarProntuario(criarProntuarioRequestDTO: CriarProntuarioRequestDTO) {
    return axios.post(
      `${this.PRONTUARIO_ENDPOINTS}`,
      criarProntuarioRequestDTO,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  atualizarProntuario(
    prontuarioId: number,
    atualizarProntuarioRequestDTO: AtualizarProntuarioRequestDTO
  ) {
    return axios.patch(
      `${this.PRONTUARIO_ENDPOINTS}/${prontuarioId}`,
      atualizarProntuarioRequestDTO,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }
}

const prontuarioDataService = new ProntuarioDataService();
export default prontuarioDataService;
