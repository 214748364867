import Messages from "../components/Messages";
import { validate } from "./Validator";

export function validarRequestBodyPaciente(paciente) {
  const camposValidar = [
    { name: "Nome", value: paciente.paciente },
    { name: "Nr. Carteirinha", value: paciente.nrCarteirinha },
    { name: "Psicologo", value: paciente.psicologo },
    { name: "Data de nasc.", value: paciente.dataNascimento },
    { name: "Médico", value: paciente.medico },
    { name: "Sexo", value: paciente.sexo },
  ];
  return validate(camposValidar);
}

export async function validarTrocaDeStatusPacienteMessage(rowData) {
    return Messages.questionMessage(
      "Confirme a sua opção",
      `O paciente ${rowData.paciente} será ${
        rowData.ativo ? "inativado" : "ativado"
      }`,
      `Sim. ${rowData.ativo ? "Inativar" : "Ativar"}`,
      "Não."
    );
  }
  