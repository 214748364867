import { EspecialidadeDTO } from "./EspecialidadeDTO";

export class MedicoDTO {
  id?: number;
  nome?: string;
  crm?: string;
  ativo?: boolean;
  estado?: string;
  especialidades?: EspecialidadeDTO[]

  constructor(options: Partial<MedicoDTO> = {}) {
    this.id = options.id;
    this.nome = options.nome;
    this.crm = options.crm;
    this.ativo = options.ativo;
    this.estado = options.estado;
    this.especialidades = options.especialidades;
  }
}
