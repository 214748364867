import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import message from "../../components/Messages";
import DefaultButton from "../../components/buttons/DefaultButton";
import {
  FiltrarIcon,
  LimparIcon,
  MeusPacientesIcon,
} from "../../components/icons/Icons";
import CustomPaginateDataTable from "../../components/table/CustomPaginateDataTable";
import { MedicoDTO } from "../../model/dto/MedicoDTO";
import { MeusPacientesDTO } from "../../model/dto/MeusPacientesDTO";
import { MeusPacientesFilteredRequestDTO } from "../../model/dto/request/MeusPacientesFilteredRequestDTO";
import MedicoService from "../../service/MedicoService";
import MeusPacientesService from "../../service/MeusPacientesService";
import ProntuarioService from "../../service/ProntuarioService";
import TipoProntuarioService from "../../service/TipoProntuarioService";
import DialogVisualizaPaciente from "../paciente/dialog/DialogVisualizaPaciente";
import "./MeusPacientes.css";
import DialogAcoesMeusPacientes from "./dialog/DialogAcoesMeusPacientes";
import DialogSelecionarTipoAtendimento from "./dialog/DialogSelecionarTipoAtendimento";
import { MeusPacientesHeader } from "./header/MeusPacientesHeader";

export default function MeusPacientes() {
  const history = useHistory();
  const [linhasPorPagina, setLinhasPorPagina] = useState(10);
  const [pagina, setPagina] = useState(0);
  const [displayModalTipoAtendimento, setDisplayModalTipoAtendimento] =
    useState(false);
  const [tiposProntuario, setTiposProntuario] = useState([]);
  const [pacienteSelecionado, setPacienteSelecionado] = useState(
    new MeusPacientesDTO()
  );
  const [
    displayModalVisualizarDadosPaciente,
    setDisplayModalVisualizarDadosPaciente,
  ] = useState(false);
  const [displayModalAcoesPacientes, setDisplayModalAcoesPaciente] =
    useState(false);

  const setLinhasPorPaginaCallback = useCallback(
    function (l) {
      setLinhasPorPagina(l);
    },
    [setLinhasPorPagina]
  );

  const setPaginaCallback = useCallback(
    function (p) {
      setPagina(p);
    },
    [setPagina]
  );

  const status = [
    { nome: "Todos", valor: null },
    { nome: "Ativos", valor: true },
    { nome: "Inativos", valor: false },
  ];

  const [statusPacientesSelecionado, setStatusPacientesSelecionado] =
    useState(null);
  const [meusPacientes, setMeusPacientes] = useState([]);
  const [totalMeusPacientes, setTotalMeusPacientes] = useState(0);
  const [blockUi, setBlockUi] = useState(false);
  const [medicos, setMedicos] = useState([]);

  const [filtros, setFiltros] = useState(new MeusPacientesFilteredRequestDTO());
  const [filtroMedicoSelecionado, setFiltroMedicoSelecionado] = useState(
    new MedicoDTO()
  );

  useEffect(() => {
    carregarMeusPacientes();
    carregarMedicos();
    carregarTiposProntuario();
  }, []);

  function navigate(url, state) {
    history.push(url, state);
  }

  async function filtrarMeusPacientes(size, page) {
    setBlockUi(true);
    try {
      const [meusPacientesData] = await Promise.all([
        MeusPacientesService.getMeusPacientes(size, page, filtros),
      ]);
      setMeusPacientes(meusPacientesData.content);
      setTotalMeusPacientes(meusPacientesData.totalElements);
      setBlockUi(false);
    } catch (error) {
      message.errorMessage("Ops ...", error.response.data.message);
      setBlockUi(false);
    }
  }

  async function carregarMeusPacientes() {
    setBlockUi(true);
    try {
      const [meusPacientesData] = await Promise.all([
        MeusPacientesService.getMeusPacientes(10, 0, null),
      ]);
      setMeusPacientes(meusPacientesData.content);
      setTotalMeusPacientes(meusPacientesData.totalElements);
      setBlockUi(false);
    } catch (error) {
      message.errorMessage("Ops ...", error.response.data.message);
      setBlockUi(false);
    }
  }

  async function carregarMedicos() {
    setBlockUi(true);
    const [medicosData] = await Promise.all([MedicoService.getAll()]);
    setMedicos(medicosData);
    setBlockUi(false);
  }

  async function carregarTiposProntuario() {
    setBlockUi(true);
    const [tiposProntuarioData] = await Promise.all([
      TipoProntuarioService.getAllTiposProntuario(),
    ]);
    setTiposProntuario(tiposProntuarioData);
    setBlockUi(false);
  }

  function visualizarAtendimentosDoPaciente(rowData) {
    setPacienteSelecionado(rowData);
  }

  function visualizarDadosDoPaciente(rowData) {
    setDisplayModalAcoesPaciente(false);
    setDisplayModalVisualizarDadosPaciente(true);
    setPacienteSelecionado(rowData);
  }

  function selecionarAtendimentoPaciente(rowData) {
    setDisplayModalAcoesPaciente(false);
    setDisplayModalTipoAtendimento(true);
    setPacienteSelecionado(rowData);
  }

  async function iniciarAtendimentoPaciente(tipoProntuario) {
    setBlockUi(true);
    try {
      const response = await ProntuarioService.criarProntuario(
        tipoProntuario.id,
        pacienteSelecionado.id
      );
      fecharModalSelecionarTipoAtendimento();
      setBlockUi(false);
      navigate(`/prontuario/cadastrar/${response.id}/editar`, {
        isVisualizacao: false,
      });
    } catch (error) {
      fecharModalSelecionarTipoAtendimento();
      message.errorMessage("Opss...", error.response.data.message);
      setBlockUi(false);
    }
    setBlockUi(false);
  }

  function fecharModalSelecionarTipoAtendimento() {
    setDisplayModalTipoAtendimento(false);
  }

  function fecharModalVisualizarDadosDoPaciente() {
    setDisplayModalVisualizarDadosPaciente(false);
  }

  function fecharModalAcoesPacientes() {
    setDisplayModalAcoesPaciente(false);
  }

  async function limpar() {
    setFiltroMedicoSelecionado(new MedicoDTO());
    setFiltros(new MeusPacientesFilteredRequestDTO());
    setStatusPacientesSelecionado(null);
    setLinhasPorPagina(10);
    setPagina(0);
    carregarMedicos();
    carregarMeusPacientes();
  }

  function onClickLinhaPaciente(rowData) {
    setPacienteSelecionado(rowData.value.paciente);
    setDisplayModalAcoesPaciente(true);
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div>
        <div className="MeusPacientes">
          <div className="Title">
            <span>
              <MeusPacientesIcon /> Meus pacientes
            </span>
          </div>
        </div>
        <div className="BodyMeusPacientes">
          <Row>
            <Col>
              <label htmlFor="medicoFilter">Médico: &nbsp;&nbsp;</label>
              <Dropdown
                className="FiltroMedico"
                placeholder="Selecione um médico"
                name="medicoFilter"
                id="medicoFilter"
                style={{
                  width: "100%",
                  height: "50%",
                  background: "#7988ac",
                }}
                value={
                  filtroMedicoSelecionado.id !== undefined
                    ? filtroMedicoSelecionado
                    : {}
                }
                options={medicos}
                optionLabel="nome"
                emptyMessage="Não há médicos"
                onChange={(e) => {
                  setFiltroMedicoSelecionado(e.target.value);
                  setFiltros({ ...filtros, medicoId: e.target.value.id });
                }}
              />
            </Col>
            <Col>
              <label htmlFor="pacienteStatusFilter">Status: &nbsp;&nbsp;</label>
              <Dropdown
                placeholder="Selecione um status"
                name="pacienteStatusFilter"
                id="pacienteStatusFilter"
                style={{ width: "100%", background: "#7988ac" }}
                value={statusPacientesSelecionado}
                options={status}
                optionLabel="nome"
                onChange={(e) => {
                  setStatusPacientesSelecionado(e.value);
                  setFiltros({
                    ...filtros,
                    ativo: e.value.valor,
                  });
                }}
              />
            </Col>
            <Col>
              <label htmlFor="pacienteNomeFilter">Nome: &nbsp;&nbsp;</label>
              <InputText
                style={{ width: "100%", textAlign: "center" }}
                type="text"
                value={filtros.nome !== undefined ? filtros.nome : ""}
                onChange={(e) =>
                  setFiltros({
                    ...filtros,
                    nome: e.target.value,
                  })
                }
              />
            </Col>
            <Col>
              <label htmlFor="pacienteNomeFilter">
                Nr. carteirinha: &nbsp;&nbsp;
              </label>
              <InputText
                style={{ width: "100%", textAlign: "center" }}
                type="text"
                value={
                  filtros.nrCarteirinha !== undefined
                    ? filtros.nrCarteirinha
                    : ""
                }
                onChange={(e) =>
                  setFiltros({
                    ...filtros,
                    nrCarteirinha: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <div className="FilterItens">
              <DefaultButton
                fontSize={12}
                icon={<FiltrarIcon />}
                buttonLabel={"Filtrar"}
                messageToolTip={
                  "Filtra os resultados de acordo com os parâmetros"
                }
                click={(_) => {
                  filtrarMeusPacientes(linhasPorPagina, pagina);
                }}
                width={150}
                height={30}
              />{" "}
              <DefaultButton
                fontSize={12}
                icon={<LimparIcon />}
                buttonLabel={"Limpar"}
                messageToolTip={"Limpar os filtros"}
                click={limpar}
                width={150}
                height={30}
              />{" "}
            </div>
          </Row>
          <div style={{ marginTop: 40 }}>
            <CustomPaginateDataTable
              values={meusPacientes}
              totalRecords={totalMeusPacientes}
              dataKey="id"
              headerList={MeusPacientesHeader}
              actionTemplate={null}
              paginator={true}
              exibeActionTemplate={false}
              callbackFunction={filtrarMeusPacientes}
              linhasPorPagina={linhasPorPagina}
              setLinhasPorPagina={setLinhasPorPaginaCallback}
              setPagina={setPaginaCallback}
              fieldToSort={"nrPacientePsicologo"}
              showInLeft={true}
              callbackSelectionChange={onClickLinhaPaciente}
            />
          </div>
        </div>
      </div>
      <DialogAcoesMeusPacientes
        display={displayModalAcoesPacientes}
        esconder={fecharModalAcoesPacientes}
        paciente={pacienteSelecionado}
        tituloModal={
          pacienteSelecionado !== undefined
            ? pacienteSelecionado.paciente
            : null
        }
        visualizaListaAtendimentos={visualizarAtendimentosDoPaciente}
        hrefVisualizarAtendimentosPaciente={`/prontuario/paciente/${pacienteSelecionado.id}`}
        iniciaAtendimentoPaciente={selecionarAtendimentoPaciente}
        visualizarCadastro={visualizarDadosDoPaciente}
      />
      <DialogSelecionarTipoAtendimento
        display={displayModalTipoAtendimento}
        esconder={fecharModalSelecionarTipoAtendimento}
        tiposProntuario={tiposProntuario}
        iniciarAtendimento={iniciarAtendimentoPaciente}
        nomePaciente={
          pacienteSelecionado?.paciente ? pacienteSelecionado.paciente : ""
        }
        dataNascimento={
          pacienteSelecionado?.paciente
            ? pacienteSelecionado.dataNascimento
            : null
        }
      />
      <DialogVisualizaPaciente
        display={displayModalVisualizarDadosPaciente}
        esconder={fecharModalVisualizarDadosDoPaciente}
        paciente={pacienteSelecionado !== undefined ? pacienteSelecionado : {}}
        tituloModal={
          pacienteSelecionado !== undefined &&
          pacienteSelecionado.paciente !== undefined
            ? pacienteSelecionado.paciente
            : ""
        }
      />
    </BlockUI>
  );
}
