import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useState } from "react";

import PropTypes from "prop-types";

import DefaultButton from "../../../components/buttons/DefaultButton";
import { Password } from "primereact/password";
import "./RightInformations.css";

export default function RightInformations(props) {
  const doLogin = props.doLogin;

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="col-5 flex align-items-center justify-content-center">
      <div className="p-fluid" style={{ width: 350 }}>
        <Card style={{ backgroundColor: "#5685a5" }}>
          <div className="field">
            <label
              style={{ color: "#24345a", fontFamily: "Syncopate" }}
              htmlFor="username"
            >
              Usuário
            </label>
            <InputText
              style={{
                backgroundColor: "#fff",
                color: "#000",
                fontFamily: "Syncopate",
                textAlign: "left",
              }}
              id="username"
              type="text"
              onChange={(e) => {
                setLogin(e.target.value);
              }}
            />
          </div>
          <div className="field">
            <label
              style={{ color: "#24345a", fontFamily: "Syncopate" }}
              htmlFor="password"
            >
              Senha
            </label>
            <div className="RightInformations">
              <Password
                style={{ width: "100%", textAlign: "center" }}
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                toggleMask
                feedback={false}
              />
            </div>
          </div>
          <DefaultButton
            buttonLabel="Login"
            labelColor="#5685a5"
            backgroundColor="#fff"
            borderColor="#24345a"
            hoverColor="#24345a"
            onHoverBorderColor="#fff"
            onClickColor="#1e1f26"
            onClickBorderColor="#cc2a36"
            width={150}
            height={60}
            messageToolTip="Logar-se no sistema"
            click={() => doLogin(login, password)}
          />
        </Card>
      </div>
    </div>
  );
}

RightInformations.propTypes = {
  doLogin: PropTypes.func,
};
