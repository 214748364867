/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import "moment/locale/pt-br";
import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Col, Row } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import message from "../../components/Messages";
import DefaultButton from "../../components/buttons/DefaultButton";
import { AgendaIcon, ExportarExcelIcon } from "../../components/icons/Icons";
import authDataService from "../../data_services/auth/AuthDataService";
import { EventoAgendaDTO } from "../../model/dto/EventoAgendaDTO";
import { UsuarioInfoDTO } from "../../model/dto/UsuarioInfoDTO";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import TipoManipulacaoAgendaEnum from "../../model/enums/TipoManipulacaoAgendaEnum";
import EventoAgendaService from "../../service/EventoAgendaService";
import MeusPacientesService from "../../service/MeusPacientesService";
import TipoAusenciaService from "../../service/TipoAusenciaService";
import TipoEventoAgendaService from "../../service/TipoEventoAgendaService";
import UsuarioService from "../../service/UsuarioService";
import { exportAgendaExcel } from "../../utils/excel/ExportAgendaExcel";
import {
  validarExclusaoEventoMessage,
  validarRequestBodyCriacaoEvento,
} from "../../validator/CriarEventoValidator";
import "./Agenda.css";
import "./Agenda.scss";
import DialogAdicionarEvento from "./dialog/DialogAdicionarEvento";
import DialogMesAnoExportacao from "./dialog/DialogMesAnoExportacao";

export default function Agenda() {
  moment.locale("pt-br");
  const usuarioLogado = JSON.parse(secureLocalStorage.getItem("userSession"));
  const [blockUi, setBlockUi] = useState(false);
  const localizer = momentLocalizer(moment);
  const { defaultDate } = useMemo(
    () => ({
      defaultDate: new Date(),
    }),
    []
  );
  const [eventos, setEventos] = useState([]);
  const [tiposEvento, setTiposEvento] = useState([]);
  const [evento, setEvento] = useState(new EventoAgendaDTO());
  const setEventoSelecionadoCallback = useCallback(
    function (e) {
      setEvento(e);
    },
    [setEvento]
  );
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalExportar, setDisplayModalExportar] = useState(false);
  const [usuariosPsicologos, setUsuariosPsicologos] = useState([]);
  const [usuarioPsicologoSelecionado, setUsuarioPsicologoSelecionado] =
    useState(new UsuarioInfoDTO());
  const [tipoManipulacaoEvento, setTipoManipulacaoEvento] = useState(
    TipoManipulacaoAgendaEnum.CRIACAO_EVENTO
  );
  const [meusPacientes, setMeusPacientes] = useState([]);
  const [tiposAusencia, setTiposAusencia] = useState([]);
  const [outroUsuarioSelecionado, setOutroUsuarioSelecionado] = useState(false);

  useEffect(() => {
    if (outroUsuarioSelecionado) {
      setTipoManipulacaoEvento(TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO);
    }
  }, [outroUsuarioSelecionado]);

  useEffect(() => {
    initAgenda();
  }, []);

  async function initAgenda() {
    carregarDados();
    if (
      authDataService.hasPermission(
        FuncionalidadeEnum.VISUALIZACAO_AGENDA_GERAL
      )
    ) {
      carregarUsuariosPsicologos();
    }

    if (
      authDataService.hasPermission(
        FuncionalidadeEnum.VISUALIZAR_MEUS_PACIENTES
      ) && usuarioLogado.isPsicologo
    ) {
      carregarPacientesDoPsicologo();
    }
  }

  async function carregarUsuariosPsicologos() {
    setBlockUi(true);
    const [psicologosData] = await Promise.all([
      UsuarioService.getUsuariosPsicologos(),
    ]);
    if (usuarioLogado.isPsicologo) {
      setUsuariosPsicologos(
        psicologosData.filter((psicologo) => psicologo.id !== usuarioLogado.id)
      );
    } else {
      setUsuariosPsicologos(psicologosData);
    }
    setBlockUi(false);
  }

  async function carregarPacientesDoPsicologo() {
    setBlockUi(true);
    const [pacientesData] = await Promise.all([
      MeusPacientesService.getMeusPacientesIdNome(),
    ]);
    setMeusPacientes(pacientesData);
    setBlockUi(false);
  }

  async function carregarDados() {
    setBlockUi(true);
    try {
      const [tiposEventoData, eventosData, tiposAusenciaData] =
        await Promise.all([
          authDataService.hasPermission(
            FuncionalidadeEnum.VISUALIZACAO_AGENDA_GERAL
          )
            ? TipoEventoAgendaService.getAllTiposEvento()
            : TipoEventoAgendaService.getAllTiposEventoFilteredByUsuarioPsicologo(),
          EventoAgendaService.getAllEventosByUsuarioLogado(),
          TipoAusenciaService.getAllTiposAusencia(),
        ]);
      setTiposEvento(tiposEventoData);
      setEventos(eventosData);
      setTiposAusencia(tiposAusenciaData);
      setBlockUi(false);
    } catch (error) {
      message.errorMessage("Ops ...", error.response.data.message);
      setBlockUi(false);
    }
    setBlockUi(false);
  }

  function ajustarDataDosEventos(eventos) {
    return eventos.map((evento) => ({
      ...evento,
      inicio: new Date(
        new Date(evento.inicio).getFullYear(),
        new Date(evento.inicio).getMonth(),
        new Date(evento.inicio).getDate(),
        new Date(evento.inicio).getHours(),
        new Date(evento.inicio).getMinutes()
      ),
      fim: new Date(
        new Date(evento.fim).getFullYear(),
        new Date(evento.fim).getMonth(),
        new Date(evento.fim).getDate(),
        new Date(evento.fim).getHours(),
        new Date(evento.fim).getMinutes()
      ),
    }));
  }

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      if (outroUsuarioSelecionado) {
        setTipoManipulacaoEvento(TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO);
      } else {
        setTipoManipulacaoEvento(TipoManipulacaoAgendaEnum.CRIACAO_EVENTO);
      }
      end = checkDates(start, end);
      setEvento({
        ...evento,
        inicio: start,
        fim: end,
      });
      setDisplayModal(true);
    },
    [outroUsuarioSelecionado, evento]
  );

  function checkDates(start, end) {
    if(start.toDateString() !== end.toDateString()) {
      end = new Date(start);
      return end.setHours(end.getHours(), end.getMinutes(), end.getSeconds(), end.getMilliseconds());
    } else {
      return end;
    }
  }

  const handleSelectEvent = useCallback((evento) => {
    if (evento.usuario.id === usuarioLogado.id) {
      setTipoManipulacaoEvento(TipoManipulacaoAgendaEnum.EDICAO_EVENTO);
    }
    setEvento(evento);
    setDisplayModal(true);
  }, []);

  function fecharModal() {
    setEvento(null);
    setDisplayModal(false);
  }

  async function handleVisualizarAgendaPsicologo(e) {
    setOutroUsuarioSelecionado(true);
    setTipoManipulacaoEvento(TipoManipulacaoAgendaEnum.VISUALIZACAO_EVENTO);
    setUsuarioPsicologoSelecionado(e.value);
    setBlockUi(true);
    const [eventosData] = await Promise.all([
      EventoAgendaService.getAllEventosByUsuarioId(e.value.id),
    ]);
    setEventos(eventosData);
    setUsuarioPsicologoSelecionado(e.value);
    setBlockUi(false);
  }

  function getCorFundoEvento(event) {
    if (event !== undefined && event.tipoEventoAgenda !== undefined) {
      if (event.isCancelado) {
        return "red";
      } else {
        return event.tipoEventoAgenda.hexCorTipoEvento;
      }
    } else {
      return "blue";
    }
  }

  function getCorLabelEvento(event) {
    if (event !== undefined && event.tipoEventoAgenda !== undefined) {
      if (event.isCancelado) {
        return "white";
      } else {
        return event.tipoEventoAgenda.hexCorLabelTipoEvento;
      }
    } else {
      return "blue";
    }
  }

  function carregarMeusEventos() {
    setOutroUsuarioSelecionado(false);
    setUsuarioPsicologoSelecionado(new UsuarioInfoDTO({ id: null }));
    setTipoManipulacaoEvento(TipoManipulacaoAgendaEnum.CRIACAO_EVENTO);
    initAgenda();
  }

  async function salvarEvento(evento, replicaEventoNoMes) {
    if (!validarRequestBodyCriacaoEvento(evento)) {
      fecharModal();
      return;
    }

    try {
      setBlockUi(true);
      await EventoAgendaService.salvarDadosEvento(
        evento.id,
        evento,
        replicaEventoNoMes
      );
      if (tipoManipulacaoEvento === TipoManipulacaoAgendaEnum.EDICAO_EVENTO) {
        fecharModal();
        setBlockUi(false);
        message.successMessage("Sucesso", "Evento editado com sucesso!");
        initAgenda();
      } else {
        fecharModal();
        setBlockUi(false);
        message.successMessage("Sucesso", "Evento criado com sucesso!");
        initAgenda();
      }
    } catch (error) {
      fecharModal();
      setBlockUi(false);
      message.errorMessage("Erro", error.response.data.message);
    }
    setBlockUi(false);
  }

  async function removerEvento(evento) {
    fecharModal();
    const result = await validarExclusaoEventoMessage(evento);
    if (result.isConfirmed) {
      setBlockUi(true);
      try {
        await EventoAgendaService.deletarEvento(evento.id);
        fecharModal();
        setBlockUi(false);
        message.successMessage("Sucesso", "Evento removido com sucesso!");
        initAgenda();
      } catch (error) {
        fecharModal();
        setBlockUi(false);
        message.errorMessage("Erro", error.response.data.message);
      }
      setBlockUi(false);
    }
  }

  async function cancelarEvento(eventoId, motivoCancelamento, isCancelar) {
    setBlockUi(true);
    try {
      await EventoAgendaService.cancelarEvento(
        eventoId,
        motivoCancelamento,
        isCancelar
      );
      fecharModal();
      setBlockUi(false);
      message.successMessage(
        "Sucesso",
        `Evento ${evento.isCancelado ? `reativado` : `cancelado`} com sucesso`
      );
      initAgenda();
    } catch (error) {
      fecharModal();
      setBlockUi(false);
      message.errorMessage("Erro", error.response.data.message);
    }
    setBlockUi(false);
  }

  async function alterarStatusComparecimento(
    eventoId,
    pacienteCompareceu,
    tipoAusenciaId,
    justificativaAusencia
  ) {
    setBlockUi(true);
    try {
      await EventoAgendaService.alterarStatusComparecimentoPaciente(
        eventoId,
        pacienteCompareceu,
        tipoAusenciaId,
        justificativaAusencia
      );
      fecharModal();
      setBlockUi(false);
      message.successMessage(
        "Sucesso",
        "Status do evento alterado com sucesso!"
      );
      initAgenda();
    } catch (error) {
      fecharModal();
      setBlockUi(false);
      message.errorMessage("Erro", error.response.data.message);
    }
    setBlockUi(false);
  }

  function getBodyEvento(evento) {
    if (
      evento.tipoEventoAgenda.nomeTipoEvento === "Consulta" &&
      evento.pacienteCompareceu
    ) {
      return (
        <div>
          {evento.nomeEvento}
          <span style={{ color: "green", marginLeft: "5px" }}>✓</span>
        </div>
      );
    } else if (
      evento.tipoEventoAgenda.nomeTipoEvento === "Consulta" &&
      (evento.pacienteCompareceu === false || new Date() > evento.inicio)
    ) {
      return (
        <div>
          {evento.nomeEvento}
          <span style={{ color: "red", marginLeft: "5px" }}>✗</span>
          <span>
            {evento?.tipoAusencia ? `(${evento?.tipoAusencia.nome})` : ""}
          </span>
        </div>
      );
    } else {
      return <div>{evento.nomeEvento}</div>;
    }
  }

  function fecharModalExportarEvento() {
    setDisplayModalExportar(false);
  }

  function handleDisplayModalExportar() {
    setDisplayModalExportar(true);
  }

  async function exportarDadosAgenda(mes, ano) {
    setBlockUi(true);
    try {
      const usuarioId =
        usuarioPsicologoSelecionado?.id !== undefined &&
        usuarioPsicologoSelecionado?.id !== null
          ? usuarioPsicologoSelecionado.id
          : usuarioLogado.id;
      const [eventosData] = await Promise.all([
        EventoAgendaService.getByUsuarioIdAndMesAndAno(
          usuarioId,
          mes.valor,
          ano
        ),
      ]);
      exportAgendaExcel(
        EventoAgendaService.montaObjetoParaExportacao(eventosData),
        getFileName(mes, ano)
      );
    } catch (error) {
      fecharModalExportarEvento();
      setBlockUi(false);
      message.errorMessage("Erro", error.response.data.message);
    }
    setBlockUi(false);
  }

  function getFileName(mes, ano) {
    const usuario =
      usuarioPsicologoSelecionado?.id !== undefined &&
      usuarioPsicologoSelecionado?.id !== null
        ? usuarioPsicologoSelecionado
        : usuarioLogado;

    return `EVENTOS_${usuario.nome}_${mes.nome.toUpperCase()}_${ano}`;
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div className="Agenda">
        <div className="AgendaTitle">
          <span>
            <AgendaIcon /> Agenda
          </span>
        </div>
        <div></div>
      </div>
      <div className="BodyAgenda">
        <div className="DropdownUsuariosPsicologos">
          <Row>
            {authDataService.hasPermission(
              FuncionalidadeEnum.VISUALIZACAO_AGENDA_GERAL
            ) && (
              <>
                <Col>
                  <label
                    style={{ color: "#24345a" }}
                    htmlFor="tipoEventoSelecionado"
                  >
                    <b>Visualizar agenda do psicólogo: </b>
                  </label>
                  <Dropdown
                    filter
                    style={{ width: "100%", background: "#7988ac" }}
                    value={
                      usuarioPsicologoSelecionado !== null &&
                      usuarioPsicologoSelecionado !== undefined
                        ? usuarioPsicologoSelecionado
                        : ""
                    }
                    options={usuariosPsicologos}
                    optionLabel="nome"
                    emptyMessage="Não há informações"
                    onChange={handleVisualizarAgendaPsicologo}
                  />
                </Col>
                <Col>
                  <div style={{ marginTop: 18 }}>
                    {" "}
                    <DefaultButton
                      fontSize={13}
                      icon={<AgendaIcon />}
                      buttonLabel={"Meus eventos"}
                      messageToolTip={"Visualiza os eventos do usuário logado"}
                      click={carregarMeusEventos}
                      width={150}
                      height={35}
                    />
                  </div>
                </Col>
              </>
            )}
            <Col>
              <div style={{ marginTop: 18 }}>
                <DefaultButton
                  fontSize={13}
                  icon={<ExportarExcelIcon />}
                  buttonLabel={"Exportar"}
                  messageToolTip={
                    "Exporta os eventos de acordo com os parâmetros selecionados"
                  }
                  click={handleDisplayModalExportar}
                  width={150}
                  height={30}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Calendar
          titleAccessor={getBodyEvento}
          defaultView="day"
          messages={{
            today: "Hoje",
            previous: "Anterior",
            next: "Próximo",
            month: "Mês",
            week: "Semana",
            day: "Dia",
            date: "Data",
            time: "Hora",
            event: "Evento",
            noEventsInRange: "Nenhum evento programado no intervalo informado",
            showMore: (total) => `+${total} eventos`,
          }}
          localizer={localizer}
          events={ajustarDataDosEventos(eventos)}
          startAccessor="inicio"
          endAccessor="fim"
          defaultDate={defaultDate}
          selectable={true}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          eventPropGetter={(event) => {
            let eventStyle = {};
            eventStyle.backgroundColor = getCorFundoEvento(event);
            eventStyle.color = getCorLabelEvento(event);
            eventStyle.borderColor = "black";
            eventStyle.boxShadow = "0px 8px 6px rgba(0, 0, 0, 0.2)";
            eventStyle.fontFamily =
              "system-ui, -apple-system, Arial, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue'";
            return {
              style: eventStyle,
            };
          }}
        />
      </div>
      <DialogAdicionarEvento
        display={displayModal}
        esconder={fecharModal}
        evento={evento}
        setEvento={setEventoSelecionadoCallback}
        tiposEvento={tiposEvento}
        tipoManipulacaoEvento={tipoManipulacaoEvento}
        salvarEvento={salvarEvento}
        meusPacientes={meusPacientes}
        removerEvento={removerEvento}
        cancelarEvento={cancelarEvento}
        tiposAusencia={tiposAusencia}
        alterarStatusComparecimento={alterarStatusComparecimento}
      />
      <DialogMesAnoExportacao
        display={displayModalExportar}
        esconder={fecharModalExportarEvento}
        exportar={exportarDadosAgenda}
      />
    </BlockUI>
  );
}
