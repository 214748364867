import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import HomeDashboardService from "../../service/HomeDashboardService";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";
import If from "../../components/If";
import "./HomeDashboard.css";
import { formatDataHora } from "../../utils/FormatDataUtils";

Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale
);

export default function HomeDashboard() {
  const [dashboard, setDashboard] = useState(null);

  useEffect(() => {
    const userLogged = JSON.parse(secureLocalStorage.getItem("userSession"));
    if (userLogged !== null && userLogged.isPsicologo) {
      carregarDadosDashboard(userLogged.psicologo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function carregarDadosDashboard(psicologoId) {
    const [dashboardData] = await Promise.all([
      HomeDashboardService.getPsicologoDashboard(psicologoId),
    ]);
    setDashboard(dashboardData);
  }

  const pieChartData = dashboard
    ? {
        labels: ["Pacientes Ativos", "Pacientes Inativos"],
        datasets: [
          {
            label: "Pacientes",
            data: [
              dashboard.quantidadePacientesAtivos || 0,
              dashboard.quantidadePacientesInativos || 0,
            ],
            backgroundColor: ["#24345a", "#777a82"],
            hoverBackgroundColor: ["#24345a", "#777a82"],
          },
        ],
      }
    : null;

  const barChartData = dashboard
    ? {
        labels: [
          "Qtd. prontuários",
          "Qtd. prontuários mês corrente",
          "Qtd. prontuários nesta semana",
          "Qtd. prontuários abertos hoje",
        ],
        datasets: [
          {
            label: "Prontuários abertos no sistema",
            data: [
              dashboard.quantidadeAtendimentos || 0,
              dashboard.quantidadeAtendimentosNoMesAtual || 0,
              dashboard.quantidadeAtendimentosNaSemana || 0,
              dashboard.quantidadeAtendimentosHoje || 0,
            ],
            backgroundColor: ["#24345a", "#3b59a9", "#5b71ac", "#192f68"],
            hoverBackgroundColor: ["#24345a", "#3b59a9", "#5b71ac", "#192f68"],
          },
        ],
      }
    : null;

  const barChartTipoAtendimentoData = dashboard
    ? {
        labels: [
          "Atendimento normal",
          "Anamnese infantil",
          "Anamnese adolescente",
        ],
        datasets: [
          {
            label: "Prontuários abertos por tipo",
            data: [
              dashboard.quantidadeAtendimentosTipoNormal || 0,
              dashboard.quantidadeAtendimentosTipoAnamneseInfantil || 0,
              dashboard.quantidadeAtendimentosTipoAnamneseAdolescente || 0,
            ],
            backgroundColor: ["#24345a", "#3b59a9", "#5b71ac"],
            hoverBackgroundColor: ["#24345a", "#3b59a9", "#5b71ac"],
          },
        ],
      }
    : null;

  const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="HomeDashboard">
      <div style={{ marginTop: 10 }}>
        <label style={{ fontSize: 18 }}>
          <b>
            <u>Meu Dashboard</u>
          </b>
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 10,
          }}
        >
          <If condition={barChartData}>
            <div style={{ width: "280px", height: "280px" }}>
              <Bar data={barChartData} options={barChartOptions} />
            </div>
          </If>

          <If condition={pieChartData}>
            <div style={{ width: "280px", height: "280px" }}>
              <Pie data={pieChartData} />
            </div>
          </If>

          <If condition={barChartTipoAtendimentoData}>
            <div style={{ width: "280px", height: "280px" }}>
              <Bar
                data={barChartTipoAtendimentoData}
                options={barChartOptions}
              />
            </div>
          </If>
        </div>
      </div>
      <div style={{ marginTop: 40 }}></div>
      <label style={{ fontSize: 18 }}>
        Dados do meu último registro de prontuário
      </label>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 10,
        }}
      >
        <If condition={dashboard && dashboard.ultimoAtendimento !== null}>
          <>
            <label>
              <b>Prontuário:</b>:{" "}
              {dashboard?.ultimoAtendimento?.nrProntuario || "N/A"}
            </label>
            <label>
              <b>Paciente: </b>
              {dashboard?.ultimoAtendimento?.paciente?.paciente || "N/A"}
            </label>
            <label>
              <b>Data abertura prontuário: </b>
              {formatDataHora(dashboard?.ultimoAtendimento?.dataHoraCriacao) ||
                "N/A"}
            </label>
            <label>
              <b>Data última atualização: </b>
              {formatDataHora(
                dashboard?.ultimoAtendimento?.dataHoraUltimaAtualizacao
              ) || "N/A"}
            </label>
          </>
        </If>
      </div>

      <div style={{ marginTop: 30 }}></div>
      <label style={{ fontSize: 18 }}>Meu próximo compromisso</label>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 10,
        }}
      >
        <If condition={dashboard && dashboard.proximoEvento !== null}>
          <>
            <label>
              <b>Tipo:</b>:{" "}
              {dashboard?.proximoEvento?.tipoEventoAgenda?.nomeTipoEvento ||
                "N/A"}
            </label>
            <label>
              <b>Paciente: </b>
              {dashboard?.proximoEvento?.paciente?.paciente || "N/A"}
            </label>
            <label>
              <b>Compromisso: </b>
              {dashboard?.proximoEvento?.nomeEvento || "N/A"}
            </label>
            <label>
              <b>Data: </b>
              {formatDataHora(dashboard?.proximoEvento?.inicio) || "N/A"}
            </label>
          </>
        </If>
      </div>
    </div>
  );
}
