/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";
import { EditIcon, ExportarExcelIcon } from "../../../components/icons/Icons";

export default function DialogMesAnoExportacao({
  display,
  esconder,
  exportar,
}) {
  const mesesDoAno = [
    { nome: "Janeiro", valor: 1 },
    { nome: "Fevereiro", valor: 2 },
    { nome: "Março", valor: 3 },
    { nome: "Abril", valor: 4 },
    { nome: "Maio", valor: 5 },
    { nome: "Junho", valor: 6 },
    { nome: "Julho", valor: 7 },
    { nome: "Agosto", valor: 8 },
    { nome: "Setembro", valor: 9 },
    { nome: "Outubro", valor: 10 },
    { nome: "Novembro", valor: 11 },
    { nome: "Dezembro", valor: 12 },
  ];
  const [mesSelecionado, setMesSelecionado] = useState({});
  const [anoSelecionado, setAnoSelecionado] = useState({});

  useEffect(() => {
    const obterDataAtual = () => {
      const dataAtual = new Date();
      const mesAtual = dataAtual.getMonth() + 1;
      const anoAtual = dataAtual.getFullYear();
      return { mes: mesAtual, ano: anoAtual };
    };

    const { mes, ano } = obterDataAtual();
    setMesSelecionado(mesesDoAno.find((m) => m.valor === mes));
    setAnoSelecionado(ano);
  }, []);

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<EditIcon />}
        Parâmetros de exportação
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel>
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="mesSelecionado">
                  <b>Mês: </b>
                </label>
                <Dropdown
                  filter
                  style={{ width: "100%", background: "#7988ac" }}
                  value={mesSelecionado}
                  options={mesesDoAno}
                  optionLabel="nome"
                  emptyMessage="Não há informações"
                  onChange={(e) => {
                    setMesSelecionado(e.value);
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="anoSelecionado">
                  <b>Ano: </b>
                </label>
                <InputText
                  style={{ width: "100%", textAlign: "center" }}
                  type="number"
                  value={anoSelecionado}
                  onChange={(e) => setAnoSelecionado(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        <div className="FooterDialogAdicionarEvento">
          <DefaultButton
            fontSize={13}
            icon={<ExportarExcelIcon />}
            buttonLabel="Exportar"
            messageToolTip={
              "Exporta os eventos da agenda de acordo com os parâmetros"
            }
            click={(_) => {
              exportar(mesSelecionado, anoSelecionado);
              esconder();
            }}
            width={150}
            height={30}
          />
        </div>
      </Dialog>
    </div>
  );
}
