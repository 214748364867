import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";
import {
  CancelIcon,
  CancelarEventoIcon,
} from "../../../components/icons/Icons";

export default function DialogCancelarEvento({
  evento,
  cancelarEvento,
  display,
  esconder,
}) {
  const [motivoCancelamento, setMotivoCancelamento] = useState("");

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<CancelarEventoIcon />}
        {evento !== null && evento !== undefined && evento.isCancelado
          ? `Reativar`
          : `Cancelar`}{" "}
        {` evento ${evento ? evento.nomeEvento : ``}`}
      </div>
    );
  };

  function getStatus() {
    if (evento && evento.isCancelado) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados do evento">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="observações">
                  <b>Motivo do cancelamento: </b>
                </label>
                <InputTextarea
                  disabled={evento && evento.isCancelado}
                  rows={3}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#7988ac",
                    color: "#fff",
                  }}
                  type="text"
                  value={motivoCancelamento}
                  onChange={(e) => {
                    if (e.target.value.length <= 200) {
                      setMotivoCancelamento(e.target.value);
                    }
                  }}
                  maxLength={200}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        <div className="FooterDialogAdicionarEvento">
          <DefaultButton
            fontSize={15}
            icon={<CancelarEventoIcon />}
            buttonLabel="Salvar"
            messageToolTip={
              evento && evento.isCancelado
                ? `Reativa este evento`
                : `Cancela este evento`
            }
            click={(_) => {
              cancelarEvento(evento.id, motivoCancelamento, getStatus());
              setMotivoCancelamento("");
              esconder();
            }}
            width={150}
            height={30}
          />
          <DefaultButton
            fontSize={15}
            icon={<CancelIcon />}
            buttonLabel={"Fechar"}
            messageToolTip={"Fechar esta janela e cancelar operação"}
            click={esconder}
            width={150}
            height={30}
          />
        </div>
      </Dialog>
    </div>
  );
}
