import { formatData } from "../../../utils/FormatDataUtils";

export const PacientePsicologosHeader = [
  { field: "psicologo.nome", header: "Psicólogo" },
  {
    field: "dataInicioVinculo",
    header: "Data de início do vínculo",
    body: formatDataInicioVinculo,
  },
  {
    field: "dataFimVinculo",
    header: "Data de fim do vínculo",
    body: formatDataFimVinculo,
  },
  {
    field: "isPsicologoAtual",
    header: "Psicólogo atual?",
    body: formatIsPsicologoAtual,
  },
];

function formatDataInicioVinculo(rowData) {
  if (rowData.dataInicioVinculo !== null) {
    return formatData(rowData.dataInicioVinculo);
  } else {
    return "";
  }
}

function formatDataFimVinculo(rowData) {
  if (rowData.dataFimVinculo !== null) {
    return formatData(rowData.dataFimVinculo);
  } else {
    return "";
  }
}

function formatIsPsicologoAtual(rowData) {
  if (rowData.isPsicologoAtual) {
    return "Sim";
  } else {
    return "Não";
  }
}
