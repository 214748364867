import axios from "axios";
import { environment } from "../../environments/environment";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";

class FuncionalidadeDataService {
  env = environment.getEnvironment();
  FUNCIONALIDADE_ENDPOINTS = `${this.env.BACKEND_API_URL}/funcionalidade`;
  token = storageTokenConfig.getToken();

  getAll() {
    return axios.get(`${this.FUNCIONALIDADE_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const funcionalidadeDataService = new FuncionalidadeDataService();
export default funcionalidadeDataService;
