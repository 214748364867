export class UsuarioInfoDTO {
  id?: string;
  nome?: string;
  email?: string;
  login?: string;

  constructor(options: Partial<UsuarioInfoDTO> = {}) {
    this.id = options.id;
    this.nome = options.nome;
    this.email = options.email;
    this.login = options.login;
  }
}
