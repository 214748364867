import DefaultButton from "../../components/buttons/DefaultButton";
import AuthDataService from "../../data_services/auth/AuthDataService";
import LOGO_SYSTEM from "../../images/pronpsi_logo_horizontal_fundo_transparente.png";
import "./NotFound.css";

import { useHistory } from "react-router-dom";
import { AlertIcon, HomeIcon } from "../../components/icons/Icons";

export default function NotFound() {
  const history = useHistory();

  const navigateToHome = () => history.push("/home");
  return (
    <div>
      <div className="NotFound">
        <img alt="img-home" width={300} src={LOGO_SYSTEM} />
      </div>
      <div className="NotFound">
        <h3
          style={{
            color: "#24345a",
            fontFamily: "Open Sans Condensed",
          }}
        >
          Sistema de gestão de prontuários e pacientes
        </h3>
      </div>
      <div className="NotFound">
        <h1
          style={{
            color: "#647483",
            fontFamily: "Open Sans Condensed",
            fontSize: "60px",
          }}
        >
          <b style={{ fontSize: "50px" }}>
            <AlertIcon /> 404 - Página não encontrada
          </b>
        </h1>
      </div>
      {AuthDataService.isUserLoggedIn() && (
        <div className="NotFound">
          <DefaultButton
            icon={<HomeIcon />}
            buttonLabel={"Home"}
            messageToolTip={"Voltar para home."}
            width={200}
            height={40}
            click={() => navigateToHome()}
          />
        </div>
      )}
    </div>
  );
}
