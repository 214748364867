import moment from "moment";

export const PacienteListaExpandidaHeaderList = [
  {
    field: "dataSessao",
    header: "Data da sessão",
    body: getSessaoByStatusBaixado,
  },
  { field: "baixada", header: "Baixada", body: formatStatusBaixada },
  { field: "observacao", header: "Observação", body: getObservacao },
];

function getObservacao(rowData) {
  return <label>{rowData.observacao}</label>
}

function formatStatusBaixada(rowData) {
  if (rowData.baixada === true) {
    return <label>Sim</label>;
  } else {
    return <label>Não</label>;
  }
}

function getSessaoByStatusBaixado(rowData) {
  if (rowData.dataSessao === null) {
    return null;
  } else {
    const hoje = new Date();
    const dataSessao = new Date(rowData.dataSessao);
    
    hoje.setUTCHours(0, 0, 0, 0);
    dataSessao.setUTCHours(0, 0, 0, 0);
    if (rowData.baixada) {
      return (
        <label style={{ color: "green", fontWeight: "bold" }}>
          {formatDate(rowData.dataSessao)}
        </label>
      );
    }
    if (dataSessao < hoje && rowData.baixada === false) {
      return (
        <label style={{ color: "red", fontWeight: "bold" }}>
          {formatDate(rowData.dataSessao)}
        </label>
      );
    } else {
      return <label>{formatDate(rowData.dataSessao)}</label>;
    }
  }
}

function formatDate(date) {
  if (date !== null) {
    return moment(date).format("DD/MM/YYYY");
  } else {
    return "";
  }
}
