import { EspecialidadeDTO } from "./EspecialidadeDTO";
import { MedicoDTO } from "./MedicoDTO";
import { PacientePsicologoDTO } from "./PacientePsicologoDTO";
import { PacienteSessaoDTO } from "./PacienteSessaoDTO";
import { PsicologoDTO } from "./PsicologoDTO";

export class PacienteDTO {
  id?: number;
  paciente?: string;
  nrCarteirinha?: string;
  psicologo?: PsicologoDTO;
  inicioPlano?: Date;
  fimPlano?: Date;
  sexo?: string;
  dataNascimento?: Date;
  idade?: number;
  ativo?: boolean;
  medico?: MedicoDTO;
  sessaoList?: PacienteSessaoDTO[];
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  numero?: string;
  telefone?: string;
  especialidade?: EspecialidadeDTO;
  listaPsicologos?: PacientePsicologoDTO[];
  observacao?: string;
  emitirVpp?: boolean;

  constructor(options: Partial<PacienteDTO> = {}) {
    this.id = options.id;
    this.paciente = options.paciente;
    this.nrCarteirinha = options.nrCarteirinha;
    this.psicologo = options.psicologo;
    this.inicioPlano = options.inicioPlano;
    this.fimPlano = options.fimPlano;
    this.sexo = options.sexo;
    this.dataNascimento = options.dataNascimento;
    this.idade = options.idade;
    this.ativo = options.ativo;
    this.medico = options.medico;
    this.sessaoList = options.sessaoList;
    this.cep = options.cep;
    this.logradouro = options.logradouro;
    this.complemento = options.complemento;
    this.bairro = options.bairro;
    this.cidade = options.cidade;
    this.estado = options.estado;
    this.numero = options.numero;
    this.telefone = options.telefone;
    this.especialidade = options.especialidade;
    this.listaPsicologos = options.listaPsicologos;
    this.observacao = options.observacao;
    this.emitirVpp = options.emitirVpp;
  }
}
