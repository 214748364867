export class CriarEventoRequestDTO {
  tipoEventoId?: number;
  pacienteId?: number;
  nomeEvento?: string;
  observacao?: string;
  inicio?: Date;
  fim?: Date;
  replicaEventoNoMes?: boolean;

  constructor(options: Partial<CriarEventoRequestDTO> = {}) {
    this.tipoEventoId = options.tipoEventoId;
    this.pacienteId = options.pacienteId;
    this.nomeEvento = options.nomeEvento;
    this.observacao = options.observacao;
    this.inicio = options.inicio;
    this.fim = options.fim;
    this.replicaEventoNoMes = options.replicaEventoNoMes;
  }
}
