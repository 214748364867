export class MedicoRequestDTO {
  nome?: string;
  crm?: string;
  estado?: string;
  especialidadesIds?: number[];

  constructor(options: Partial<MedicoRequestDTO> = {}) {
    this.nome = options.nome;
    this.crm = options.crm;
    this.estado = options.estado;
    this.especialidadesIds = options.especialidadesIds;
  }
}
