import axios from "axios";
import { environment } from "../../environments/environment";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { ConsultaEnderecoRequestDTO } from "../../model/dto/request/ConsultaEnderecoRequestDTO";

class EnderecoDataService {
  env = environment.getEnvironment();
  ENDERECO_ENDPOINTS = `${this.env.BACKEND_API_URL}/consulta-endereco`;
  token = storageTokenConfig.getToken();

  getEnderecoByCep(cep: ConsultaEnderecoRequestDTO) {
    return axios.post(`${this.ENDERECO_ENDPOINTS}/cep`, cep, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const enderecoDataService = new EnderecoDataService();
export default enderecoDataService;
