import "./Usuario.css";
import {
  AtivarIcon,
  EditIcon,
  InativarIcon,
  NovoIcon,
  UsuarioIcon,
  ViewIcon,
} from "../../components/icons/Icons";
import { useCallback, useEffect, useState } from "react";
import Messages from "../../components/Messages";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import DefaultButton from "../../components/buttons/DefaultButton";
import AuthDataService from "../../data_services/auth/AuthDataService";
import CustomDataTable from "../../components/table/CustomDataTable";
import { UsuarioDTO } from "../../model/dto/UsuarioDTO";
import DialogCRUDUsuario from "./dialog/DialogCRUDUsuario";
import { UsuarioHeaderList } from "./header/UsuarioHeaderList";
import UsuarioService from "../../service/UsuarioService";
import PsicologoService from "../../service/PsicologoService";
import PerfilAcessoService from "../../service/PerfilAcessoService";
import {
  validarRequestBody,
  validarTrocaDeStatusMessage,
} from "../../validator/UsuarioValidator";
import {
  fecharModalUsuario,
  handleModalEditarUsuario,
  handleModalNovoUsuario,
  handleModalVisualizarUsuario,
} from "./dialog/DialogCRUDUsuarioUtils";
import UsuarioActionTemplate from "./UsuarioActionTemplate";
import { RadioButton } from "primereact/radiobutton";
import TipoExibicaoEnum from "../../model/enums/TipoExibicaoEnum";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";

export default function Usuario() {
  const [blockUi, setBlockUi] = useState(false);
  const [tipoExibicao, setTipoExibicao] = useState(TipoExibicaoEnum.TODOS);
  const [usuariosSemFiltro, setUsuariosSemFiltro] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [psicologos, setPsicologos] = useState([]);
  const [perfis, setPerfis] = useState([]);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(
    new UsuarioDTO()
  );
  const [totalUsuarios, setTotalUsuarios] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [isVisualizacao, setIsVisualizacao] = useState(false);
  const [isEdicao, setIsEdicao] = useState(false);

  const setUsuarioSelecionadoCallback = useCallback(
    function (u) {
      setUsuarioSelecionado(u);
    },
    [setUsuarioSelecionado]
  );

  useEffect(() => {
    carregarDados();
  }, []);

  async function alteraVisualizacao(value) {
    setTipoExibicao(value);
    switch (value) {
      case TipoExibicaoEnum.INATIVOS:
        await atualizaListaDeUsuarios(false);
        break;
      case TipoExibicaoEnum.ATIVOS:
        await atualizaListaDeUsuarios(true);
        break;
      default:
        await carregarDados();
        break;
    }
  }

  async function atualizaListaDeUsuarios(value) {
    await carregarDados();
    const usuariosFiltrados = usuariosSemFiltro.filter(
      (u) => u.ativo === value
    );
    setUsuarios(usuariosFiltrados);
    setTotalUsuarios(usuariosFiltrados.length);
  }

  async function carregarDados() {
    setBlockUi(true);
    const [usuariosData, psicologosData, perfisData] = await Promise.all([
      UsuarioService.getAll(),
      PsicologoService.getAll(),
      PerfilAcessoService.getAll(),
    ]);
    setUsuariosSemFiltro(usuariosData);
    setUsuarios(usuariosData);
    setTotalUsuarios(usuariosData.length);
    setPsicologos(psicologosData);
    setPerfis(perfisData);
    setBlockUi(false);
  }

  async function salvar(usuario) {
    if (!validarRequestBody(usuario, isEdicao)) {
      fecharModalUsuario(
        setIsEdicao,
        setDisplayModal,
        setUsuarioSelecionado,
        setIsVisualizacao
      );
      return;
    }
    try {
      setBlockUi(true);
      await UsuarioService.salvarDadosDoUsuario(usuario.id, usuario);
      if (isEdicao) {
        fecharModalUsuario(
          setIsEdicao,
          setDisplayModal,
          setUsuarioSelecionado,
          setIsVisualizacao
        );
        setBlockUi(false);
        Messages.successMessage("Sucesso", "Usuário editado com sucesso!");
        carregarDados();
      } else {
        fecharModalUsuario(
          setIsEdicao,
          setDisplayModal,
          setUsuarioSelecionado,
          setIsVisualizacao
        );
        setBlockUi(false);
        Messages.successMessage("Sucesso", "Usuário criado com sucesso!");
        carregarDados();
      }
    } catch (error) {
      fecharModalUsuario(
        setIsEdicao,
        setDisplayModal,
        setUsuarioSelecionado,
        setIsVisualizacao
      );
      setBlockUi(false);
      Messages.errorMessage("Erro", error.response.data.message);
    }
    setBlockUi(false);
  }

  async function trocarStatusUsuário(rowData) {
    const result = await validarTrocaDeStatusMessage(rowData);
    if (result.isConfirmed) {
      try {
        setBlockUi(true);
        await UsuarioService.trocarStatusUsuário(rowData);
        fecharModalUsuario(
          setIsEdicao,
          setDisplayModal,
          setUsuarioSelecionado,
          setIsVisualizacao
        );
        setBlockUi(false);
        Messages.successMessage("Sucesso", "Status alterado com sucesso");
        carregarDados();
      } catch (error) {
        fecharModalUsuario(
          setIsEdicao,
          setDisplayModal,
          setUsuarioSelecionado,
          setIsVisualizacao
        );
        setBlockUi(false);
        Messages.errorMessage("Erro", error.response.data.message);
      }
    }
    setBlockUi(false);
  }

  function fecharModal() {
    fecharModalUsuario(
      setIsEdicao,
      setDisplayModal,
      setUsuarioSelecionado,
      setIsVisualizacao
    );
  }

  function botaoVisualizarUsuario(rowData) {
    return (
      <DefaultButton
        fontSize={15}
        icon={<ViewIcon />}
        messageToolTip={"Visualizar usuário"}
        click={(_) =>
          handleModalVisualizarUsuario(
            rowData,
            setDisplayModal,
            setUsuarioSelecionado,
            setIsVisualizacao
          )
        }
        width={40}
        height={30}
      />
    );
  }

  function botaoEditarUsuario(rowData) {
    return (
      <DefaultButton
        fontSize={15}
        icon={<EditIcon />}
        messageToolTip={"Editar dados do usuário"}
        click={(_) =>
          handleModalEditarUsuario(
            rowData,
            setIsEdicao,
            setDisplayModal,
            setUsuarioSelecionado,
            setIsVisualizacao
          )
        }
        width={40}
        height={30}
      />
    );
  }

  function botaoTrocarStatusUsuario(rowData) {
    return (
      <DefaultButton
        fontSize={15}
        icon={rowData.ativo ? <InativarIcon /> : <AtivarIcon />}
        messageToolTip={`${
          rowData.ativo ? "Inativar usuário" : "Ativar usuário"
        }`}
        click={(_) => trocarStatusUsuário(rowData)}
        width={40}
        height={30}
      />
    );
  }

  function usuarioActionTemplate(rowData) {
    return (
      <UsuarioActionTemplate
        botaoVisualizarUsuario={() => botaoVisualizarUsuario(rowData)}
        botaoEditarUsuario={() => botaoEditarUsuario(rowData)}
        botaoTrocarStatusUsuario={() => botaoTrocarStatusUsuario(rowData)}
      />
    );
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div className="Usuario">
        <div className="Title">
          <span>
            <UsuarioIcon /> Usuários
          </span>
        </div>
        <div></div>
      </div>
      <div className="Body">
        {AuthDataService.hasPermission(FuncionalidadeEnum.CADASTRO_USUARIO) && (
          <div>
            <div className="NovoUsuario">
              <DefaultButton
                fontSize={12}
                icon={<NovoIcon />}
                buttonLabel={"Adicionar"}
                messageToolTip={"Cadastrar um novo usuário"}
                click={(_) =>
                  handleModalNovoUsuario(
                    setIsEdicao,
                    setDisplayModal,
                    setUsuarioSelecionado,
                    setIsVisualizacao
                  )
                }
                width={150}
                height={30}
              />{" "}
              <div style={{ marginLeft: 10 }}></div>
              <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="todos"
                    name="todos"
                    value={TipoExibicaoEnum.TODOS}
                    onChange={(e) => alteraVisualizacao(e.value)}
                    checked={tipoExibicao === TipoExibicaoEnum.TODOS}
                  />
                  <label htmlFor="todos" className="ml-2">
                    Todos
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="inativos"
                    name="inativos"
                    value={TipoExibicaoEnum.INATIVOS}
                    onChange={(e) => alteraVisualizacao(e.value)}
                    checked={tipoExibicao === TipoExibicaoEnum.INATIVOS}
                  />
                  <label htmlFor="inativos" className="ml-2">
                    Inativos
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ativos"
                    name="ativos"
                    value={TipoExibicaoEnum.ATIVOS}
                    onChange={(e) => alteraVisualizacao(e.value)}
                    checked={tipoExibicao === TipoExibicaoEnum.ATIVOS}
                  />
                  <label htmlFor="ativos" className="ml-2">
                    Ativos
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        <CustomDataTable
          values={usuarios}
          totalRecords={totalUsuarios}
          dataKey="id"
          headerList={UsuarioHeaderList}
          actionTemplate={usuarioActionTemplate}
          paginator={true}
          exibeActionTemplate={true}
        />
      </div>
      <DialogCRUDUsuario
        display={displayModal}
        esconder={fecharModal}
        usuario={usuarioSelecionado}
        setUsuario={setUsuarioSelecionadoCallback}
        isVisualizacao={isVisualizacao}
        salvar={salvar}
        psicologos={psicologos}
        perfis={perfis}
        isEdicao={isEdicao}
      />
    </BlockUI>
  );
}
