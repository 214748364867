import { MeuPacienteDTO } from "./MeuPacienteDTO";
import { PsicologoDTO } from "./PsicologoDTO";
import { QueixaInicialItemDTO } from "./QueixaInicialItemDTO";
import { TipoProntuarioDTO } from "./TipoProntuarioDTO";

export class RelatorioHistoricoAtendimentosDTO {
  nrProntuario?: string;
  tipoProntuario?: TipoProntuarioDTO;
  paciente?: MeuPacienteDTO;
  psicologo?: PsicologoDTO;
  isPrimeiraSessao?: boolean;
  isFinalizado?: boolean;
  dataHoraCriacao?: Date;
  dataHoraUltimaAtualizacao?: Date;
  itemsQueixaInicial?: QueixaInicialItemDTO[];
  registroAtendimento?: string;
  observacoes?: string;
  evolucao?: string;

  constructor(options: Partial<RelatorioHistoricoAtendimentosDTO> = {}) {
    this.nrProntuario = options.nrProntuario;
    this.tipoProntuario = options.tipoProntuario;
    this.paciente = options.paciente;
    this.psicologo = options.psicologo;
    this.isPrimeiraSessao = options.isPrimeiraSessao;
    this.isFinalizado = options.isFinalizado;
    this.dataHoraCriacao = options.dataHoraCriacao;
    this.dataHoraUltimaAtualizacao = options.dataHoraUltimaAtualizacao;
    this.itemsQueixaInicial = options.itemsQueixaInicial;
    this.registroAtendimento = options.registroAtendimento;
    this.observacoes = options.observacoes;
    this.evolucao = options.evolucao;
  }
}
