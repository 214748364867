export class TipoProntuarioDTO {
  id?: number;
  nome?: string;
  nomeExibicao?: string;
  ativo?: boolean;

  constructor(options: Partial<TipoProntuarioDTO> = {}) {
    this.id = options.id;
    this.nome = options.nome;
    this.nomeExibicao = options.nomeExibicao;
    this.ativo = options.ativo;
  }
}
