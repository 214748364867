import meusAtendimentosDataService from "../data_services/meus-atendimentos/MeusAtendimentosDataService";
import { ProntuarioDTO } from "../model/dto/ProntuarioDTO";
import { MeusAtendimentosFilteredRequestDTO } from "../model/dto/request/MeusAtendimentosFilteredRequestDTO";

class MeusAtendimentosService {
  static async getMeusAtendimentos(
    size?: number,
    page?: number,
    filters?: MeusAtendimentosFilteredRequestDTO
  ): Promise<ProntuarioDTO[]> {
    if (!filters) {
      filters = new MeusAtendimentosFilteredRequestDTO({});
    }
    if (!size) {
      size = 10;
    }

    if (!page) {
      page = 0;
    }

    const response = await meusAtendimentosDataService.getMeusAtendimentos(
      size,
      page,
      filters
    );
    const meusAtendimentos: ProntuarioDTO[] = response.data;
    return meusAtendimentos;
  }
}

export default MeusAtendimentosService;
