import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";
import DefaultButtonWithLink from "../../../components/buttons/DefaultButtonWithLink";
import {
  IniciarNovoAtendimentoIcon,
  ListaAtendimentosIcon,
  PacienteIcon,
  ViewIcon,
} from "../../../components/icons/Icons";

export default function DialogAcoesMeusPacientes({
  display,
  esconder,
  paciente,
  tituloModal,
  visualizaListaAtendimentos,
  hrefVisualizarAtendimentosPaciente,
  iniciaAtendimentoPaciente,
  visualizarCadastro,
}) {
  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<PacienteIcon />}{" "}
        {tituloModal !== undefined ? tituloModal : "Meu paciente"}
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Selecione uma ação">
          <Container>
            <Row>
              <Col>
                <DefaultButtonWithLink
                  fontSize={15}
                  icon={<ListaAtendimentosIcon />}
                  buttonLabel={"Lista de atendimentos"}
                  messageToolTip={
                    "Visualiza lista de atendimentos deste paciente"
                  }
                  click={(_) => {
                    visualizaListaAtendimentos(paciente);
                  }}
                  width={250}
                  height={40}
                  href={hrefVisualizarAtendimentosPaciente}
                />
              </Col>
              <Col>
                <DefaultButton
                  fontSize={15}
                  icon={<IniciarNovoAtendimentoIcon />}
                  buttonLabel={"Novo atendimento"}
                  messageToolTip={
                    "Inicia um novo atendimento para este paciente"
                  }
                  click={(_) => {
                    iniciaAtendimentoPaciente(paciente);
                  }}
                  width={250}
                  height={40}
                />
              </Col>
              <Col>
                <DefaultButton
                  fontSize={15}
                  icon={<ViewIcon />}
                  buttonLabel={"Visualizar cadastro"}
                  messageToolTip={"Visualiza dados do cadastro do paciente"}
                  click={(_) => {
                    visualizarCadastro(paciente);
                  }}
                  width={250}
                  height={40}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
      </Dialog>
    </div>
  );
}
