import message from "../components/Messages";
import { validate } from "./Validator";

export function validarRequestBodyCriacaoEvento(evento) {
  const camposValidar = [
    { name: "Tipo do evento", value: evento.tipoEventoAgenda },
    { name: "Nome", value: evento.nomeEvento },
    { name: "Início", value: evento.inicio },
    { name: "Fim", value: evento.fim },
  ];

  return validate(camposValidar);
}

export async function validarExclusaoEventoMessage(rowData) {
  return message.questionMessage(
    "Confirme a sua opção",
    `O evento ${rowData.nomeEvento} será excluído permanentemente. Tem certeza que deseja prosseguir?`,
    `Sim.`,
    "Não."
  );
}
