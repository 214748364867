const FuncionalidadeEnum = {
  CADASTRO_USUARIO: "CADASTRO_USUARIO",
  ATUALIZACAO_USUARIO: "ATUALIZACAO_USUARIO",
  INATIVACAO_USUARIO: "INATIVACAO_USUARIO",
  VISUALIZACAO_USUARIO: "VISUALIZACAO_USUARIO",
  CADASTRO_PSICOLOGO: "CADASTRO_PSICOLOGO",
  ATUALIZACAO_PSICOLOGO: "ATUALIZACAO_PSICOLOGO",
  INATIVACAO_PSICOLOGO: "INATIVACAO_PSICOLOGO",
  VISUALIZACAO_PSICOLOGO: "VISUALIZACAO_PSICOLOGO",
  CADASTRO_MEDICO: "CADASTRO_MEDICO",
  ATUALIZACAO_MEDICO: "ATUALIZACAO_MEDICO",
  INATIVACAO_MEDICO: "INATIVACAO_MEDICO",
  VISUALIZACAO_MEDICO: "VISUALIZACAO_MEDICO",
  CADASTRO_ESPECIALIDADE: "CADASTRO_ESPECIALIDADE",
  ATUALIZACAO_ESPECIALIDADE: "ATUALIZACAO_ESPECIALIDADE",
  VISUALIZACAO_ESPECIALIDADE: "VISUALIZACAO_ESPECIALIDADE",
  REMOCAO_ESPECIALIDADE: "REMOCAO_ESPECIALIDADE",
  CADASTRO_PERFIL: "CADASTRO_PERFIL",
  ATUALIZACAO_PERFIL: "ATUALIZACAO_PERFIL",
  REMOCAO_PERFIL: "REMOCAO_PERFIL",
  CADASTRO_PACIENTE: "CADASTRO_PACIENTE",
  ATUALIZACAO_PACIENTE: "ATUALIZACAO_PACIENTE",
  INATIVACAO_PACIENTE: "INATIVACAO_PACIENTE",
  VISUALIZACAO_PACIENTE: "VISUALIZACAO_PACIENTE",
  CADASTRO_TEMPLATE_PRONTUARIO: "CADASTRO_TEMPLATE_PRONTUARIO",
  ATUALIZACAO_TEMPLATE_PRONTUARIO: "ATUALIZACAO_TEMPLATE_PRONTUARIO",
  VISUALIZACAO_TEMPLATE_PRONTUARIO: "VISUALIZACAO_TEMPLATE_PRONTUARIO",
  CADASTRO_PRONTUARIO: "CADASTRO_PRONTUARIO",
  ATUALIZACAO_PRONTUARIO: "ATUALIZACAO_PRONTUARIO",
  VISUALIZACAO_PRONTUARIO: "VISUALIZACAO_PRONTUARIO",
  INATIVACAO_PRONTUARIO: "INATIVACAO_PRONTUARIO",
  VISUALIZAR_MEUS_PACIENTES: "VISUALIZAR_MEUS_PACIENTES",
  VISUALIZAR_MEUS_ATENDIMENTOS: "VISUALIZAR_MEUS_ATENDIMENTOS",
  VISUALIZAR_RELATORIOS: "VISUALIZAR_RELATORIOS",
  VISUALIZACAO_AGENDA: "VISUALIZACAO_AGENDA",
	VISUALIZACAO_AGENDA_GERAL: "VISUALIZACAO_AGENDA_GERAL",
	CRIACAO_EVENTO_AGENDA: "CRIACAO_EVENTO_AGENDA",
	REMOCAO_EVENTO_AGENDA: "REMOCAO_EVENTO_AGENDA",
	CANCELAMENTO_EVENTO_AGENDA: "CANCELAMENTO_EVENTO_AGENDA"
};

export default FuncionalidadeEnum;
