import Messages from "../components/Messages";
import { validate } from "./Validator";

export function validarRequestBodyMedico(medico) {
  const camposValidar = [
    { name: "Nome", value: medico.nome },
    { name: "CRM", value: medico.crm }
  ];
  return validate(camposValidar);
}

export async function validarTrocaDeStatusMedicoMessage(rowData) {
    return Messages.questionMessage(
      "Confirme a sua opção",
      `O(A) médico(a) ${rowData.nome} será ${
        rowData.ativo ? "inativado" : "ativado"
      }`,
      `Sim. ${rowData.ativo ? "Inativar" : "Ativar"}`,
      "Não."
    );
  }
  