import PropTypes from "prop-types";
import AuthDataService from "../../data_services/auth/AuthDataService";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import "./Medico.css";

export default function MedicoActionTemplate(props) {
  const { botaoVisualizarMedico, botaoEditarMedico, botaoTrocarStatusMedico } =
    props;

  return (
    <div className="ActionItens">
      {AuthDataService.hasPermission(
        FuncionalidadeEnum.VISUALIZACAO_MEDICO
      ) && <div>{botaoVisualizarMedico()}</div>}
      {AuthDataService.hasPermission(
        FuncionalidadeEnum.ATUALIZACAO_MEDICO
      ) && <div>{botaoEditarMedico()}</div>}
      {AuthDataService.hasPermission(
        FuncionalidadeEnum.INATIVACAO_MEDICO
      ) && <div>{botaoTrocarStatusMedico()}</div>}
    </div>
  );
}

MedicoActionTemplate.propTypes = {
  botaoVisualizarMedico: PropTypes.any,
  botaoEditarMedico: PropTypes.any,
  botaoTrocarStatusMedico: PropTypes.any,
};
