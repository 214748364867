import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import If from "../../components/If";
import Messages from "../../components/Messages";
import { HomeIcon } from "../../components/icons/Icons";
import AuthDataService from "../../data_services/auth/AuthDataService";
import LOGO_SYSTEM from "../../images/pronpsi_logo_horizontal_fundo_transparente.png";
import "./Home.css";
import HomeDashboard from "../dashboard/HomeDashboard";

export default function Home() {
  const userLogged = JSON.parse(secureLocalStorage.getItem("userSession"));

  useEffect(() => {
    if (AuthDataService.verifyExpiredToken()) {
      Messages.errorMessage("Fim de sessão", "Sessão expirada!").then(
        function () {
          AuthDataService.doLogout();
        }
      );
    }
  }, []);

  function getMensagemBoasVindasPsicologo() {
    if (userLogged.isPsicologo) {
      if (userLogged.psicologo.sexo === "M") {
        return `Bem vindo Dr. ${userLogged.psicologo.nome.split(" ")[0]}`;
      } else if (userLogged.psicologo.sexo === "F") {
        return `Bem vinda Dra. ${userLogged.psicologo.nome.split(" ")[0]}`;
      } else {
        return `Bem vindo ${userLogged.nome.split(" ")[0]}`;
      }
    }

    return `Bem vindo ${userLogged.nome.split(" ")[0]}`;
  }

  return (
    <div>
      <div className="Home">
        <div className="Title">
          <span>
            <HomeIcon /> {getMensagemBoasVindasPsicologo()}
          </span>
        </div>
      </div>
      <div className="Home"></div>
      <If condition={userLogged !== null && !userLogged.isPsicologo}>
        <div
          style={{ marginTop: 170, display: "flex", justifyContent: "center" }}
        >
          <img
            src={LOGO_SYSTEM}
            width={500}
            alt=""
            style={{ display: "block" }}
          />
        </div>
      </If>
      <If condition={userLogged !== null && userLogged.isPsicologo}>
        <HomeDashboard />
      </If>
    </div>
  );
}
