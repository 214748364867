import axios from "axios";
import { environment } from "../../environments/environment";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { PsicologoRequestDTO } from "../../model/dto/request/PsicologoRequestDTO";
import { AtivarInativarPsicologoRequestDTO } from "../../model/dto/request/AtivarInativarPsicologoRequestDTO";

class PsicologoDataService {
  env = environment.getEnvironment();
  PSICOLOGO_ENDPOINTS = `${this.env.BACKEND_API_URL}/psicologo`;
  token = storageTokenConfig.getToken();

  criarPsicologo(psicologo: PsicologoRequestDTO) {
    return axios.post(`${this.PSICOLOGO_ENDPOINTS}`, psicologo, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  atualizarPsicologo(psicologoId: number, psicologo: PsicologoRequestDTO) {
    return axios.put(`${this.PSICOLOGO_ENDPOINTS}/${psicologoId}`, psicologo, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  ativarInativarPsicologo(
    ativarInativarPsicologoRequest: AtivarInativarPsicologoRequestDTO
  ) {
    return axios.patch(
      `${this.PSICOLOGO_ENDPOINTS}/`,
      ativarInativarPsicologoRequest,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  getAll() {
    return axios.get(`${this.PSICOLOGO_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const psicologoDataService = new PsicologoDataService();
export default psicologoDataService;
