import ProntuarioLabel from "./ProntuarioLabel";

export default function ProntuarioItensQueixaInicialList({
  itemsQueixaInicial,
}) {
  return (
    <div style={{ textAlign: "left" }}>
      <ProntuarioLabel
        titulo={"Queixa: "}
        valor={
          itemsQueixaInicial.length > 0
            ? itemsQueixaInicial.map((item) => item.nome).join(", ")
            : ""
        }
      />
    </div>
  );
}
