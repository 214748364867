import PropTypes from "prop-types";
import "./style/ProntuarioLabel.css";
export default function ProntuarioLabel({ titulo, valor, size, underlineTitle }) {
  return (
    <div className="ProntuarioLabel">
      <label style={size !== undefined ? { fontSize: size } : { fontSize: 13 }}>
        {underlineTitle ? <b><u>{titulo}</u></b> :  <b>{titulo}</b>}
        {valor !== undefined ? valor : ""}
      </label>
    </div>
  );
}

ProntuarioLabel.propTypes = {
  titulo: PropTypes.string,
  valor: PropTypes.string,
  size: PropTypes.number,
};
