import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { useCallback, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import message from "../../../components/Messages";
import DefaultButton from "../../../components/buttons/DefaultButton";
import { FiltrarIcon, LimparIcon } from "../../../components/icons/Icons";
import PrintButton from "../../../components/print/PrintButton";
import PrintProntuario from "../../../components/print/prontuario/PrintProntuario";
import CustomPaginateDataTable from "../../../components/table/CustomPaginateDataTable";
import { PacienteDTO } from "../../../model/dto/PacienteDTO";
import { PsicologoDTO } from "../../../model/dto/PsicologoDTO";
import { RelatorioHistoricoAtendimentosDTO } from "../../../model/dto/RelatorioHistoricoAtendimentosDTO";
import { TipoProntuarioDTO } from "../../../model/dto/TipoProntuarioDTO";
import { RelatorioHistoricoAtendimentosFilteredRequestDTO } from "../../../model/dto/request/RelatorioHistoricoAtendimentosFilteredRequestDTO";
import RelatorioService from "../../../service/RelatorioService";
import { exportRelatorioHistoricoAtendimentos } from "../../../utils/excel/ExportRelatorioHistoricoAtendimentoUtils";
import "./RelatorioHistoricoAtendimentos.css";
import { RelatorioHistoricoAtendimentosHeader } from "./RelatorioHistoricoAtendimentosHeader";

export default function RelatorioHistoricoAtendimentos({
  tiposProntuario,
  pacientes,
  psicologos,
}) {
  const imprimirRef = useRef();
  const [blockUi, setBlockUi] = useState(false);
  const [dados, setDados] = useState([]);
  const [totalDados, setTotalDados] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(10);
  const [pagina, setPagina] = useState(0);
  const [atendimentoSelecionado, setAtendimentoSelecionado] = useState(
    new RelatorioHistoricoAtendimentosDTO()
  );

  const [filtros, setFiltros] = useState(
    new RelatorioHistoricoAtendimentosFilteredRequestDTO()
  );

  const [filtroPsicologoSelecionado, setFiltroPsicologoSelecionado] = useState(
    new PsicologoDTO()
  );
  const [filtroPacienteSelecionado, setFiltroPacienteSelecionado] = useState(
    new PacienteDTO()
  );
  const [filtroTipoProntuarioSelecionado, setFiltroTipoProntuarioSelecionado] =
    useState(new TipoProntuarioDTO());

  const setLinhasPorPaginaCallback = useCallback(
    function (l) {
      setLinhasPorPagina(l);
    },
    [setLinhasPorPagina]
  );

  const setPaginaCallback = useCallback(
    function (p) {
      setPagina(p);
    },
    [setPagina]
  );

  async function filtrarRelatorio(size, page) {
    setBlockUi(true);
    RelatorioService.getRelatorioHistoricoAtendimentos(
      size,
      page,
      filtros
    ).then((response) => {
      if (response.content.length === 0) {
        message.errorMessage(
          "Atenção!",
          "Não existem registros de acordo com os filtros informados!"
        );
        setBlockUi(false);
        limpar();
      } else {
        setDados(response.content);
        setTotalDados(response.totalElements);
        setBlockUi(false);
      }
    });
    setBlockUi(false);
  }

  function limpar() {
    setFiltros(new RelatorioHistoricoAtendimentosFilteredRequestDTO());
    setFiltroPacienteSelecionado(new PacienteDTO());
    setFiltroPsicologoSelecionado(new PsicologoDTO());
    setFiltroTipoProntuarioSelecionado(new TipoProntuarioDTO());
    setLinhasPorPagina(10);
    setPagina(0);
    setDados([]);
    setTotalDados(0);
  }

  function handlePrintCallBackFunction(rowData) {
    setAtendimentoSelecionado(rowData);
    return true;
  }

  function actionTemplate(rowData) {
    return (
      <PrintButton
        contentRef={imprimirRef}
        messageToolTip={"Imprimir este prontuário"}
        titulo={
          rowData !== undefined && rowData.paciente !== undefined
            ? `${
                rowData.nrProntuario
              }_${rowData.paciente.paciente.toUpperCase()}`
            : ""
        }
        callbackFunction={handlePrintCallBackFunction}
        rowData={rowData}
        width={40}
        height={30}
      />
    );
  }
  return (
    <>
      <BlockUI
        width={"100%"}
        template={
          <ProgressBar
            strokeWidth="8"
            className="blue-spinner"
            style={{ width: "40px" }}
          />
        }
        blocked={blockUi}
      >
        <div className="RelatorioHistoricoAtendimentos">
          <h4>Relatório de histórico de atendimentos</h4>
          <Container>
            <Row>
              <Col>
                <label htmlFor="nrProntuarioFilter">
                  Nº prontuário: &nbsp;&nbsp;
                </label>
                <InputText
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    filtros?.nrProntuario !== undefined
                      ? filtros.nrProntuario
                      : ""
                  }
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      nrProntuario: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <label htmlFor="tipoProntuarioFilter">
                  Tipo atendimento: &nbsp;&nbsp;
                </label>
                <Dropdown
                  filter
                  className="FiltroTipoProntuario"
                  placeholder="Selecione um tipo de atendimento"
                  name="tipoProntuarioFilter"
                  id="tipoProntuarioFilter"
                  style={{
                    width: "100%",
                    height: "50%",
                    background: "#7988ac",
                  }}
                  value={
                    filtroTipoProntuarioSelecionado?.id !== undefined
                      ? filtroTipoProntuarioSelecionado
                      : {}
                  }
                  options={tiposProntuario}
                  optionLabel="nome"
                  emptyMessage="Não há tipos de atendimento"
                  onChange={(e) => {
                    setFiltroTipoProntuarioSelecionado(e.target.value);
                    setFiltros({
                      ...filtros,
                      tipoProntuarioId: e.target.value.id,
                    });
                  }}
                />
              </Col>
              <Col>
                <label htmlFor="pacienteFilter">Paciente: &nbsp;&nbsp;</label>
                <Dropdown
                  filter
                  className="FiltroPaciente"
                  placeholder="Selecione um paciente"
                  name="pacienteFilter"
                  id="pacienteFilter"
                  style={{
                    width: "100%",
                    height: "50%",
                    background: "#7988ac",
                  }}
                  value={
                    filtroPacienteSelecionado?.id !== undefined
                      ? filtroPacienteSelecionado
                      : {}
                  }
                  options={pacientes}
                  optionLabel="paciente"
                  emptyMessage="Não há pacientes"
                  onChange={(e) => {
                    setFiltroPacienteSelecionado(e.target.value);
                    setFiltros({
                      ...filtros,
                      pacienteId: e.target.value.id,
                    });
                  }}
                />
              </Col>
              <Col>
                <label htmlFor="FiltroPsicologo">Psicólogo: &nbsp;&nbsp;</label>
                <Dropdown
                  filter
                  className="FiltroPsicologo"
                  placeholder="Selecione um psicólogo"
                  name="psicologoFilter"
                  id="psicologoFilter"
                  style={{
                    width: "100%",
                    height: "50%",
                    background: "#7988ac",
                  }}
                  value={
                    filtroPsicologoSelecionado?.id !== undefined
                      ? filtroPsicologoSelecionado
                      : {}
                  }
                  options={psicologos}
                  optionLabel="nome"
                  emptyMessage="Não há psicólogos"
                  onChange={(e) => {
                    setFiltroPsicologoSelecionado(e.target.value);
                    setFiltros({
                      ...filtros,
                      psicologoId: e.target.value.id,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="dataInicioVinculoFilter">
                  Data criação prontuário (de): &nbsp;&nbsp;
                </label>
                <InputText
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    filtros?.dataAtendimentoInicial !== undefined
                      ? filtros.dataAtendimentoInicial
                      : ""
                  }
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      dataAtendimentoInicial: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <label htmlFor="dataFimVinculoFilter">
                  Data criação prontuário (até): &nbsp;&nbsp;
                </label>
                <InputText
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    filtros?.dataAtendimentoFinal !== undefined
                      ? filtros.dataAtendimentoFinal
                      : ""
                  }
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      dataAtendimentoFinal: e.target.value,
                    })
                  }
                />
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
            <div className="FilterRelatorioHistoricoAtendimentos">
              <DefaultButton
                fontSize={12}
                icon={<FiltrarIcon />}
                buttonLabel={"Buscar"}
                messageToolTip={
                  "Busca os resultados de acordo com os parâmetros"
                }
                click={(_) => {
                  filtrarRelatorio(linhasPorPagina, pagina);
                }}
                width={150}
                height={30}
              />{" "}
              <DefaultButton
                fontSize={12}
                icon={<LimparIcon />}
                buttonLabel={"Limpar"}
                messageToolTip={"Limpa os filtros e os dados da tabela"}
                click={limpar}
                width={150}
                height={30}
              />{" "}
            </div>
          </Container>
          {totalDados > 0 && (
            <CustomPaginateDataTable
              exportar={true}
              dadosParaExportacao={RelatorioService.montaObjetoParaExportacaoRelatorioHistoricoAtendimentos(
                dados
              )}
              exportFunction={exportRelatorioHistoricoAtendimentos}
              values={dados}
              totalRecords={totalDados}
              dataKey="id"
              headerList={RelatorioHistoricoAtendimentosHeader}
              actionTemplate={actionTemplate}
              paginator={true}
              exibeActionTemplate={true}
              callbackFunction={filtrarRelatorio}
              linhasPorPagina={linhasPorPagina}
              setLinhasPorPagina={setLinhasPorPaginaCallback}
              setPagina={setPaginaCallback}
              fieldToSort={"dataHoraCriacao"}
              showInLeft={true}
            />
          )}
        </div>
        <PrintProntuario
          ref={imprimirRef}
          dadosProntuario={atendimentoSelecionado}
        />
      </BlockUI>
    </>
  );
}
