import { EspecialidadeDTO } from "./EspecialidadeDTO";
import { MedicoDTO } from "./MedicoDTO";
import { PacientePsicologoDTO } from "./PacientePsicologoDTO";

export class MeuPacienteDTO {
  id?: number;
  paciente?: string;
  nrCarteirinha?: string;
  inicioPlano?: Date;
  fimPlano?: Date;
  sexo?: string;
  dataNascimento?: Date;
  idade?: number;
  ativo?: boolean;
  medico?: MedicoDTO;
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  numero?: string;
  telefone?: string;
  especialidade?: EspecialidadeDTO;
  listaPsicologos?: PacientePsicologoDTO[];

  constructor(options: Partial<MeuPacienteDTO> = {}) {
    this.id = options.id;
    this.paciente = options.paciente;
    this.nrCarteirinha = options.nrCarteirinha;
    this.inicioPlano = options.inicioPlano;
    this.fimPlano = options.fimPlano;
    this.sexo = options.sexo;
    this.dataNascimento = options.dataNascimento;
    this.idade = options.idade;
    this.ativo = options.ativo;
    this.medico = options.medico;
    this.cep = options.cep;
    this.logradouro = options.logradouro;
    this.complemento = options.complemento;
    this.bairro = options.bairro;
    this.cidade = options.cidade;
    this.estado = options.estado;
    this.numero = options.numero;
    this.telefone = options.telefone;
    this.especialidade = options.especialidade;
    this.listaPsicologos = options.listaPsicologos;
  }
}
