import { PacienteSessaoRequestDTO } from "./PacienteSessaoRequestDTO";

export class PacienteRequestDTO {
  paciente?: string;
  nrCarteirinha?: string;
  psicologoId?: number;
  inicioPlano?: Date;
  fimPlano?: Date;
  sexo?: string;
  dataNascimento?: Date;
  idade?: number;
  medicoId?: number;
  sessoes?: PacienteSessaoRequestDTO[];
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  numero?: string;
  telefone?: string;
  especialidadeId?: number;
  observacao?: string;
  emitirVpp?: boolean;

  constructor(options: Partial<PacienteRequestDTO> = {}) {
    this.paciente = options.paciente;
    this.nrCarteirinha = options.nrCarteirinha;
    this.psicologoId = options.psicologoId;
    this.inicioPlano = options.inicioPlano;
    this.fimPlano = options.fimPlano;
    this.sexo = options.sexo;
    this.dataNascimento = options.dataNascimento;
    this.idade = options.idade;
    this.medicoId = options.medicoId;
    this.sessoes = options.sessoes;
    this.cep = options.cep;
    this.logradouro = options.logradouro;
    this.complemento = options.complemento;
    this.bairro = options.bairro;
    this.cidade = options.cidade;
    this.estado = options.estado;
    this.numero = options.numero;
    this.telefone = options.telefone;
    this.especialidadeId = options.especialidadeId;
    this.observacao = options.observacao;
    this.emitirVpp = options.emitirVpp;
  }
}
