import SecureStorage from "react-secure-storage";

const secureStorage = SecureStorage;
const TOKEN = "token";

export const storageTokenConfig = {
  getToken: (): any => {
    const token: any = secureStorage.getItem(TOKEN);
    const tokenObject = JSON.parse(token);
    if (tokenObject !== null) {
      return tokenObject.token;
    } else {
      return "";
    }
  },

  setToken: (token: string): void => {
    const usertToken = { token };
    secureStorage.setItem(TOKEN, JSON.stringify(usertToken));
  },

  removeToken: (): void => {
    secureStorage.removeItem(TOKEN);
  },
};
