import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";
import {
  AdicionarIcon,
  AtivarIcon,
  EditIcon,
  InativarIcon,
  PacienteIcon,
  ViewIcon,
} from "../../../components/icons/Icons";

export default function DialogAcoesPaciente({
  display,
  esconder,
  paciente,
  tituloModal,
  visualizaPaciente,
  editaPaciente,
  trocarStatusPaciente,
  adicionaSessao,
}) {
  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<PacienteIcon />}{" "}
        {tituloModal !== undefined
          ? tituloModal
          : "Cadastro e gerenciamento de pacientes"}
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Selecione uma ação">
          <Container>
            <Row>
              <Col>
                <DefaultButton
                  fontSize={15}
                  icon={<ViewIcon />}
                  buttonLabel={"Visualizar paciente"}
                  messageToolTip={"Visualiza dados do paciente"}
                  click={(_) => {
                    visualizaPaciente(paciente);
                  }}
                  width={250}
                  height={40}
                />
              </Col>
              <Col>
                <DefaultButton
                  fontSize={15}
                  icon={<EditIcon />}
                  buttonLabel={"Editar paciente"}
                  messageToolTip={"Edita os dados do paciente"}
                  click={(_) => {
                    editaPaciente(paciente);
                  }}
                  width={250}
                  height={40}
                />
              </Col>
              <Col>
                <DefaultButton
                  fontSize={15}
                  icon={paciente.ativo ? <InativarIcon /> : <AtivarIcon />}
                  buttonLabel={`${
                    paciente.ativo ? "Inativar paciente" : "Ativar paciente"
                  }`}
                  messageToolTip={"Troca o status do paciente"}
                  click={(_) => trocarStatusPaciente(paciente)}
                  width={250}
                  height={40}
                />
              </Col>
              <Col>
                <DefaultButton
                  fontSize={15}
                  icon={<AdicionarIcon />}
                  buttonLabel={"Adicionar sessão"}
                  messageToolTip={"Adiciona sessões no paciente"}
                  click={(_) => {
                    adicionaSessao(paciente);
                  }}
                  width={250}
                  height={40}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
      </Dialog>
    </div>
  );
}
