import moment from "moment";

export function formatDataHora(data) {
  return moment(data).format("DD/MM/YYYY HH:mm:ss");
}

export function formatData(data) {
  return moment(data).format("DD/MM/YYYY");
}

export function formatDataIngles(data) {
  return new Date(data);
}

export function calcularIdade(dataNascimento) {
  const hoje = new Date();
  const dataNasc = new Date(dataNascimento);
  let idade = hoje.getFullYear() - dataNasc.getFullYear();
  if (
    hoje.getMonth() < dataNasc.getMonth() ||
    (hoje.getMonth() === dataNasc.getMonth() &&
      hoje.getDate() < dataNasc.getDate())
  ) {
    idade--;
  }

  return idade.toString();
}
