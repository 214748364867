/* eslint-disable react-hooks/exhaustive-deps */
import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  default as Messages,
  default as message,
} from "../../components/Messages";
import DefaultButton from "../../components/buttons/DefaultButton";
import {
  FiltrarIcon,
  LimparIcon,
  NovoIcon,
  PacienteIcon,
} from "../../components/icons/Icons";
import CustomPaginateDataTable from "../../components/table/CustomPaginateDataTable";
import AuthDataService from "../../data_services/auth/AuthDataService";
import { PacienteDTO } from "../../model/dto/PacienteDTO";
import { PsicologoDTO } from "../../model/dto/PsicologoDTO";
import { PacienteFiltrosRequestDTO } from "../../model/dto/request/PacienteFiltrosRequestDTO";
import FuncionalidadeEnum from "../../model/enums/FuncionalidadeEnum";
import MedicoService from "../../service/MedicoService";
import PacienteService from "../../service/PacienteService";
import PsicologoService from "../../service/PsicologoService";
import { exportExcel } from "../../utils/excel/ExportPacienteExcelUtils";
import {
  validarRequestBodyPaciente,
  validarTrocaDeStatusPacienteMessage,
} from "../../validator/PacienteValidator";
import "./Paciente.css";
import DialogAcoesPaciente from "./dialog/DialogAcoesPaciente";
import DialogAdicionarSessaoPaciente from "./dialog/DialogAdicionarSessoesPaciente";
import {
  fecharModalAdicionarSessao,
  handleModalAdicionarSessao,
} from "./dialog/DialogAdicionarSessoesPacienteUtils";
import DialogCRUDPaciente from "./dialog/DialogCRUDPaciente";
import {
  fecharModalPaciente,
  handleModalEditarPaciente,
  handleModalNovoPaciente,
  handleModalVisualizarPaciente,
} from "./dialog/DialogCRUDPacienteUtils";
import { PacienteHeaderList } from "./header/PacienteHeaderList";
import { PacienteListaExpandidaHeaderList } from "./header/PacienteListaExpandidaHeaderList";

export default function Paciente() {
  const [blockUi, setBlockUi] = useState(false);
  const [linhasPorPagina, setLinhasPorPagina] = useState(10);
  const [pagina, setPagina] = useState(0);
  const setPaginaCallback = useCallback(
    function (p) {
      setPagina(p);
    },
    [setPagina]
  );

  const setLinhasPorPaginaCallback = useCallback(
    function (l) {
      setLinhasPorPagina(l);
    },
    [setLinhasPorPagina]
  );

  const status = [
    { nome: "Todos", valor: null },
    { nome: "Ativos", valor: true },
    { nome: "Inativos", valor: false },
  ];
  const emitirVppStatus = [
    { nome: "N/A", valor: null },
    { nome: "Sim", valor: true },
    { nome: "Não", valor: false },
  ];
  const statusSessoes = [
    { nome: "Todos", valor: null },
    { nome: "Baixadas", valor: true },
    { nome: "Não baixadas", valor: false },
  ];
  const [pacientes, setPacientes] = useState([]);
  const [psicologos, setPsicologos] = useState([]);
  const [medicos, setMedicos] = useState([]);
  const [pacienteSelecionado, setPacienteSelecionado] = useState(
    new PacienteDTO()
  );
  const [totalPacientes, setTotalPacientes] = useState(0);
  const [sessoesPaciente, setSessoesPaciente] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalAdicionarParcela, setDisplayModalAdicionarParcela] =
    useState(false);
  const [displayModalAcoes, setDisplayModalAcoes] = useState(false);
  const [isVisualizacao, setIsVisualizacao] = useState(false);
  const [isEdicao, setIsEdicao] = useState(false);

  const [filtros, setFiltros] = useState(new PacienteFiltrosRequestDTO());
  const [filtroPsicologoSelecionado, setFiltroPsicologoSelecionado] = useState(
    new PsicologoDTO()
  );
  const [filtroAtivoSelecionado, setFiltroAtivoSelecionado] = useState(null);
  const [filtroEmitirVpp, setFiltroEmitirVpp] = useState(null);
  const [quantidadePacientesPsicologo, setQuantidadePacientesPsicologo] =
    useState(null);
  const [filtroExibeSessoesBaixadas, setFiltroExibeSessoesBaixadas] =
    useState(null);
  const [visualizaHistorico, setVisualizaHistorico] = useState(false);

  const setPacienteSelecionadoCallback = useCallback(
    function (p) {
      setPacienteSelecionado(p);
    },
    [setPacienteSelecionado]
  );

  useEffect(() => {
    carregarDados();
  }, []);

  async function carregarDados() {
    setBlockUi(true);
    const [pacientesData, psicologosData, medicosData] = await Promise.all([
      PacienteService.getAllPacientesPaginate(10, pagina, null),
      PsicologoService.getAll(),
      MedicoService.getAll(),
    ]);
    setPacientes(pacientesData.content);
    setTotalPacientes(pacientesData.totalElements);
    setPsicologos(psicologosData);
    setMedicos(medicosData);
    setBlockUi(false);
  }

  async function filtrarPacientes(size, page) {
    setBlockUi(true);
    PacienteService.getAllPacientesPaginate(size, page, filtros)
      .then((response) => {
        if (response.content.length === 0) {
          message.errorMessage(
            "Atenção!",
            "Não existem registros de acordo com os filtros informados!"
          );
          setBlockUi(false);
          limpar();
        } else {
          setPacientes(response.content);
          setTotalPacientes(response.totalElements);
          setQuantidadePacientesPsicologo(response.totalElements);
          setBlockUi(false);
        }
      })
      .catch((error) => {
        message.errorMessage("Ops ...", error.response.data.message);
        setBlockUi(false);
      });
  }

  async function salvar(paciente) {
    if (!validarRequestBodyPaciente(paciente, isEdicao)) {
      fecharModal();
      return;
    }
    try {
      setBlockUi(true);
      await PacienteService.salvarDadosPaciente(paciente.id, paciente);
      if (isEdicao) {
        fecharModal();
        Messages.successMessage("Sucesso", "Paciente editado com sucesso!");
        if (
          filtroPsicologoSelecionado.id !== undefined ||
          filtroEmitirVpp !== null ||
          filtroAtivoSelecionado !== null ||
          filtroExibeSessoesBaixadas !== null
        ) {
          filtrarPacientes(linhasPorPagina, pagina);
        } else {
          carregarDados();
        }
      } else {
        fecharModal();
        Messages.successMessage("Sucesso", "Paciente criado com sucesso!");
        if (
          filtroPsicologoSelecionado.id !== undefined ||
          filtroEmitirVpp !== null ||
          filtroAtivoSelecionado !== null ||
          filtroExibeSessoesBaixadas !== null
        ) {
          filtrarPacientes();
        } else {
          carregarDados();
        }
      }
    } catch (error) {
      fecharModal();
      Messages.errorMessage("Erro", error.response.data.message);
    }
    setBlockUi(false);
  }

  async function trocarStatusPaciente(rowData) {
    setDisplayModalAcoes(false);
    const result = await validarTrocaDeStatusPacienteMessage(rowData);
    if (result.isConfirmed) {
      try {
        setBlockUi(true);
        await PacienteService.trocarStatusPaciente(rowData);
        fecharModal();
        Messages.successMessage("Sucesso", "Status alterado com sucesso");
        carregarDados();
        setBlockUi(false);
      } catch (error) {
        setBlockUi(false);
        fecharModal();
        Messages.errorMessage("Erro", error.response.data.message);
      }
    }
    setBlockUi(false);
  }

  async function salvarSessoes(sessoes) {
    setBlockUi(true);
    try {
      await PacienteService.adicionarSessoes(pacienteSelecionado.id, sessoes);
      fecharModalSessao();
      Messages.successMessage("Sucesso!", "Sessões adicionadas com sucesso!");
      carregarDados();
    } catch (error) {
      fecharModalSessao();
      Messages.errorMessage("Erro", error.response.data.message);
    }
    setBlockUi(false);
  }

  function fecharModal() {
    fecharModalPaciente(
      setIsEdicao,
      setDisplayModal,
      setPacienteSelecionado,
      setIsVisualizacao
    );
  }

  function fecharModalAcoes() {
    setDisplayModalAcoes(false);
  }

  function visualizaPaciente(rowData) {
    fecharModalAcoes();
    handleModalVisualizarPaciente(
      rowData,
      setDisplayModal,
      setPacienteSelecionado,
      setIsVisualizacao,
      setVisualizaHistorico
    );
  }

  function editaPaciente(rowData) {
    fecharModalAcoes();
    handleModalEditarPaciente(
      rowData,
      setIsEdicao,
      setDisplayModal,
      setPacienteSelecionado,
      setIsVisualizacao,
      setVisualizaHistorico
    );
  }

  function adicionaSessao(rowData) {
    fecharModalAcoes();
    handleModalAdicionarSessao(
      setDisplayModalAdicionarParcela,
      rowData?.sessaoList.length > 0
        ? rowData.sessaoList
        : [
            {
              id: null,
              dataSessao: new Date(),
              baixada: false,
              observacao: null,
            },
          ],
      setSessoesPaciente,
      rowData,
      setPacienteSelecionado
    );
  }

  function fecharModalSessao() {
    fecharModalAdicionarSessao(
      setDisplayModalAdicionarParcela,
      setPacienteSelecionado,
      setSessoesPaciente
    );
  }

  function onClickLinhaPaciente(rowData) {
    setPacienteSelecionado(rowData.value);
    setDisplayModalAcoes(true);
  }

  function limpar() {
    setLinhasPorPagina(10);
    setPagina(0);
    setFiltros(new PacienteFiltrosRequestDTO());
    setFiltroPsicologoSelecionado(new PsicologoDTO());
    setFiltroAtivoSelecionado(null);
    setFiltroEmitirVpp(null);
    setQuantidadePacientesPsicologo(null);
    setFiltroExibeSessoesBaixadas(null);
    carregarDados();
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <div className="Psicologo">
        <div className="Title">
          <span>
            <PacienteIcon /> Pacientes
          </span>
        </div>
        <div></div>
      </div>
      <div className="Body">
        {AuthDataService.hasPermission(
          FuncionalidadeEnum.CADASTRO_PACIENTE
        ) && (
          <div className="NovoPaciente">
            <DefaultButton
              fontSize={12}
              icon={<NovoIcon />}
              buttonLabel={"Adicionar"}
              messageToolTip={"Cadastrar um novo paciente"}
              click={(_) =>
                handleModalNovoPaciente(
                  setIsEdicao,
                  setDisplayModal,
                  setPacienteSelecionado,
                  setIsVisualizacao,
                  setVisualizaHistorico
                )
              }
              width={150}
              height={30}
            />{" "}
            <br></br>
            <div style={{ marginLeft: 10 }}></div>
          </div>
        )}
        {filtroPsicologoSelecionado?.id !== undefined && (
          <Container>
            <Row>
              <Col>
                <label htmlFor="filtroPiscologoNome">Psicólogo:</label>
                <label className="LabelWithoutBold">
                  {filtroPsicologoSelecionado.nome}
                </label>
              </Col>
              <Col>
                <label htmlFor="filtroPiscologoNome">CRP:</label>
                <label className="LabelWithoutBold">
                  {filtroPsicologoSelecionado.crp}
                </label>
              </Col>
              <Col>
                <label htmlFor="filtroPiscologoNome">Telefone:</label>
                <label className="LabelWithoutBold">
                  {filtroPsicologoSelecionado.telefone}
                </label>
              </Col>
              <Col>
                <label htmlFor="filtroPiscologoNome">Email:</label>
                <label className="LabelWithoutBold">
                  {filtroPsicologoSelecionado.email}
                </label>
              </Col>
            </Row>
            {quantidadePacientesPsicologo !== null && (
              <Row>
                <Col>
                  <label htmlFor="filtroPiscologoNome">
                    Quantidade de pacientes:&nbsp;
                  </label>
                  <label className="LabelWithoutBold">
                    {quantidadePacientesPsicologo}
                  </label>
                </Col>
              </Row>
            )}
          </Container>
        )}
        <div>
          <Row>
            <Col>
              <label htmlFor="psicologoFilter">Psicólogo: &nbsp;&nbsp;</label>
              <Dropdown
                filter
                className="FiltroPsicologo"
                placeholder="Selecione um psicólogo"
                name="psicologoFilter"
                id="psicologoFilter"
                style={{ width: "100%", height: "50%", background: "#7988ac" }}
                value={
                  filtroPsicologoSelecionado?.id !== undefined
                    ? filtroPsicologoSelecionado
                    : {}
                }
                options={psicologos}
                optionLabel="nome"
                emptyMessage="Não há psicologos"
                onChange={(e) => {
                  setFiltroPsicologoSelecionado(e.target.value);
                  setFiltros({
                    ...filtros,
                    psicologoId: e.target.value.id,
                  });
                }}
              />
            </Col>
            <Col>
              <label htmlFor="pacienteStatusFilter">Status: &nbsp;&nbsp;</label>
              <Dropdown
                placeholder="Selecione um status"
                name="pacienteStatusFilter"
                id="pacienteStatusFilter"
                style={{ width: "100%", background: "#7988ac" }}
                value={filtroAtivoSelecionado}
                options={status}
                optionLabel="nome"
                onChange={(e) => {
                  setFiltroAtivoSelecionado(e.value);
                  setFiltros({
                    ...filtros,
                    ativo: e.value.valor,
                  });
                }}
              />
            </Col>
            <Col>
              <label htmlFor="emitirVppStatusFilter">
                Emitir VPP: &nbsp;&nbsp;
              </label>
              <Dropdown
                placeholder="Selecione uma opção"
                name="emitirVppStatusFilter"
                id="emitirVppStatusFilter"
                style={{ width: "100%", background: "#7988ac" }}
                value={filtroEmitirVpp}
                options={emitirVppStatus}
                optionLabel="nome"
                onChange={(e) => {
                  setFiltroEmitirVpp(e.value);
                  setFiltros({
                    ...filtros,
                    emitirVpp: e.value.valor,
                  });
                }}
              />
            </Col>
            <Col>
              <label htmlFor="pacienteSessoesFilter">
                Sessões: &nbsp;&nbsp;
              </label>
              <Dropdown
                placeholder="Selecione um status de sessão"
                name="pacienteSessoesFilter"
                id="pacienteSessoesFilter"
                style={{ width: "100%", background: "#7988ac" }}
                value={filtroExibeSessoesBaixadas}
                options={statusSessoes}
                optionLabel="nome"
                onChange={(e) => {
                  setFiltroExibeSessoesBaixadas(e.value);
                  setFiltros({
                    ...filtros,
                    exibeSessoesBaixadas: e.value.valor,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="FilterItens">
          <DefaultButton
            fontSize={12}
            icon={<FiltrarIcon />}
            buttonLabel={"Filtrar"}
            messageToolTip={"Filtra os resultados de acordo com os parâmetros"}
            click={(_) => {
              filtrarPacientes();
            }}
            width={150}
            height={30}
          />{" "}
          <DefaultButton
            fontSize={12}
            icon={<LimparIcon />}
            buttonLabel={"Limpar"}
            messageToolTip={"Limpar os filtros"}
            click={(_) => {
              limpar();
            }}
            width={150}
            height={30}
          />{" "}
        </div>
        <br></br>
        <CustomPaginateDataTable
          exportar={true}
          values={pacientes}
          totalRecords={totalPacientes}
          dataKey="id"
          headerList={PacienteHeaderList}
          actionTemplate={null}
          paginator={true}
          exibeActionTemplate={false}
          dadosParaExportacao={PacienteService.montaObjetoPacienteParaExportacao(
            pacientes
          )}
          expandable={true}
          expandableTitle={`Sessões do paciente`}
          expandableObjectName={"sessaoList"}
          expandableHeader={PacienteListaExpandidaHeaderList}
          expandableAttributeName={"paciente"}
          exportFunction={exportExcel}
          callbackFunction={filtrarPacientes}
          linhasPorPagina={linhasPorPagina}
          setLinhasPorPagina={setLinhasPorPaginaCallback}
          setPagina={setPaginaCallback}
          callbackSelectionChange={onClickLinhaPaciente}
        />
      </div>
      <DialogCRUDPaciente
        display={displayModal}
        esconder={fecharModal}
        paciente={pacienteSelecionado}
        setPaciente={setPacienteSelecionadoCallback}
        isVisualizacao={isVisualizacao}
        salvar={salvar}
        medicos={medicos}
        psicologos={psicologos}
        visualizaHistorico={visualizaHistorico}
        carregarDadosCallback={carregarDados}
      />
      <DialogAcoesPaciente
        display={displayModalAcoes}
        esconder={fecharModalAcoes}
        paciente={pacienteSelecionado}
        tituloModal={pacienteSelecionado.paciente}
        visualizaPaciente={visualizaPaciente}
        editaPaciente={editaPaciente}
        trocarStatusPaciente={trocarStatusPaciente}
        adicionaSessao={adicionaSessao}
      />
      <DialogAdicionarSessaoPaciente
        display={displayModalAdicionarParcela}
        esconder={fecharModalSessao}
        inputList={sessoesPaciente}
        setInputList={setSessoesPaciente}
        paciente={pacienteSelecionado}
        salvar={salvarSessoes}
      />
    </BlockUI>
  );
}
