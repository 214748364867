import { useCallback, useEffect, useRef, useState } from "react";
import { TipoProntuarioDTO } from "../../../model/dto/TipoProntuarioDTO";
import { TemplatePadraoProntuarioUsuarioDTO } from "../../../model/dto/TemplatePadraoProntuarioUsuarioDTO";
import TipoProntuarioService from "../../../service/TipoProntuarioService";
import TemplatePadraoProntuarioUsuarioService from "../../../service/TemplatePadraoProntuarioUsuarioService";
import { validarCriacaoNovoTemplatePadraoProntuario } from "../../../validator/TemplatePadraoProntuarioValidator";
import Messages from "../../../components/Messages";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import CustomDataTable from "../../../components/table/CustomDataTable";
import { ModeloPadraoProntuarioHeader } from "../padrao/header/ModeloPadraoProntuarioHeader";
import ModeloPadraoProntuarioUsuarioActionTemplate from "./buttons/ModeloUsuarioProntuarioActionButtons";
import { EditIcon, ViewIcon } from "../../../components/icons/Icons";
import DialogCRUDModeloPadraoProntuario from "../padrao/dialog/DialogCRUDModeloPadraoProntuario";
import PrintableContent from "../../../components/print/PrintableContent";
import { NOT_FOUND_EXCEPTION } from "../../../model/exceptions/ExceptionsEnum";

export default function ModeloUsuarioProntuario() {
  const quillRef = useRef();
  const [blockUi, setBlockUi] = useState(false);
  const [tiposProntuario, setTiposProntuario] = useState([]);
  const [totalTiposProntuario, setTotalTiposProntuario] = useState(0);
  const [tipoProntuarioSelecionado, setTipoProntuarioSelecionado] = useState(
    new TipoProntuarioDTO()
  );
  const [template, setTemplate] = useState(
    new TemplatePadraoProntuarioUsuarioDTO()
  );

  const [displayModal, setDisplayModal] = useState(false);
  const [isVisualizacao, setIsVisualizacao] = useState(false);

  const setTemplateCallback = useCallback(
    function (t) {
      setTemplate(t);
    },
    [setTemplate]
  );

  useEffect(() => {
    carregarDados();
  }, []);

  async function fetchTemplateParaImprimirCallback(rowData) {
    try {
      setBlockUi(true);
      const [templateData] = await Promise.all([
        TemplatePadraoProntuarioUsuarioService.getTemplatePadraoByUsuarioIdAndTipoProntuarioId(
          rowData.id
        ),
      ]);
      setTemplate(templateData);
      setTipoProntuarioSelecionado(rowData);
      setBlockUi(false);
    } catch (error) {
      if (error.response.data.code === NOT_FOUND_EXCEPTION) {
        Messages.errorMessage(
          "Opss...",
          "Este tipo de prontuário não possui template padrão"
        );
        setBlockUi(false);
        return false;
      }
    }
    setBlockUi(false);
    return true;
  }

  async function carregarDados() {
    setBlockUi(true);
    const [tiposProntuarioData] = await Promise.all([
      TipoProntuarioService.getAllTiposProntuario(),
    ]);
    setTiposProntuario(tiposProntuarioData);
    setTotalTiposProntuario(tiposProntuarioData.length);
    setBlockUi(false);
  }

  function handleModalVisualizarTemplatePadraoPorTipoProntuario(rowData) {
    buscarTemplatePorTipoProntuario(rowData, true);
  }

  function handleModalEditarTemplatePadraoPorTipoProntuario(rowData) {
    buscarTemplatePorTipoProntuario(rowData, false);
  }

  function fecharModal() {
    setDisplayModal(false);
    setTipoProntuarioSelecionado(new TemplatePadraoProntuarioUsuarioDTO());
    setIsVisualizacao(false);
  }

  async function buscarTemplatePorTipoProntuario(rowData, isVisualizacao) {
    setBlockUi(true);
    try {
      const [templateData] = await Promise.all([
        TemplatePadraoProntuarioUsuarioService.getTemplatePadraoByUsuarioIdAndTipoProntuarioId(
          rowData.id
        ),
      ]);
      setTemplate(templateData);
      setDisplayModal(true);
      setTipoProntuarioSelecionado(rowData);
      setIsVisualizacao(isVisualizacao);
      setBlockUi(false);
    } catch (error) {
      if (error.response.data.code === NOT_FOUND_EXCEPTION) {
        setBlockUi(false);
        const result = await validarCriacaoNovoTemplatePadraoProntuario(
          rowData
        );
        if (result.isConfirmed) {
          setTemplate(new TemplatePadraoProntuarioUsuarioDTO());
          setDisplayModal(true);
          setTipoProntuarioSelecionado(rowData);
          setIsVisualizacao(isVisualizacao);
          setBlockUi(false);
        }
      }
    }
    setBlockUi(false);
  }

  async function salvar() {
    try {
      await TemplatePadraoProntuarioUsuarioService.salvarDadosTemplatePadraoProntuarioUsuario(
        template.id,
        template
      );
      fecharModal();
      setBlockUi(false);
      Messages.successMessage("Sucesso", "Template salvo com sucesso!");
      carregarDados();
    } catch (error) {
      fecharModal();
      setBlockUi(false);
      Messages.errorMessage("Erro", error.response.data.message);
    }
    setBlockUi(false);
  }

  function actionTemplate(rowData) {
    return (
      <ModeloPadraoProntuarioUsuarioActionTemplate
        visualizarIcon={<ViewIcon />}
        visualizarMessageTooltip={"Visualizar template"}
        visualizarClickAction={(_) =>
          handleModalVisualizarTemplatePadraoPorTipoProntuario(rowData)
        }
        editarIcon={<EditIcon />}
        editarMessageTooltip={"Editar template"}
        editarClickAction={(_) =>
          handleModalEditarTemplatePadraoPorTipoProntuario(rowData)
        }
        imprimirRef={quillRef}
        imprimirMessageTooltip={"Imprimir"}
        imprimirTituloArquivo={tipoProntuarioSelecionado.nomeExibicao}
        imprimirCallbackFunction={(_) =>
          fetchTemplateParaImprimirCallback(rowData)
        }
        imprimirRowData={rowData}
      />
    );
  }

  return (
    <BlockUI
      width={"100%"}
      template={
        <ProgressSpinner
          strokeWidth="8"
          className="blue-spinner"
          style={{ width: "40px" }}
        />
      }
      blocked={blockUi}
    >
      <label style={{ fontSize: 15 }}>Meus modelos de prontuário</label>
      <CustomDataTable
        values={tiposProntuario}
        totalRecords={totalTiposProntuario}
        dataKey="id"
        headerList={ModeloPadraoProntuarioHeader}
        actionTemplate={actionTemplate}
        paginator={true}
        exibeActionTemplate={true}
      />
      <DialogCRUDModeloPadraoProntuario
        display={displayModal}
        esconder={fecharModal}
        tipoProntuario={tipoProntuarioSelecionado}
        isVisualizacao={isVisualizacao}
        template={template}
        setTemplate={setTemplateCallback}
        salvar={salvar}
      />

      <PrintableContent
        ref={quillRef}
        conteudoImprimir={template.htmlTemplate}
      />
    </BlockUI>
  );
}
