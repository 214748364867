export class AlterarStatusComparecimentoRequestDTO {
  pacienteCompareceu?: boolean;
  tipoAusenciaId?: number;
  justificativaAusencia?: string;

  constructor(options: Partial<AlterarStatusComparecimentoRequestDTO> = {}) {
    this.pacienteCompareceu = options.pacienteCompareceu;
    this.tipoAusenciaId = options.tipoAusenciaId;
    this.justificativaAusencia = options.justificativaAusencia;
  }
}
