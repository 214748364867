import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import { useEffect, useState } from "react";
import { RelatoriosIcon } from "../../components/icons/Icons";
import RelatoriosOptions from "../../model/enums/RelatorioEnum";
import PacienteService from "../../service/PacienteService";
import PsicologoService from "../../service/PsicologoService";
import TipoProntuarioService from "../../service/TipoProntuarioService";
import UsuarioService from "../../service/UsuarioService";
import "./Relatorios.css";
import RelatorioHistoricoAlteracaoPsicologo from "./historico-alteracao-psicologo/RelatorioHistoricoAlteracaoPsicologo";
import RelatorioHistoricoAtendimentos from "./historico-atendimentos/RelatorioHistoricoAtendimentos";
export default function Relatorios() {
  const [blockUi, setBlockUi] = useState(false);
  const [relatorioSelecionado, setRelatorioSelecionado] = useState(null);
  const [psicologos, setPsicologos] = useState([]);
  const [pacientes, setPacientes] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [tiposProntuario, setTiposProntuario] = useState([]);

  useEffect(() => {
    carregarDados();
  }, []);

  async function carregarDados() {
    setBlockUi(true);
    const [psicologosData, pacientesData, usuariosData, tiposProntuarioData] =
      await Promise.all([
        PsicologoService.getAll(),
        PacienteService.getAll(),
        UsuarioService.getAll(),
        TipoProntuarioService.getAllTiposProntuario(),
      ]);
    setPsicologos(psicologosData);
    setPacientes(pacientesData);
    setUsuarios(usuariosData);
    setTiposProntuario(tiposProntuarioData);
    setBlockUi(false);
  }
  return (
    <>
      <BlockUI
        width={"100%"}
        template={
          <ProgressSpinner
            strokeWidth="8"
            className="blue-spinner"
            style={{ width: "40px" }}
          />
        }
        blocked={blockUi}
      >
        <div className="Relatorios">
          <div className="Title">
            <span>
              <RelatoriosIcon /> Relatórios
            </span>
          </div>
          <div></div>
        </div>
        <div className="BodyRelatorios">
          <div style={{ textAlign: "left", marginBottom: 5 }}>
            <label>
              <u>Tipo do relatório: </u>
            </label>
          </div>
          <div className="flex align-items-center">
            {RelatoriosOptions.map((relatorio) => {
              return (
                <div key={relatorio.id} className="flex align-items-center">
                  <RadioButton
                    inputId={relatorio.inputId}
                    name={relatorio.nome}
                    value={relatorio.valor}
                    onChange={(e) => {
                      setRelatorioSelecionado(e.value);
                    }}
                    checked={relatorioSelecionado === relatorio.valor}
                  />
                  <label
                    htmlFor={relatorio.nome}
                    className="ml-2"
                    style={{ marginRight: 15 }}
                  >
                    {relatorio.nome}
                  </label>
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: 10 }}>
            {relatorioSelecionado === "HISTORICO_ALTERACAO_PSICOLOGO" && (
              <RelatorioHistoricoAlteracaoPsicologo
                psicologos={psicologos}
                pacientes={pacientes}
                usuarios={usuarios}
              />
            )}
            {relatorioSelecionado === "HISTORICO_ATENDIMENTOS" && (
              <RelatorioHistoricoAtendimentos
                tiposProntuario={tiposProntuario}
                pacientes={pacientes}
                psicologos={psicologos}
              />
            )}
          </div>
        </div>
      </BlockUI>
    </>
  );
}
