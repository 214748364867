import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css"; // Adicionando o estilo do tema bubble
import "react-quill/dist/quill.snow.css";
import DefaultButton from "../../../../components/buttons/DefaultButton";
import {
  CancelIcon,
  ModeloProntuarioIcon,
  SaveIcon,
} from "../../../../components/icons/Icons";
import "../../../../components/quill/Quill.css";
import {
  QuillFormats,
  QuillModules,
} from "../../../../components/quill/QuillConfig";

export default function DialogCRUDModeloPadraoProntuario(props) {
  const {
    display,
    esconder,
    tipoProntuario,
    isVisualizacao,
    template,
    setTemplate,
    salvar,
  } = props;

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<ModeloProntuarioIcon />}{" "}
        {`Template padrão para o prontuário ${tipoProntuario.nomeExibicao}`}
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        contentStyle={{
          width: "100vw",
        }}
        style={{ width: "100vw" }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados do template">
          <Container style={{ maxHeight: "400px", height: "350px" }}>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a", fontSize: 10 }}
                  htmlFor="templatePadrao"
                >
                  <b>Observação: </b> O template desenhado aqui, será carregado
                  como padrão ao iniciar um prontuário do tipo{" "}
                  {tipoProntuario.nomeExibicao}{" "} junto com os dados pré-preenchidos do paciente
                </label>
                <br></br>
                <br></br>
                <ReactQuill
                  theme="snow"
                  modules={QuillModules}
                  formats={QuillFormats}
                  readOnly={isVisualizacao}
                  style={{
                    backgroundColor: "white",
                    border: "3px solid #24345a",
                    margin: 0,
                    padding: 0,
                    width: "100%",
                  }}
                  value={template?.htmlTemplate || ""}
                  onChange={(htmlTemplate) =>
                    setTemplate({
                      ...template,
                      tipoProntuario: tipoProntuario,
                      htmlTemplate: htmlTemplate,
                      ativo: true,
                    })
                  }
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        {!isVisualizacao && (
          <div className="Footer">
            <DefaultButton
              fontSize={15}
              icon={<SaveIcon />}
              buttonLabel={"Salvar"}
              messageToolTip={"Salvar template"}
              click={(_) => salvar(template)}
              width={150}
              height={30}
            />
            <DefaultButton
              fontSize={15}
              icon={<CancelIcon />}
              buttonLabel={"Cancelar"}
              messageToolTip={"Cancelar edição"}
              click={esconder}
              width={150}
              height={30}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}
