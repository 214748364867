import "./DialogCRUDPaciente.css";

import { Dialog } from "primereact/dialog";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  default as Messages,
  default as message,
} from "../../../components/Messages";
import DefaultButton from "../../../components/buttons/DefaultButton";
import {
  CancelIcon,
  FiltrarIcon,
  PacienteIcon,
  SaveIcon,
} from "../../../components/icons/Icons";
import CustomDataTable from "../../../components/table/CustomDataTable";
import { PacientePsicologoDTO } from "../../../model/dto/PacientePsicologoDTO";
import EnderecoService from "../../../service/EnderecoService";
import PacienteService from "../../../service/PacienteService";
import { Ufs } from "../../../utils/EstadosBrasilUtils";
import { validarConsultaCep } from "../../../validator/EnderecoValidator";
import { PacientePsicologosHeader } from "../header/PacientePsicologosHeader";
import DialogAlteraDataVinculo from "./DialogAlteraDataVinculo";

export default function DialogCRUDPaciente(props) {
  const [displayModalDataVinculo, setDisplayModalDataVinculo] = useState(false);
  const [pacientePsicologo, setPacientePsicologo] = useState(
    new PacientePsicologoDTO()
  );

  const setPacientePsicologoCallback = useCallback(
    function (p) {
      setPacientePsicologo(p);
    },
    [setPacientePsicologo]
  );

  const sexoList = ["M", "F"];
  const {
    display,
    esconder,
    paciente,
    setPaciente,
    isVisualizacao,
    salvar,
    medicos,
    psicologos,
    tituloModal,
    visualizaHistorico,
    carregarDadosCallback,
  } = props;

  const [enderecoConsultado, setEnderecoConsultado] = useState("");
  const [isBuscandoDadosEndereco, setIsBuscandoDadosEndereco] = useState(false);

  function fecharModalDataVinculo() {
    setDisplayModalDataVinculo(false);
  }

  function onClickLinha(rowData) {
    setPacientePsicologo(rowData.value);
    setDisplayModalDataVinculo(true);
  }

  function salvarDataVinculo(pacientePsicologo) {
    PacienteService.alterarDataVinculoPacientePsicologo(
      pacientePsicologo.id,
      pacientePsicologo
    )
      .then((_) => {
        message.successMessage(
          "Sucesso",
          "Data de vínculo alterada com sucesso!"
        );
        fecharModalDataVinculo(true);
        esconder();
        carregarDadosCallback();
      })
      .catch((error) => {
        message.errorMessage("Ops...", error.response.data.message);
        fecharModalDataVinculo(true);
        esconder();
        carregarDadosCallback();
      });
  }

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<PacienteIcon />}{" "}
        {tituloModal !== undefined
          ? tituloModal
          : "Cadastro e gerenciamento de pacientes"}
      </div>
    );
  };

  const calcularIdade = (dataNascimento) => {
    const hoje = new Date();
    const dataNasc = new Date(dataNascimento);
    let idade = hoje.getFullYear() - dataNasc.getFullYear();
    if (
      hoje.getMonth() < dataNasc.getMonth() ||
      (hoje.getMonth() === dataNasc.getMonth() &&
        hoje.getDate() < dataNasc.getDate())
    ) {
      idade--;
    }

    return idade;
  };

  const handleDataNascimentoChange = (e) => {
    const novaDataNascimento = e.target.value;
    setPaciente({
      ...paciente,
      dataNascimento: novaDataNascimento,
      idade: calcularIdade(novaDataNascimento),
    });
  };

  async function consultarEnderecoPorCep() {
    if (!validarConsultaCep(enderecoConsultado)) {
      esconder();
      return;
    }
    try {
      setIsBuscandoDadosEndereco(true);
      const [enderecoData] = await Promise.all([
        EnderecoService.getEnderecoByCep(enderecoConsultado),
      ]);
      setPaciente({
        ...paciente,
        cep: enderecoData.cep,
        logradouro: enderecoData.logradouro,
        complemento: enderecoData.complemento,
        bairro: enderecoData.bairro,
        cidade: enderecoData.cidade,
        estado: enderecoData.estado,
        numero: enderecoData.numero,
      });
      setIsBuscandoDadosEndereco(false);
    } catch (error) {
      setIsBuscandoDadosEndereco(false);
      setEnderecoConsultado("");
      esconder();
      Messages.errorMessage("Erro", error.response.data.message);
    }
  }

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados do paciente">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteId">
                  <b>ID: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.id || ""}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="nomePaciente">
                  <b>Nome: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.paciente || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      paciente: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteNrCarteirinha"
                >
                  <b>Nr. Carteirinha: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.nrCarteirinha || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      nrCarteirinha: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteDataNascimento"
                >
                  <b>Data nascimento: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={paciente?.dataNascimento || ""}
                  onChange={handleDataNascimentoChange}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteIdade">
                  <b>Idade: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="number"
                  value={paciente?.idade || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      idade: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteSexo">
                  <b>Sexo: </b>
                </label>
                <br></br>
                <Dropdown
                  disabled={isVisualizacao}
                  style={{ width: "100%", background: "#7988ac" }}
                  value={paciente?.sexo || ""}
                  options={sexoList}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      sexo: e.value,
                    });
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteDataInicioPlano"
                >
                  <b>Data inicio plano: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={paciente?.inicioPlano || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      inicioPlano: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteDataFimPlano"
                >
                  <b>Data fim plano: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={paciente?.fimPlano || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      fimPlano: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="usuarioAtivo">
                  <b>Emitir VPP: </b>
                </label>
                <br></br>
                <Checkbox
                  style={{ marginTop: "15px" }}
                  disabled={isVisualizacao}
                  inputId="bloqued"
                  checked={
                    paciente?.emitirVpp === null ||
                    paciente?.emitirVpp === undefined
                      ? true
                      : paciente.emitirVpp
                  }
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      emitirVpp: e.checked,
                    });
                  }}
                ></Checkbox>
              </Col>
            </Row>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacientePsicologo">
                  <b>Psicólogo: </b>
                </label>
                <br></br>
                <Dropdown
                  filter
                  disabled={isVisualizacao}
                  style={{ width: "100%", background: "#7988ac" }}
                  value={paciente?.psicologo || ""}
                  options={psicologos}
                  optionLabel="nome"
                  emptyMessage="Não há psicologos"
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      psicologo: e.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacientePsicologo">
                  <b>Observações: </b>
                </label>
                <br></br>
                <InputTextarea
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.observacao || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      observacao: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        <Panel header="Dados de contato">
          <Container>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoCep"
                >
                  <b>CEP: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.cep || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      cep: e.target.value,
                    });
                    setEnderecoConsultado(e.target.value);
                  }}
                />
                {isBuscandoDadosEndereco && (
                  <label htmlFor="buscandoLabel">
                    Buscando dados do endereço...
                  </label>
                )}
              </Col>
              {!isVisualizacao && (
                <Col>
                  <div className="BotaoConsultaCep SpinnerContainer">
                    <DefaultButton
                      fontSize={10}
                      icon={<FiltrarIcon />}
                      buttonLabel={"Buscar"}
                      messageToolTip={"Busca o endereço pelo CEP informado"}
                      click={(_) => consultarEnderecoPorCep()}
                      width={130}
                      height={30}
                    />
                    {isBuscandoDadosEndereco && (
                      <div style={{ marginLeft: "10px" }}>
                        <ProgressSpinner
                          strokeWidth="8"
                          className="blue-spinner"
                          style={{ width: "40px" }}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoEndereco"
                >
                  <b>Endereço: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.logradouro || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      logradouro: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoNumero"
                >
                  <b>Número: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.numero || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      numero: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoComplemento"
                >
                  <b>Complemento: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.complemento || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      complemento: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoBairro"
                >
                  <b>Bairro: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.bairro || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      bairro: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoCidade"
                >
                  <b>Cidade: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.cidade || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      cidade: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoEstado"
                >
                  <b>Estado: </b>
                </label>
                <br></br>
                <Dropdown
                  disabled={isVisualizacao || isBuscandoDadosEndereco}
                  filter
                  style={{ width: "100%", background: "#7988ac" }}
                  value={paciente?.estado || ""}
                  options={Ufs}
                  emptyMessage="Não há estados cadastrados no sistema"
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      estado: e.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoTelefone"
                >
                  <b>Telefone: </b>
                </label>
                <br></br>
                <InputMask
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  mask="(99) 99999-9999"
                  value={paciente?.telefone || ""}
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      telefone: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        <Panel header="Dados do médico">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteMedico">
                  <b>Médico: </b>
                </label>
                <br></br>
                {!isVisualizacao ? (
                  <Dropdown
                    filter
                    disabled={isVisualizacao}
                    style={{ width: "100%", background: "#7988ac" }}
                    value={paciente?.medico || ""}
                    options={medicos}
                    optionLabel="nome"
                    emptyMessage="Não há médicos cadastrados"
                    onChange={(e) => {
                      setPaciente({
                        ...paciente,
                        medico: e.value,
                      });
                    }}
                  />
                ) : (
                  <label style={{ color: "#24345a" }} htmlFor="pacienteMedico">
                    <b>{paciente?.medico?.nome || ""} </b>
                  </label>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoEspecialidade"
                >
                  <b>Especialidade do médico: </b>
                </label>
                <br></br>
                <Dropdown
                  disabled={isVisualizacao || !paciente.medico}
                  filter
                  style={{ width: "100%", background: "#7988ac" }}
                  value={paciente?.especialidade || ""}
                  options={paciente.medico?.especialidades}
                  optionLabel="nome"
                  emptyMessage="Não há especialidades para o médico selecionado"
                  onChange={(e) => {
                    setPaciente({
                      ...paciente,
                      especialidade: e.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="crmMedico">
                  <b>CRM: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.medico?.crm || ""}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        {visualizaHistorico === true && (
          <Panel header="Histórico de psicólogos">
            <Container>
              <Row>
                <Col>
                  <CustomDataTable
                    values={
                      paciente !== undefined &&
                      paciente.listaPsicologos !== undefined
                        ? paciente.listaPsicologos
                        : []
                    }
                    totalRecords={
                      paciente !== undefined &&
                      paciente.listaPsicologos !== undefined
                        ? paciente.listaPsicologos.length
                        : 0
                    }
                    dataKey="id"
                    headerList={PacientePsicologosHeader}
                    actionTemplate={null}
                    paginator={true}
                    exibeActionTemplate={false}
                    callbackSelectionChange={
                      !isVisualizacao ? onClickLinha : null
                    }
                  />
                </Col>
              </Row>
            </Container>
          </Panel>
        )}
        <br></br>
        {!isVisualizacao && (
          <div className="Footer">
            <DefaultButton
              fontSize={15}
              icon={<SaveIcon />}
              buttonLabel={"Salvar"}
              messageToolTip={"Salvar operação"}
              click={(_) => salvar(paciente)}
              width={150}
              height={30}
            />
            <DefaultButton
              fontSize={15}
              icon={<CancelIcon />}
              buttonLabel={"Cancelar"}
              messageToolTip={"Cancelar operação"}
              click={esconder}
              width={150}
              height={30}
            />
          </div>
        )}
      </Dialog>
      <DialogAlteraDataVinculo
        display={displayModalDataVinculo}
        esconder={fecharModalDataVinculo}
        nomePaciente={paciente.paciente}
        pacientePsicologo={pacientePsicologo}
        setPacientePsicologo={setPacientePsicologoCallback}
        salvar={salvarDataVinculo}
        isVisualizacao={isVisualizacao}
      />
    </div>
  );
}
