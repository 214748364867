import { Col, Row } from "react-bootstrap";
import ProntuarioLabel from "./ProntuarioLabel";

export default function InformacoesProntuario({ prontuario }) {
  function formatPrimeiraSessao(rowData) {
    if (rowData.isPrimeiraSessao) {
      return "Sim";
    } else {
      return "Não";
    }
  }
  return (
    <div style={{ textAlign: "left" }}>
      <Row>
        <Col>
          <ProntuarioLabel
            titulo={"Tipo de atendimento: "}
            valor={
              prontuario !== undefined &&
              prontuario.tipoProntuario !== undefined
                ? prontuario.tipoProntuario.nome
                : ""
            }
          />
        </Col>
        <Col>
          <ProntuarioLabel
            titulo={"Psicólogo: "}
            valor={
              prontuario !== undefined && prontuario.psicologo !== undefined
                ? prontuario.psicologo.nome
                : ""
            }
          />
        </Col>
        <Col>
          <ProntuarioLabel
            titulo={"Primeira sessão?: "}
            valor={
              prontuario !== undefined ? formatPrimeiraSessao(prontuario) : ""
            }
          />
        </Col>
      </Row>
      <Row></Row>
    </div>
  );
}
