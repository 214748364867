import Messages from "../components/Messages";

export function validate(fieldsValidate) {
  let fieldsInvalids = [];
  for (let field of fieldsValidate) {
    if (field.value === "" || field.value === undefined) {
      fieldsInvalids.push(field.name);
    }
  }
  if (fieldsInvalids.length === 1) {
    Messages.errorMessage(
      "Erro",
      `O campo ${fieldsInvalids} precisa ser preenchido.`
    ).then(function () {
      return false;
    });
  } else if (fieldsInvalids.length > 1) {
    Messages.errorMessage(
      "Erro",
      `Os campos ${fieldsInvalids} precisam ser preenchidos.`
    ).then(function () {
      return false;
    });
  } else {
    return true;
  }
}
