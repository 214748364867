export class TemplatePadraoProntuarioRequestDTO {
  tipoProntuarioId?: number;
  htmlTemplate?: string;
  ativo?: boolean;

  constructor(options: Partial<TemplatePadraoProntuarioRequestDTO> = {}) {
    this.tipoProntuarioId = options.tipoProntuarioId;
    this.htmlTemplate = options.htmlTemplate;
    this.ativo = options.ativo;
  }
}
