import PropTypes from "prop-types";

import { Dialog } from "primereact/dialog";
import { CancelIcon, EspecialidadeIcon, SaveIcon } from "../../../components/icons/Icons";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import DefaultButton from "../../../components/buttons/DefaultButton";

export default function DialogCRUDEspecialidade(props) {
  const {
    display,
    esconder,
    especialidade,
    setEspecialidade,
    isVisualizacao,
    salvar,
  } = props;

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<EspecialidadeIcon />} {"Cadastro e gerenciamento de especialidades"}
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados da especialidade">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="especialidadeId">
                  <b>ID: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    especialidade?.id !== undefined
                      ? "#" + especialidade.id
                      : ""
                  }
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="especialidadeNome">
                  <b>Especialidade: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    especialidade?.nome !== undefined ? especialidade.nome : ""
                  }
                  onChange={(e) => {
                    setEspecialidade({
                      ...especialidade,
                      nome: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="especialidadeCBO">
                  <b>CBO: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    especialidade?.cbo !== undefined ? especialidade.cbo : ""
                  }
                  onChange={(e) => {
                    setEspecialidade({
                      ...especialidade,
                      cbo: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        {!isVisualizacao && (
          <div className="Footer">
            <DefaultButton
              fontSize={15}
              icon={<SaveIcon />}
              buttonLabel={"Salvar"}
              messageToolTip={"Salvar operação"}
              click={(_) => salvar(especialidade)}
              width={150}
              height={30}
            />
            <DefaultButton
              fontSize={15}
              icon={<CancelIcon />}
              buttonLabel={"Cancelar"}
              messageToolTip={"Cancelar operação"}
              click={esconder}
              width={150}
              height={30}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}

DialogCRUDEspecialidade.propTypes = {
  display: PropTypes.any,
  esconder: PropTypes.any,
  especialidade: PropTypes.any,
  setEspecialidade: PropTypes.any,
  isVisualizacao: PropTypes.any,
  salvar: PropTypes.any,
};
