import { TemplatePadraoProntuarioUsuarioDTO } from "./../model/dto/TemplatePadraoProntuarioUsuarioDTO";
import templatePadraoProntuarioUsuarioDataService from "../data_services/templates-prontuario/TemplatePadraoProntuarioUsuarioDataService";
import { TemplatePadraoProntuarioRequestDTO } from "../model/dto/request/TemplatePadraoProntuarioRequestDTO";

class TemplatePadraoProntuarioUsuarioService {
  static async getAllTemplatesByUsuarioLogado(): Promise<
    TemplatePadraoProntuarioUsuarioDTO[]
  > {
    const response =
      await templatePadraoProntuarioUsuarioDataService.getAllTemplatesByUsuarioLogado();
    const templates: TemplatePadraoProntuarioUsuarioDTO[] = response.data;
    return templates;
  }

  static async getTemplatePadraoByUsuarioIdAndTipoProntuarioId(
    tipoProntuarioId: number
  ): Promise<TemplatePadraoProntuarioUsuarioDTO> {
    const response =
      await templatePadraoProntuarioUsuarioDataService.getTemplatePadraoByUsuarioIdAndTipoProntuarioId(
        tipoProntuarioId
      );
    const template: TemplatePadraoProntuarioUsuarioDTO = response.data;
    return template;
  }

  static async salvarDadosTemplatePadraoProntuarioUsuario(
    id: number,
    templatePadraoProntuario: any
  ) {
    const templatePadraoProntuarioRequestDTO = this.montaRequest(
      templatePadraoProntuario
    );
    if (id) {
      await this.atualizarTemplatePadraoProntuarioUsuario(
        id,
        templatePadraoProntuarioRequestDTO
      );
    } else {
      await this.criarTemplatePadraoProntuarioUsuario(
        templatePadraoProntuarioRequestDTO
      );
    }
  }

  private static async criarTemplatePadraoProntuarioUsuario(
    templatePadraoProntuarioRequestDTO: TemplatePadraoProntuarioRequestDTO
  ) {
    await templatePadraoProntuarioUsuarioDataService.criarTemplatePadraoProntuarioUsuario(
      templatePadraoProntuarioRequestDTO
    );
  }

  private static async atualizarTemplatePadraoProntuarioUsuario(
    templatePadraoProntuarioId: number,
    templatePadraoProntuarioRequestDTO: TemplatePadraoProntuarioRequestDTO
  ) {
    await templatePadraoProntuarioUsuarioDataService.atualizarTemplatePadraoProntuarioUsuario(
      templatePadraoProntuarioId,
      templatePadraoProntuarioRequestDTO
    );
  }

  private static montaRequest(
    templatePadraoProntuarioUsuarioDTO: TemplatePadraoProntuarioUsuarioDTO
  ) {
    return new TemplatePadraoProntuarioRequestDTO({
      tipoProntuarioId: templatePadraoProntuarioUsuarioDTO.tipoProntuario?.id,
      htmlTemplate: templatePadraoProntuarioUsuarioDTO.htmlTemplate,
      ativo: templatePadraoProntuarioUsuarioDTO.ativo,
    });
  }
}

export default TemplatePadraoProntuarioUsuarioService;
