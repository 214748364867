export class PacienteFiltrosRequestDTO {
  psicologoId?: number;
  ativo?: boolean;
  especialidadeId?: number;
  exibeSessoesBaixadas?: boolean;
  emitirVpp?: boolean;

  constructor(options: Partial<PacienteFiltrosRequestDTO> = {}) {
    this.psicologoId = options.psicologoId;
    this.ativo = options.ativo;
    this.especialidadeId = options.especialidadeId;
    this.exibeSessoesBaixadas = options.exibeSessoesBaixadas;
    this.emitirVpp = options.emitirVpp;
  }
}
