import PropTypes from "prop-types";

import "./DialogCRUDMedico.css";

import { Dialog } from "primereact/dialog";

import {
  CancelIcon,
  MedicoIcon,
  SaveIcon,
} from "../../../components/icons/Icons";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";
import { Col, Container, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import DefaultButton from "../../../components/buttons/DefaultButton";
import { Ufs } from "../../../utils/EstadosBrasilUtils";
import { MultiSelect } from "primereact/multiselect";

export default function DialogCRUDMedico(props) {
  const {
    display,
    esconder,
    medico,
    setMedico,
    isVisualizacao,
    salvar,
    especialidades,
  } = props;

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<MedicoIcon />} {"Cadastro e gerenciamento de médicos"}
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados do médico">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="medicoId">
                  <b>ID: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={medico?.id !== undefined ? medico.id : ""}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="nomeMedico">
                  <b>Nome: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={medico?.nome !== undefined ? medico.nome : ""}
                  onChange={(e) => {
                    setMedico({
                      ...medico,
                      nome: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="crmMedico">
                  <b>CRM: </b>
                </label>
                <br></br>
                <InputText
                  disabled={isVisualizacao}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={medico?.crm !== undefined ? medico.crm : ""}
                  onChange={(e) => {
                    setMedico({
                      ...medico,
                      crm: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="medicoEspecialidade"
                >
                  <b>Especialidade: </b>
                </label>
                <br></br>
                <MultiSelect
                  itemClassName="CustomMultiSelect"
                  disabled={isVisualizacao}
                  value={
                    medico?.especialidades !== undefined
                      ? medico.especialidades
                      : []
                  }
                  options={especialidades}
                  optionLabel="nome"
                  placeholder="Selecione as especialidades"
                  filter
                  className="multiselect-custom CustomMultiSelect"
                  display="chip"
                  onChange={(e) => {
                    setMedico({
                      ...medico,
                      especialidades: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="medicoSelecionadoEstado"
                >
                  <b>Estado: </b>
                </label>
                <br></br>
                <Dropdown
                  disabled={isVisualizacao}
                  filter
                  style={{ width: "100%", background: "#7988ac" }}
                  value={medico?.estado !== undefined ? medico.estado : ""}
                  options={Ufs}
                  emptyMessage="Não há estados cadastrados no sistema"
                  onChange={(e) => {
                    setMedico({
                      ...medico,
                      estado: e.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        {!isVisualizacao && (
          <div className="Footer">
            <DefaultButton
              fontSize={15}
              icon={<SaveIcon />}
              buttonLabel={"Salvar"}
              messageToolTip={"Salvar operação"}
              click={(_) => salvar(medico)}
              width={150}
              height={30}
            />
            <DefaultButton
              fontSize={15}
              icon={<CancelIcon />}
              buttonLabel={"Cancelar"}
              messageToolTip={"Cancelar operação"}
              click={esconder}
              width={150}
              height={30}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}

DialogCRUDMedico.propTypes = {
  display: PropTypes.any,
  esconder: PropTypes.any,
  medico: PropTypes.any,
  setMedico: PropTypes.any,
  isVisualizacao: PropTypes.any,
  salvar: PropTypes.any,
  especialidades: PropTypes.any,
};
