import axios from "axios";
import { storageTokenConfig } from "../../../config/token/StorageTokenConfig";
import { environment } from "../../../environments/environment";
import { CriarEventoRequestDTO } from "../../../model/dto/request/CriarEventoRequestDTO";
import { CancelarEventoRequestDTO } from "../../../model/dto/request/CancelarEventoRequestDTO";
import { AlterarStatusComparecimentoRequestDTO } from "../../../model/dto/request/AlterarStatusComparecimentoRequestDTO";

class EventoAgendaDataService {
  env = environment.getEnvironment();
  EVENTO_AGENDA_ENDPOINTS = `${this.env.BACKEND_API_URL}/agenda/evento`;
  token = storageTokenConfig.getToken();

  getAllEventosByUsuarioLogado() {
    return axios.get(`${this.EVENTO_AGENDA_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  getAllEventosByUsuarioId(usuarioId: number) {
    return axios.get(
      `${this.EVENTO_AGENDA_ENDPOINTS}/by-usuario/${usuarioId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  criarEvento(criarEventoRequestDTO: CriarEventoRequestDTO) {
    return axios.post(
      `${this.EVENTO_AGENDA_ENDPOINTS}`,
      criarEventoRequestDTO,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  atualizarEvento(
    eventoId: number,
    criarEventoRequestDTO: CriarEventoRequestDTO
  ) {
    return axios.put(
      `${this.EVENTO_AGENDA_ENDPOINTS}/${eventoId}`,
      criarEventoRequestDTO,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  deletarEvento(eventoId: number) {
    return axios.delete(`${this.EVENTO_AGENDA_ENDPOINTS}/${eventoId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  cancelarEvento(
    eventoId: number,
    cancelarEventoRequestDTO: CancelarEventoRequestDTO
  ) {
    return axios.patch(
      `${this.EVENTO_AGENDA_ENDPOINTS}/cancelar-evento/${eventoId}`,
      cancelarEventoRequestDTO,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  alterarStatusComparecimentoPaciente(
    eventoId: number,
    alterarStatusComparecimentoRequestDTO: AlterarStatusComparecimentoRequestDTO
  ) {
    return axios.patch(
      `${this.EVENTO_AGENDA_ENDPOINTS}/alterar-comparecimento/${eventoId}`,
      alterarStatusComparecimentoRequestDTO,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  getByUsuarioIdAndMesAndAno(usuarioId: number, mes: number, ano: number) {
    return axios.get(
      `${this.EVENTO_AGENDA_ENDPOINTS}/filtrar-mes-ano/${usuarioId}/${mes}/${ano}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }
}

const eventoAgendaDataService = new EventoAgendaDataService();
export default eventoAgendaDataService;
