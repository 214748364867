import "./DialogCRUDPaciente.css";

import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import { PacienteIcon } from "../../../components/icons/Icons";
import CustomDataTable from "../../../components/table/CustomDataTable";
import { Ufs } from "../../../utils/EstadosBrasilUtils";
import { PacientePsicologosHeader } from "../header/PacientePsicologosHeader";

export default function DialogVisualizaPaciente(props) {
  const sexoList = ["M", "F"];
  const { display, esconder, paciente, tituloModal } = props;

  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<PacienteIcon />}{" "}
        {tituloModal !== undefined
          ? tituloModal
          : "Cadastro e gerenciamento de pacientes"}
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Dados do paciente">
          <Container>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteId">
                  <b>ID: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.id !== undefined ? paciente.id : ""}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="nomePaciente">
                  <b>Nome: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    paciente?.paciente !== undefined ? paciente.paciente : ""
                  }
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteNrCarteirinha"
                >
                  <b>Nr. Carteirinha: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    paciente?.nrCarteirinha !== undefined
                      ? paciente.nrCarteirinha
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteDataNascimento"
                >
                  <b>Data nascimento: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    paciente?.dataNascimento !== undefined
                      ? paciente.dataNascimento
                      : ""
                  }
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteIdade">
                  <b>Idade: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="number"
                  value={paciente?.idade !== undefined ? paciente.idade : ""}
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteSexo">
                  <b>Sexo: </b>
                </label>
                <br></br>
                <Dropdown
                  disabled={true}
                  style={{ width: "100%", background: "#7988ac" }}
                  value={paciente?.sexo !== undefined ? paciente.sexo : ""}
                  options={sexoList}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteDataInicioPlano"
                >
                  <b>Data inicio plano: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    paciente?.inicioPlano !== undefined
                      ? paciente.inicioPlano
                      : ""
                  }
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteDataFimPlano"
                >
                  <b>Data fim plano: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="date"
                  value={
                    paciente?.fimPlano !== undefined ? paciente.fimPlano : ""
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacienteMedico">
                  <b>Médico: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    paciente?.medico !== undefined ? paciente.medico.nome : {}
                  }
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoEspecialidade"
                >
                  <b>Especialidade do médico: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    paciente?.especialidade !== undefined
                      ? paciente.especialidade.nome
                      : ""
                  }
                />
              </Col>
              <Col>
                <label style={{ color: "#24345a" }} htmlFor="pacientePsicologo">
                  <b>Psicólogo: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    paciente?.psicologo !== undefined
                      ? paciente.psicologo.nome
                      : ""
                  }
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
        <Panel header="Dados de contato">
          <Container>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoCep"
                >
                  <b>CEP: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.cep !== undefined ? paciente.cep : ""}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoEndereco"
                >
                  <b>Endereço: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    paciente?.logradouro !== undefined
                      ? paciente.logradouro
                      : ""
                  }
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoNumero"
                >
                  <b>Número: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.numero !== undefined ? paciente.numero : ""}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoComplemento"
                >
                  <b>Complemento: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={
                    paciente?.complemento !== undefined
                      ? paciente.complemento
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoBairro"
                >
                  <b>Bairro: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.bairro !== undefined ? paciente.bairro : ""}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoCidade"
                >
                  <b>Cidade: </b>
                </label>
                <br></br>
                <InputText
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  value={paciente?.cidade !== undefined ? paciente.cidade : ""}
                />
              </Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoEstado"
                >
                  <b>Estado: </b>
                </label>
                <br></br>
                <Dropdown
                  disabled={true}
                  filter
                  style={{ width: "100%", background: "#7988ac" }}
                  value={paciente?.estado !== undefined ? paciente.estado : ""}
                  options={Ufs}
                  emptyMessage="Não há estados cadastrados no sistema"
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>
                <label
                  style={{ color: "#24345a" }}
                  htmlFor="pacienteSelecionadoTelefone"
                >
                  <b>Telefone: </b>
                </label>
                <br></br>
                <InputMask
                  disabled={true}
                  style={{ width: "100%", textAlign: "center" }}
                  type="text"
                  mask="(99) 99999-9999"
                  value={
                    paciente?.telefone !== undefined ? paciente.telefone : ""
                  }
                />
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Panel>
        <br></br>

        <Panel header="Histórico de psicólogos">
          <Container>
            <Row>
              <Col>
                <CustomDataTable
                  values={
                    paciente !== undefined &&
                    paciente.listaPsicologos !== undefined
                      ? paciente.listaPsicologos
                      : []
                  }
                  totalRecords={
                    paciente !== undefined &&
                    paciente.listaPsicologos !== undefined
                      ? paciente.listaPsicologos.length
                      : 0
                  }
                  dataKey="id"
                  headerList={PacientePsicologosHeader}
                  actionTemplate={null}
                  paginator={true}
                  exibeActionTemplate={false}
                  callbackSelectionChange={null}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
        <br></br>
      </Dialog>
    </div>
  );
}
