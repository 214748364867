import axios from "axios";
import { environment } from "../../environments/environment";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { EspecialidadeDTO } from "../../model/dto/EspecialidadeDTO";

class EspecialidadeDataService {
  env = environment.getEnvironment();
  ESPECIALIDADE_ENDPOINTS = `${this.env.BACKEND_API_URL}/especialidade`;
  token = storageTokenConfig.getToken();

  getAll() {
    return axios.get(`${this.ESPECIALIDADE_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  salvarEspecialidade(especialidade: EspecialidadeDTO) {
    return axios.post(`${this.ESPECIALIDADE_ENDPOINTS}`, especialidade, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + this.token,
      },
    });
  }

  atualizarEspecialidade(id: number, especialidade: EspecialidadeDTO) {
    return axios.put(`${this.ESPECIALIDADE_ENDPOINTS}/${id}`, especialidade, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + this.token,
      },
    });
  }

  removerEspecialidade(especialidade: EspecialidadeDTO) {
    return axios.delete(`${this.ESPECIALIDADE_ENDPOINTS}/${especialidade.id}`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + this.token,
      },
    });
  }
}

const especialidadeDataService = new EspecialidadeDataService()
export default especialidadeDataService;
