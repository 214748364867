import { AiFillHome } from "react-icons/ai";
import { BiSolidCalendarHeart } from "react-icons/bi";
import { BsFillFileMedicalFill } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import {
  FaCalendarAlt,
  FaHandHoldingHeart,
  FaLaptopMedical,
  FaSave,
  FaSearch,
  FaUser,
  FaUserAlt,
  FaUserCheck,
  FaUserClock,
  FaUserSlash,
  FaUserTimes
} from "react-icons/fa";
import { FaFile, FaFileExcel, FaListUl } from "react-icons/fa6";
import { FiAlertTriangle } from "react-icons/fi";
import { GrView } from "react-icons/gr";
import { ImProfile } from "react-icons/im";
import { IoIosAddCircle } from "react-icons/io";
import {
  IoArrowBackCircleSharp,
  IoPeopleSharp,
  IoPrint,
} from "react-icons/io5";
import { LuFileSpreadsheet } from "react-icons/lu";
import {
  MdCancel,
  MdCleaningServices,
  MdOutlineAddCircle,
  MdOutlineFreeCancellation,
  MdOutlineMenu,
} from "react-icons/md";
import { PiCertificateFill } from "react-icons/pi";
import {
  RiDeleteBin5Fill,
  RiLockPasswordFill,
  RiLogoutBoxFill,
  RiUserHeartFill,
} from "react-icons/ri";
import { TiEdit } from "react-icons/ti";

export const UserIcon = FaUserAlt;
export const AlertIcon = FiAlertTriangle;
export const HomeIcon = AiFillHome;
export const EspecialidadeIcon = PiCertificateFill;
export const MenuIcon = MdOutlineMenu;
export const PasswordIcon = RiLockPasswordFill;
export const SaveIcon = FaSave;
export const CancelIcon = MdCancel;
export const LogoutIcon = RiLogoutBoxFill;
export const UsuarioIcon = FaUser;
export const ViewIcon = GrView;
export const EditIcon = TiEdit;
export const DeleteIcon = RiDeleteBin5Fill;
export const NovoIcon = MdOutlineAddCircle;
export const PerfilAcessoIcon = ImProfile;
export const InativarIcon = FaUserSlash;
export const AtivarIcon = FaUserCheck;
export const PsicologoIcon = FaHandHoldingHeart;
export const MedicoIcon = FaLaptopMedical;
export const PacienteIcon = IoPeopleSharp;
export const LimparIcon = MdCleaningServices;
export const FiltrarIcon = FaSearch;
export const AdicionarIcon = IoIosAddCircle;
export const ExportarExcelIcon = FaFileExcel;
export const ModeloProntuarioIcon = BsFillFileMedicalFill;
export const ImprimirIcon = IoPrint;
export const MeusPacientesIcon = RiUserHeartFill;
export const MeusAtendimentosIcon = BiSolidCalendarHeart;
export const IniciarNovoAtendimentoIcon = FaUserClock;
export const TipoProntuarioIcon = FaFile;
export const ListaAtendimentosIcon = FaListUl;
export const EditarProntuarioIcon = CiEdit;
export const VoltarIcon = IoArrowBackCircleSharp;
export const RelatoriosIcon = LuFileSpreadsheet;
export const AgendaIcon = FaCalendarAlt;
export const CancelarEventoIcon = MdOutlineFreeCancellation;
export const StatusComparecimentoAusente = FaUserTimes;
