import axios from "axios";
import { environment } from "../../environments/environment";
import { storageTokenConfig } from "../../config/token/StorageTokenConfig";
import { UsuarioRequestDTO } from "../../model/dto/request/UsuarioRequestDTO";
import { AtivarInativarUsuarioRequestDTO } from "../../model/dto/request/AtivarInativarUsuarioRequestDTO";

class UsuarioDataService {
  env = environment.getEnvironment();
  USUARIO_ENDPOINTS = `${this.env.BACKEND_API_URL}/usuario`;
  token = storageTokenConfig.getToken();

  criarUsuario(usuario: UsuarioRequestDTO) {
    return axios.post(`${this.USUARIO_ENDPOINTS}`, usuario, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  atualizarUsuario(usuarioId: number, usuario: UsuarioRequestDTO) {
    return axios.put(`${this.USUARIO_ENDPOINTS}/${usuarioId}`, usuario, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  ativarInativarUsuario(
    ativarInativarUsuarioRequest: AtivarInativarUsuarioRequestDTO
  ) {
    return axios.patch(
      `${this.USUARIO_ENDPOINTS}/`,
      ativarInativarUsuarioRequest,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.token,
        },
      }
    );
  }

  getAll() {
    return axios.get(`${this.USUARIO_ENDPOINTS}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  alterarSenha(usuario: any, antigaSenha: string, novaSenha: string) {
    const requestBody = {
      antigaSenha: antigaSenha,
      novaSenha: novaSenha,
      usuarioId: usuario.id,
    };

    return axios.post(`${this.USUARIO_ENDPOINTS}/alterar-senha`, requestBody, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }

  getUsuariosPsicologos() {
    return axios.get(`${this.USUARIO_ENDPOINTS}/psicologos`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + this.token,
      },
    });
  }
}

const usuarioDataService = new UsuarioDataService();
export default usuarioDataService;
