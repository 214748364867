import MedicoDataService from "../data_services/medico/MedicoDataService";
import { EspecialidadeDTO } from "../model/dto/EspecialidadeDTO";
import { MedicoDTO } from "../model/dto/MedicoDTO";
import { AtivarInativarMedicoRequestDTO } from "../model/dto/request/AtivarInativarMedicoRequestDTO";
import { MedicoRequestDTO } from "../model/dto/request/MedicoRequestDTO";

class MedicoService {
  static async salvarDadosMedico(id: number, medico: any) {
    const medicoRequest = this.montaRequest(medico);
    if (id) {
      await this.atualizaMedico(id, medicoRequest);
    } else {
      await this.criarMedico(medicoRequest);
    }
  }

  static async trocarStatusMedico(medico: MedicoDTO) {
    await MedicoDataService.ativarInativarMedico(
      this.montaRequestAtivarInativarMedico(medico)
    );
  }

  static async getAll(): Promise<MedicoDTO[]> {
    const response = await MedicoDataService.getAll();
    const medicos: MedicoDTO[] = response.data;
    return medicos;
  }

  private static montaRequestAtivarInativarMedico(medico: MedicoDTO) {
    return new AtivarInativarMedicoRequestDTO({
      medicoId: medico.id,
      ativo: !medico.ativo,
    });
  }

  private static async atualizaMedico(
    id: number,
    medico: MedicoRequestDTO
  ) {
    await MedicoDataService.atualizarMedico(id, medico);
  }

  private static async criarMedico(medico: MedicoRequestDTO) {
    await MedicoDataService.criarMedico(medico);
  }

  private static montaRequest(medico: any) {
    return new MedicoRequestDTO({
      nome: medico.nome,
      crm: medico.crm,
      estado: medico.estado,
      especialidadesIds:
      medico.especialidades?.map(
        (especialidade: EspecialidadeDTO) => especialidade.id
      ) || [],
    });
  }
}

export default MedicoService;
