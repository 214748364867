import moment from "moment";
import "./PacienteHeaderList.css";

export const PacienteHeaderList = [
  {
    field: "ativo",
    header: "Ativo",
    body: getStatusBody,
    size: 100,
    expandable: false,
  },
  {
    field: "paciente",
    header: "Nome",
    body: getNomePacienteBody,
    size: 500,
    expandable: false,
  },
  { field: "nrCarteirinha", header: "Nr. Carteirinha", expandable: false },
  {
    field: "dataNascimento",
    header: "Data de nascimento",
    body: getDataNascimentoBody,
    expandable: false,
  },
  { field: "idade", header: "Idade", calcularIdade, expandable: false },
  { field: "sexo", header: "Sexo", expandable: false },
  {
    field: "inicioPlano",
    header: "Inicio Plano",
    body: getDataInicioBody,
    expandable: false,
  },
  {
    field: "fimPlano",
    header: "Fim plano",
    body: getDataFimBody,
    expandable: false,
  },
  { field: "medico.nome", header: "Médico", expandable: false, size: 400 },
  { field: "medico.crm", header: "CRM", expandable: false },
  { field: "especialidade.cbo", header: "CBO", expandable: false },
  { field: "especialidade.nome", header: "Especialidade", expandable: false },
  {
    field: "psicologo.nome",
    header: "Psicólogo",
    size: 400,
    expandable: false,
  },
  { field: "psicologo.crp", header: "CRP", expandable: false },
];

function getNomePacienteBody(rowData) {
  if (rowData.emitirVpp === true) {
    return (
      <label className="EmitirVpp" htmlFor="pacienteNomeList">
        {rowData.paciente}
      </label>
    );
  } else {
    return (
      <label className="PacienteList" htmlFor="pacienteNomeList">
        {rowData.paciente}
      </label>
    );
  }
}

function getStatusBody(rowData) {
  if (rowData.ativo) {
    return (
      <label htmlFor="medicoStatusAtivo" style={{ color: "green" }}>
        ✓
      </label>
    );
  } else {
    return (
      <label htmlFor="medicoStatusInativo" style={{ color: "red" }}>
        x
      </label>
    );
  }
}

function getDataNascimentoBody(rowData) {
  return formatDate(rowData.dataNascimento);
}

function getDataInicioBody(rowData) {
  return formatDate(rowData.inicioPlano);
}

function getDataFimBody(rowData) {
  return formatDate(rowData.fimPlano);
}

function formatDate(date) {
  if (date !== null) {
    return moment(date).format("DD/MM/YYYY");
  } else {
    return "";
  }
}

function calcularIdade(dataNascimento) {
  const hoje = new Date();
  const dataNasc = new Date(dataNascimento);
  let idade = hoje.getFullYear() - dataNasc.getFullYear();
  if (
    hoje.getMonth() < dataNasc.getMonth() ||
    (hoje.getMonth() === dataNasc.getMonth() &&
      hoje.getDate() < dataNasc.getDate())
  ) {
    idade--;
  }

  return idade.toString();
}
