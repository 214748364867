import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Col, Container, Row } from "react-bootstrap";
import DefaultButton from "../../../components/buttons/DefaultButton";
import DefaultButtonWithLink from "../../../components/buttons/DefaultButtonWithLink";
import {
  EditarProntuarioIcon,
  MeusAtendimentosIcon,
  MeusPacientesIcon,
  TipoProntuarioIcon,
} from "../../../components/icons/Icons";
import PrintButton from "../../../components/print/PrintButton";

export default function DialogMeuAtendimento({
  display,
  esconder,
  paciente,
  setVisualizarDadosPaciente,
  tituloModal,
  visualizarDadosAtendimento,
  hrefVisualizarAtendimento,
  editarDadosAtendimento,
  hrefEditarAtendimento,
  imprimirRef,
  imprimirTituloArquivo,
  callbackFunction,
  prontuario,
}) {
  const tituloComIcone = () => {
    return (
      <div style={{ color: "#fff" }}>
        {<MeusAtendimentosIcon />}{" "}
        {tituloModal !== undefined ? tituloModal : "Dados do atendimento"}
      </div>
    );
  };

  return (
    <div className="container">
      <Dialog
        className="dialog-size"
        style={{ width: "70%" }}
        contentStyle={{
          maxHeight: "80vh",
          overflow: "auto",
          overflowX: "hidden",
          backgroundColor: "#fff",
        }}
        header={tituloComIcone}
        visible={display}
        onHide={esconder}
      >
        <br></br>
        <Panel header="Selecione uma opção">
          <Container>
            <Row>
              <Col>
                <DefaultButton
                  fontSize={15}
                  icon={<MeusPacientesIcon />}
                  buttonLabel={"Visualizar dados do paciente"}
                  messageToolTip={"Visualiza os dados deste paciente"}
                  click={(_) => setVisualizarDadosPaciente(paciente)}
                  width={250}
                  height={40}
                />
              </Col>
              <Col>
                <DefaultButtonWithLink
                  fontSize={15}
                  icon={<TipoProntuarioIcon />}
                  buttonLabel={"Visualizar este atendimento"}
                  messageToolTip={"Visualiza os dados deste atendimento"}
                  click={(_) => visualizarDadosAtendimento(paciente)}
                  width={250}
                  height={40}
                  href={hrefVisualizarAtendimento}
                />
              </Col>
              <Col>
                <DefaultButtonWithLink
                  fontSize={15}
                  icon={<EditarProntuarioIcon />}
                  buttonLabel={"Editar este atendimento"}
                  messageToolTip={"Edita os dados deste atendimento"}
                  click={(_) => editarDadosAtendimento(paciente)}
                  width={250}
                  height={40}
                  href={hrefEditarAtendimento}
                />
              </Col>
              <Col>
                <PrintButton
                  contentRef={imprimirRef}
                  messageToolTip={"Imprimir este prontuário"}
                  titulo={imprimirTituloArquivo}
                  callbackFunction={callbackFunction}
                  rowData={prontuario}
                  label={"Imprimir"}
                  width={250}
                  height={40}
                />
              </Col>
            </Row>
          </Container>
        </Panel>
      </Dialog>
    </div>
  );
}
