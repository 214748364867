export class MeusPacientesFilteredRequestDTO {
  medicoId?: number;
  ativo?: boolean;
  nome?: string;
  nrCarteirinha?: string;

  constructor(options: Partial<MeusPacientesFilteredRequestDTO> = {}) {
    this.medicoId = options.medicoId;
    this.ativo = options.ativo;
    this.nome = options.nome;
    this.nrCarteirinha = options.nrCarteirinha;
  }
}
